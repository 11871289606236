import React, { useEffect, useState } from 'react';
import { getSession } from '../../../utils/session';
import { CONNECTEDNORTH_SIGNINFO, CONNECTEDNORTH_SIGNTYPE } from '../../../config';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import GradeLevel from './gradelevel';
import Subject from './subject';
import Region from './region';
import Search from './search';
import { LinearProgress } from '@mui/material';
import { getAllRegions } from '../../../airtables/Regions.js';
import CurriculumLink from '../../modals/CurriculumLink';
import { useSelector } from 'react-redux';

const Screen = () => {
    const [userType, setUserType] = useState();
    const [userInfo, setUserInfo] = useState();
    const [activeTab, setActiveTab] = useState('search');
    const [loading, setLoading] = useState(true);
    const [regions, setRegions] = useState([]);
    const [regionMap, setRegionMap] = useState({});
    const [linkModal, openLinkModal] = useState(false);
    const [activeCurriculum, setActiveCurriculum] = useState(null)
    const { totalSubjects, subjectMap } = useSelector(state => state.appInfo);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        const getInitialize = async () => {
            const ut = await getSession(CONNECTEDNORTH_SIGNTYPE); setUserType(ut);
            const ui = await getSession(CONNECTEDNORTH_SIGNINFO); setUserInfo(ui);

            const rs = await getAllRegions(); setRegions(rs);

            if (rs && rs.length) {
                const m = {};
                rs.forEach(r => m[r.id] = r);
                setRegionMap(m)
            }

            setTimeout(() => {
                setLoading(false);
            }, 1000)
        }

        getInitialize();
    }, []);

    const openLink = (link) => {
        setActiveCurriculum(link);
        openLinkModal(true)
    }

    return (
        <div className="main-container">
            <span className="page-topic">Sessions by Curriculum</span>
            <div style={{ marginTop: 20 }}>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === 'grade' })}
                            onClick={() => { toggle('grade'); }}
                        >Grade Level</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === 'subject' })}
                            onClick={() => { toggle('subject'); }}
                        >Subject</NavLink>
                    </NavItem>
                    {
                        userType === 'Team' && (
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === 'region' })}
                                    onClick={() => { toggle('region'); }}
                                >Region</NavLink>
                            </NavItem>
                        )
                    }
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === 'search' })}
                            onClick={() => { toggle('search'); }}
                        >Keyword Search</NavLink>
                    </NavItem>
                </Nav>
                {loading ? (
                    <div>
                        <LinearProgress />
                        <div style={{ height: 500 }} />
                    </div>
                ) : (
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="grade">
                            <GradeLevel
                                userType={userType}
                                userInfo={userInfo}
                                subjectMap={subjectMap}
                                regionMap={regionMap}
                                openLink={openLink}
                            />
                        </TabPane>
                        <TabPane tabId="subject">
                            <Subject
                                userType={userType}
                                userInfo={userInfo}
                                subjects={totalSubjects}
                                regionMap={regionMap}
                                openLink={openLink}
                            />
                        </TabPane>
                        {
                            userType === 'Team' && (
                                <TabPane tabId="region">
                                    <Region
                                        userType={userType}
                                        regions={regions}
                                        subjectMap={subjectMap}
                                        regionMap={regionMap}
                                        openLink={openLink}
                                    />
                                </TabPane>
                            )
                        }
                        <TabPane tabId="search">
                            <Search
                                userType={userType}
                                userInfo={userInfo}
                                regionMap={regionMap}
                                openLink={openLink}
                            />
                        </TabPane>
                    </TabContent>
                )}
            </div>

            {(linkModal && activeCurriculum !== null) && (
                <CurriculumLink
                    userType={userType}
                    curriculum={activeCurriculum}
                    onToggle={() => {
                        openLinkModal(false);
                        setActiveCurriculum(null);
                    }}
                />
            )}
        </div>
    )
}

export default Screen;