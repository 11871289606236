import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    Modal,
    FormGroup,
    Input,
    Col,
    Form,
    Label,
    FormFeedback,
    InputGroup,
    InputGroupText
} from 'reactstrap';
import { GradeLevels } from '../../utils/constants';
import CustomFile from '../../components/CustomFile';
import Loading from '../../components/Loading';
import uploadFile from '../../airtables/uploadfile';
import { createProviderSession, updateProviderSession } from '../../airtables/ProviderSessions';
import { saveAs } from 'file-saver';
import Axios from 'axios';
import { logEvent } from 'firebase/analytics';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { analytics } from '../../firebase';

const descPlaceholder = {
    fr: `Format suggéré: 4 à 8 phrases qui incluent: 
- 1 à 2 phrases d’introduction (qui vous êtes, contexte, travail, compétences, etc.)
- 1 à 2 phrases: ce que les élèves vont apprendre (but de la session)
- 1 à 2 phrases: ce que les élèves vont faire pendant la session (envisagez une composante interactive)`,
    en: `Suggested format: 4-8 sentences which include:
- 1-2 sentences introduction (who you are/background/work/skills, etc.)
- 1-2 sentences: what the students will be learning (the goal of the session)
- 1-2 sentences: what will the students be doing during the session (consider an interactive component)`
}

const ProviderSessionModal = ({ userInfo, session, onToggle, onChange, disableField }) => {
    const { totalSubjects, appLocale, presentersForProvider } = useSelector(state => state.appInfo);
    const [title, setTitle] = useState("");
    const [cost, setCost] = useState();
    const [language, setLanguage] = useState("English");
    const [subjects, setSubjects] = useState({});
    const [grades, setGrades] = useState([]);
    const [length, setLength] = useState();
    const [description, setDescription] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [errors, setErrors] = useState({});
    const fileInputRef = useRef();
    const imgInputRef = useRef();
    const [saving, setSaving] = useState(false);
    const [materialInfo, setMaterialInfo] = useState("");
    const [sessionImg, setSessionImg] = useState(null);
    const [relatedLinkTitle, setRelatedLinkTitle] = useState("")
    const [relatedLinkURL, setRelatedLinkURL] = useState("");
    const [defaultPresenter, setDefaultPresenter] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        if (session) {
            setTitle(session['Session Title']);
            setCost(session['Cost']);
            setLanguage(session['Language']);
            setGrades(session['Grade(s)']);
            setLength(session['Length (Minutes)']);
            setDescription(session['Description']);
            setMaterialInfo(session["Materials Info"]);
            setRelatedLinkTitle(session["Link Title"]);
            setRelatedLinkURL(session["Link URL"]);
            setDefaultPresenter(session['Default Presenter'] ? session['Default Presenter'][0] : '')
            if (session['Resource Sheets']) {
                setAttachments(session['Resource Sheets'].map((file) => ({
                    file: {
                        name: file.filename,
                        type: file.type,
                        url: file.url
                    },
                    thumbs: file?.thumbnails?.large,
                    uploaded: true
                })));
            } else {
                setAttachments([]);
            }

            if (session['Image/Photo']) {
                setSessionImg({
                    file: {
                        name: session['Image/Photo'][0].filename,
                        type: session['Image/Photo'][0].type,
                        url: session['Image/Photo'][0].url
                    },
                    thumbs: session['Image/Photo'][0]?.thumbnails?.large,
                    uploaded: true
                })
            } else setSessionImg(null);

            let obj = {}
            if (session.Subject) {
                session.Subject.forEach(sId => {
                    obj[sId] = true;
                })
            }
            setSubjects(obj);
        } else {
            setTitle("");
            setCost();
            setLanguage("English");
            setSubjects([]);
            setGrades([]);
            setLength();
            setDescription("");
            setMaterialInfo("");
            setAttachments([]);
            setSessionImg(null);
            setRelatedLinkTitle("");
            setRelatedLinkURL("");
            setDefaultPresenter('')
        }
    }, [session])

    const disabled = () => {
        if (session && disableField) return true;
        return false;
    }

    const onAddAttachFile = (file) => {
        if (file) {
            var atts = [...attachments];
            atts.push({
                file,
                uploaded: false
            })
            setAttachments(atts);
            fileInputRef.current.value = '';
        }
    }

    const onAddSessionImage = (file) => {
        if (file) {
            setSessionImg({
                file,
                uploadFile: false
            })
        }
        imgInputRef.current.value = '';
    }

    const onSave = async () => {
        if (saving) return;

        var errorlist = {};
        if (!title) errorlist.title = "Title field is required.";
        // if (!cost) errorlist.cost = "Cost field is required.";
        if (!subjects || subjects.length === 0) errorlist.subjects = "Subject field is required.";
        if (!grades || grades.length === 0) errorlist.grades = "Grade(s) field is required.";
        if (!description) errorlist.description = "Description field is required.";

        if (Object.keys(errorlist).length) {
            setErrors(errorlist);
            return;
        }

        const getSessionStatue = () => {
            if (session) {
                if (session.Status === 'In Development' ||
                    session.Status === 'New' ||
                    session.Status === "Consider Another Option (See notes)"
                ) {
                    return session.Status;
                }
                return 'Active (Open for booking)'
            }
            return "In Development"
        }

        setSaving(true);
        var params = {
            'Session Title': title,
            'Cost': cost,
            'Language': language,
            'Length (Minutes)': length,
            'Description': description,
            'Subject': [],
            'Grade(s)': grades,
            'Provider': [userInfo.id],
            'Status': getSessionStatue(),
            'Provider Updates to Review': true,
            'Materials Info': materialInfo,
            'Link Title': relatedLinkTitle,
            'Link URL': relatedLinkURL,
            'Default Presenter': defaultPresenter ? [defaultPresenter] : null
        };

        const sKeys = Object.keys(subjects);
        sKeys.forEach(k => {
            if (subjects[k]) params['Subject'].push(k);
        })
        if (attachments && attachments.length) {
            for (var i = 0; i < attachments.length; i++) {
                if (!attachments[i].uploaded) {
                    const file = attachments[i].file;
                    const uRes = await uploadFile(file)
                    attachments[i].file = {
                        filename: file.name,
                        type: file.type,
                        url: uRes
                    }
                    attachments[i].uploaded = true;
                }
            }
            params['Resource Sheets'] = attachments.map(attach => ({ url: attach.file.url }));
        }
        if (sessionImg) {
            if (!sessionImg.uploaded) {
                const uRes = await uploadFile(sessionImg.file);
                params['Image/Photo'] = [{ url: uRes }];
            } else {
                params['Image/Photo'] = [{ url: sessionImg.file.url }];
            }
        } else {
            params['Image/Photo'] = null;
        }

        var tmpSessions = null;
        if (session) {
            await updateProviderSession(session.id, params);
        } else {
            tmpSessions = await createProviderSession(params);
        }

        logEvent(analytics, session ? "edit_session" : "add_session", {
            userId: userInfo.id,
            sessionId: session ? session.id : tmpSessions[0].id
        })

        onChange();
        onToggle();
    }

    const fileDownload = (url, name) => {
        const config = { responseType: 'blob' };
        Axios.get(url, config).then(response => {
            saveAs(response.data, name)
        });
    }

    const renderSubject = (sub, i) => {
        const subjectName = appLocale === 'fr' ? sub["Subject (FR)"] : sub["Subject"];
        if (!subjectName) return;

        return (
            <Button
                key={i}
                outline={subjects[sub.id] ? false : true}
                color="primary"
                size="sm"
                type="button"
                style={{ marginBottom: 5 }}
                onClick={() => {
                    if (disabled()) return
                    const obj = { ...subjects };
                    obj[sub.id] = obj[sub.id] ? false : true;
                    setSubjects(obj);
                }}
                disabled={disabled()}
            >{subjectName}</Button>
        )
    }

    return (
        <Modal
            className="modal-dialog-centered session-modal"
            isOpen={true}
            toggle={onToggle}
            backdrop="static"
        >
            <div className="modal-header">
                <h5 className="modal-title" id="modal-title-default">{session ? t("edit-session") : t("add-session")}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={onToggle}
                ><span aria-hidden={true}>×</span></button>
            </div>
            <div className="modal-body">
                <div className="session-info">
                    <Form>
                        <FormGroup row>
                            <Label sm={3} for="title">{t("title")}:</Label>
                            <Col sm={9}>
                                <Input
                                    invalid={errors.title ? true : false}
                                    id="title"
                                    type="text"
                                    defaultValue={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    disabled={disabled()}
                                />
                                <FormFeedback>{errors.title}</FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={3} for="cost">{t("cost")}:</Label>
                            <Col sm={9}>
                                <InputGroup>
                                    <InputGroupText>$</InputGroupText>
                                    <Input
                                        invalid={errors.cost ? true : false}
                                        id="cost"
                                        type="number"
                                        placeholder="in dollars, for example: 100"
                                        defaultValue={cost}
                                        style={{ paddingLeft: 10 }}
                                        onChange={(e) => {
                                            if (e.target.value) setCost(parseFloat(e.target.value))
                                            else setCost(0)
                                        }}
                                        disabled={disabled() || session}
                                    />
                                </InputGroup>
                                <FormFeedback>{errors.cost}</FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={3} for="language">{t("language")}:</Label>
                            <Col sm={9}>
                                <Input
                                    type="select"
                                    name="select"
                                    id="language"
                                    defaultValue={language}
                                    disabled={disabled()}
                                    onChange={e => setLanguage(e.target.value)}
                                >
                                    <option>English</option>
                                    <option value="French">français</option>
                                </Input>
                            </Col>
                        </FormGroup>

                        <FormGroup>
                            <Label>{t("subjects")}:</Label>
                            <Col>
                                <div className="flex-row">
                                    {
                                        totalSubjects.map((subject, i) => renderSubject(subject, i))
                                    }
                                </div>
                                <div className="invalid-feedback" style={{ display: 'block' }}>{errors.subjects}</div>
                            </Col>
                        </FormGroup>

                        <FormGroup>
                            <Label>{t("grades")}:</Label>
                            <Col>
                                <div className="flex-row">
                                    {
                                        GradeLevels.map((grade, i) => (
                                            <Button
                                                key={i}
                                                outline={grades && grades.indexOf(grade) >= 0 ? false : true}
                                                color="primary"
                                                size="sm"
                                                type="button"
                                                style={{ marginBottom: 5 }}
                                                onClick={() => {
                                                    const gs = grades ? [...grades] : [];
                                                    const id = gs.indexOf(grade);
                                                    if (id >= 0) {
                                                        gs.splice(id, 1);
                                                    } else {
                                                        gs.push(grade);
                                                    }
                                                    setGrades(gs);
                                                }}
                                                disabled={disabled()}
                                            >{grade}</Button>
                                        ))
                                    }
                                </div>
                                <div className="invalid-feedback" style={{ display: 'block' }}>{errors.grades}</div>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={3} for="length">{t("length")}:</Label>
                            <Col sm={6}>
                                <Input
                                    id="length"
                                    type="number"
                                    defaultValue={length}
                                    onChange={(e) => {
                                        if (e.target.value) setLength(parseInt(e.target.value))
                                        else setLength(0)
                                    }}
                                    disabled={disabled()}
                                />
                            </Col>
                            <Label sm={3} for="length" style={{ paddingLeft: 0 }}>minutes</Label>
                        </FormGroup>

                        <FormGroup>
                            <Label for="description">{t("description")}:</Label>
                            <Input
                                invalid={errors.description ? true : false}
                                id="description"
                                type="textarea"
                                defaultValue={description}
                                style={{ height: 120 }}
                                onChange={(e) => setDescription(e.target.value)}
                                disabled={disabled()}
                                placeholder={descPlaceholder[appLocale]}
                            />
                            <FormFeedback>{errors.description}</FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Label for="materialsinfo">{t("materials-required")}:</Label>
                            <Input
                                invalid={errors.materialsinfo ? true : false}
                                id="materialsinfo"
                                type="text"
                                defaultValue={materialInfo}
                                onChange={(e) => setMaterialInfo(e.target.value)}
                                disabled={disabled()}
                            />
                            <FormFeedback>{errors.materialsinfo}</FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Label>{t("support-handouts")}:</Label>
                            <div className="flex-row">
                                {
                                    attachments.map((attach, i) => (
                                        <CustomFile
                                            key={i}
                                            attachment={attach}
                                            onRemove={() => {
                                                if (window.confirm("Are you sure you want to delete this file?")) {
                                                    const newAttachments = [...attachments];
                                                    newAttachments.splice(i, 1);
                                                    setAttachments(newAttachments);
                                                }
                                            }}
                                            onDownload={() => fileDownload(attach.file.url, attach.file.name)}
                                        />
                                    ))
                                }
                            </div>

                            <div style={{ paddingTop: 10 }}>
                                <Button size="sm" onClick={() => fileInputRef.current.click()} type="button" color="secondary" >Attach File</Button>
                            </div>
                            <input
                                type="file"
                                accept="*/*"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={e => onAddAttachFile(e.target.files[0])}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label>{t("session-image")}:</Label>
                            <div className="flex-row">
                                {
                                    sessionImg ? (
                                        <CustomFile
                                            attachment={sessionImg}
                                            onRemove={() => {
                                                if (window.confirm("Are you sure you want to delete this image")) {
                                                    setSessionImg(null)
                                                }
                                            }}
                                            onDownload={() => fileDownload(sessionImg.file.url, sessionImg.file.name)}
                                        />
                                    ) : null
                                }
                            </div>

                            <span>{t("provider-session-desc1")}</span>
                            <div style={{ paddingTop: 10 }}>
                                <Button
                                    size="sm"
                                    onClick={() => imgInputRef.current.click()}
                                    type="button"
                                    color="secondary"
                                    disabled={disabled()}
                                >{sessionImg ? "Change" : "Add"}</Button>
                            </div>
                            <input
                                type="file"
                                accept="image/*"
                                ref={imgInputRef}
                                style={{ display: 'none' }}
                                onChange={e => {
                                    if (e.target.files && e.target.files.length) {
                                        onAddSessionImage(e.target.files[0])
                                    }
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="description">{t("related-link")}:</Label>
                            <Col>
                                <Input
                                    type="text"
                                    placeholder={t("related-link-title")}
                                    defaultValue={relatedLinkTitle}
                                    onChange={(e) => setRelatedLinkTitle(e.target.value)}
                                />
                                <div style={{ height: 10 }} />
                                <Input
                                    type="text"
                                    placeholder={t("related-link-url")}
                                    defaultValue={relatedLinkURL}
                                    onChange={(e) => setRelatedLinkURL(e.target.value)}
                                />
                            </Col>
                        </FormGroup>

                        {
                            (presentersForProvider && presentersForProvider.length) ? (
                                <FormGroup row>
                                    <Label sm={3} for="defaultpresenter">{t("default-presenter")}:</Label>
                                    <Col sm={9}>
                                        <Input
                                            type="select"
                                            name="defaultpresenter"
                                            id="defaultpresenter"
                                            defaultValue={defaultPresenter}
                                            onChange={e => setDefaultPresenter(e.target.value)}
                                        >
                                            <option value=""></option>
                                            {
                                                presentersForProvider.map((presenter, i) => (
                                                    <option value={presenter.id} key={i}>{presenter.Name}</option>
                                                ))
                                            }
                                        </Input>
                                    </Col>
                                </FormGroup>
                            ) : null
                        }
                    </Form>
                </div>
            </div>
            <div className="modal-footer">
                <Button
                    className="ml-auto"
                    color="success"
                    type="button"
                    onClick={() => onSave()}
                >{saving && (<Loading size={20} />)}{` `}{t("save-change")}</Button>
                <Button
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={onToggle}
                >{t("close")}</Button>
            </div>
        </Modal>
    )
}

export default ProviderSessionModal;