import base from './config';
const tableName = "Regions"

export const getAllRegions = () => {
    return new Promise((resolve, reject) => {
        const regions = [];
        base(tableName).select(
            {
                sort: [
                    { field: 'Name', direction: 'asc' }
                ]
            }
        ).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                const reg = {
                    id: record.id,
                    ...record.fields
                }

                regions.push(reg);
            })
            fetchNextPage()
        }).then(res => {
            resolve(regions)
        }).catch(error => {
            resolve([])
        })
    })
}

export const getRegionByID = (id) => {
    return new Promise((resolve, reject) => {
        base(tableName).find(id).then(record => {
            if (record && record._rawJson) {
                resolve(record._rawJson.fields)
            } else {
                resolve(null)
            }
        }).catch(error => {
            reject(error.toString())
        })
    });
}

export const getRegionByName = (name) => {
    return new Promise((resolve, reject) => {
        const regions = [];
        base(tableName).select(
            {
                filterByFormula: `OR({Name} = '${name}', {Long Name} = '${name}')`
            }
        ).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                const reg = {
                    id: record.id,
                    ...record.fields
                }

                regions.push(reg);
            })
            fetchNextPage()
        }).then(res => {
            if (regions.length) resolve(regions[0])
            else                resolve(null)
        }).catch(error => {
            resolve([])
        })
    })
}