import base from './config';
const tableName = "Sessions";

export const getSessions = (
    field,
    name,
    status,
    isAdmin,
    isPast,
    isTeacherChecking /* # Teachers > 1 */
) => {

    return new Promise((resolve, reject) => {
        var filterByFormula = null;
        var extraCondition = `NOT(FIND('Session Test', {Session Title})), {Session} != ERROR(), `
        if (!isAdmin) {
            if (field === 'School Lead') {
                extraCondition = extraCondition + `OR(FIND("${name}", {School Lead}), FIND("${name}", {Session Host(s)}), FIND("${name}", {School School Lead})), `;
            } else {
                extraCondition = extraCondition + `FIND("${name}", {${field}}), `;
            }
        }

        if (isTeacherChecking) {
            extraCondition = extraCondition + `{# Teachers} >= 1, `;
        }
        if (status === 'Completed') {
            if (field === 'Teacher') {
                filterByFormula = `AND(${extraCondition}{Session Start Date/Time} < NOW(), {Status} = '${status}', OR({Rating} = BLANK(), {Rating} = 0))`;
            } else if (field === 'School Lead') {
                filterByFormula = `AND(${extraCondition}{Session Start Date/Time} < NOW(), {Status} = '${status}', OR({Team Rating} = BLANK(), {Team Rating} = 0))`;
            }
        } else if (status === 'Booked') {
            if (isPast) {
                filterByFormula = `AND(${extraCondition}{Session Start Date/Time} <= NOW(), {Status} = '${status}')`;
            } else {
                filterByFormula = `AND(${extraCondition}{Status} = '${status}', {Session Start Date/Time} >= DATEADD(NOW(), -1, 'hours'))`;
            }
        } else if (status === 'Requested') {
            filterByFormula = `AND(${extraCondition}{Status} = '${status}')`;
        }

        var sessions = [];
        base(tableName).select({
            filterByFormula,
            sort: [
                { field: 'Session Start Date/Time', direction: status === 'Completed' ? 'desc' : 'asc' }
            ]
        }).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                var session = { id: record.id, ...record.fields }
                sessions.push(session);
            })
            fetchNextPage()
        }).then(res => {
            resolve(sessions)
        }).catch(error => {
            resolve([])
        })
    });
}

export const getProviderUpcomingSessions = (providerName, isTeacherChecking) => {
    return new Promise((resolve, reject) => {
        var filterByFormula = null;
        var extraCondition = `NOT(FIND('Session Test', {Session Title})), {Session} != ERROR(), `

        extraCondition = extraCondition + `FIND("${providerName}", {Provider}), `;

        if (isTeacherChecking) {
            extraCondition = extraCondition + `{# Teachers} >= 1, `;
        }
        filterByFormula = `AND(${extraCondition}OR({Status} = 'Booked', {Status} = 'Confirmed for Booking'), {Session Start Date/Time} >= DATEADD(NOW(), -1, 'hours'))`;

        var sessions = [];
        base(tableName).select({
            filterByFormula,
            sort: [{ field: 'Session Start Date/Time', direction: 'asc' }]
        }).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                var session = { id: record.id, ...record.fields }
                sessions.push(session);
            })
            fetchNextPage()
        }).then(res => {
            resolve(sessions)
        }).catch(error => {
            resolve([])
        })
    });
}

export const getCompletedSessions = (field, name, isAdmin) => {
    return new Promise((resolve, reject) => {
        var sessions = [];
        var filterByFormula = null;
        var extraCondition = `NOT(FIND('Session Test', {Session Title})), {Session} != ERROR(), `
        if (!isAdmin) {
            extraCondition = extraCondition + `FIND('${name}', {${field}}), `;
        }
        filterByFormula = `AND(${extraCondition}{Status} = 'Completed', {Team Rating} != BLANK(), {Team Rating} != 0)`;

        base(tableName).select({
            filterByFormula,
            sort: [
                { field: 'Session Start Date/Time', direction: 'desc' }
            ]
        }).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                var session = {
                    id: record.id,
                    ...record.fields
                }
                sessions.push(session);
            })
            fetchNextPage()
        }).then(res => {
            resolve(sessions)
        }).catch(error => {
            resolve([])
        })
    })
}

export const getAllCompletedSessions = (
    field,
    name,
    isTeacherChecking /* # Teachers > 1 */
) => {
    return new Promise((resolve, reject) => {
        var sessions = [];

        var formula = "";
        if (isTeacherChecking) {
            formula = `AND({# Teachers} <= 1, NOT(FIND('Session Test', {Session Title})), {Session} != ERROR(), FIND('${name}', {${field}}), {Status} = 'Completed')`
        } else {
            formula = `AND(NOT(FIND('Session Test', {Session Title})), {Session} != ERROR(), FIND('${name}', {${field}}), {Status} = 'Completed')`
        }

        base(tableName).select({
            filterByFormula: formula,
            sort: [
                { field: 'Session Start Date/Time', direction: 'desc' }
            ]
        }).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                var session = {
                    id: record.id,
                    ...record.fields
                }
                sessions.push(session);
            })
            fetchNextPage()
        }).then(res => {
            resolve(sessions)
        }).catch(error => {
            resolve([])
        })
    })
}

export const getPremiumSessions = () => {
    return new Promise((resolve, reject) => {
        var sessions = [];
        base(tableName).select({
            filterByFormula: `AND({Session Start Date/Time} >= NOW(), {Type of Session} = 'Premium Session', {Premium Registration Closed} = FALSE(), {Hide from Events} = FALSE())`,
            sort: [
                { field: 'Session Start Date/Time', direction: 'asc' }
            ]
        }).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                var session = {
                    id: record.id,
                    ...record.fields
                }
                sessions.push(session);
            })
            fetchNextPage()
        }).then(res => {
            resolve(sessions)
        }).catch(error => {
            resolve([])
        })
    })
}

export const getPDWeekSessions = () => {
    return new Promise((resolve, reject) => {
        var sessions = [];
        base(tableName).select({
            filterByFormula: `AND(FIND('23 NU PD Week', {Tags}))`,
            sort: [
                { field: 'Session Start Date/Time', direction: 'desc' }
            ]
        }).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                var session = {
                    id: record.id,
                    ...record.fields
                }
                sessions.push(session);
            })
            fetchNextPage()
        }).then(res => {
            resolve(sessions)
        }).catch(error => {
            resolve([])
        })
    })
}

export const addSession = (param) => {
    return new Promise((resolve, reject) => {
        base(tableName).create([{
            "fields": param
        }]).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error)
        })
    })
}

export const updateSession = (id, param) => {
    return new Promise((resolve, reject) => {
        base(tableName).update([{
            id, fields: param
        }]).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export const getUnpaidSessions = (field, name, isAdmin) => {
    return new Promise((resolve, reject) => {
        var filterByFormula = null;
        var extraCondition = `NOT(FIND('Session Test', {Session Title})), {Session} != ERROR(), `
        if (!isAdmin) {
            extraCondition = extraCondition + `FIND('${name}', {${field}}), `;
        }
        filterByFormula = `AND(${extraCondition}{Payments} = BLANK(), {CILC?} = 0, {Cost} > 0, {Status} = 'Completed')`;

        var sessions = [];
        base(tableName).select({
            filterByFormula,
            sort: [
                { field: 'Session Start Date/Time', direction: 'desc' }
            ]
        }).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                var session = {
                    id: record.id,
                    ...record.fields
                }
                sessions.push(session);
            })
            fetchNextPage()
        }).then(res => {
            resolve(sessions)
        }).catch(error => {
            resolve([])
        })
    })
}

export const getSessionsByIds = (ids) => {
    return new Promise((resolve, reject) => {
        resolve();
    });
}

export const getSessionById = (id) => {
    return new Promise((resolve, reject) => {
        base(tableName).find(id).then(record => {
            if (record && record._rawJson) {
                resolve({
                    id: record._rawJson.id,
                    ...record._rawJson.fields
                })
            } else {
                resolve(null)
            }
        }).catch(error => {
            resolve(null);
        })
    })
}

export const getSessionsForPending = (field, name, isAdmin) => {
    return new Promise((resolve, reject) => {
        var filterByFormula = null;
        var extraCondition = `NOT(FIND('Session Test', {Session Title})), {Session} != ERROR(), `
        if (!isAdmin) {
            extraCondition = extraCondition + `FIND("${name}", {${field}}), `;
        }

        if (field === 'Provider') {
            filterByFormula = `AND(${extraCondition}OR({Status} = 'Requested', {Status} = 'In Development'), {Session Start Date/Time} >= NOW())`;
        } else {
            filterByFormula = `AND(${extraCondition}OR({Status} = 'Requested', {Status} = 'In Development', {Status} = 'In Planning'))`;
        }

        var sessions = [];
        base(tableName).select({
            filterByFormula,
            sort: [
                { field: 'Session Start Date/Time', direction: 'asc' }
            ]
        }).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                var session = {
                    id: record.id,
                    ...record.fields
                }
                sessions.push(session);
            })
            fetchNextPage()
        }).then(res => {
            resolve(sessions)
        }).catch(error => {
            resolve([])
        })
    });
}

export const searchSessions = (providerName, search = "", states = []) => {
    return new Promise((resolve, reject) => {
        var searchCondition = "", stateCondition = "", providerCondition, filterByFormula = "";
        if (search && search.trim()) {
            const kWord = search.trim().toUpperCase();

            if (kWord.indexOf(" ") > 0) {
                searchCondition = "OR(";
                searchCondition += `REGEX_MATCH(UPPER(ARRAYJOIN({Session Title})), '${kWord}')`;
                searchCondition += `, REGEX_MATCH(UPPER(ARRAYJOIN({Session Description})), '${kWord}')`;
                searchCondition += `, REGEX_MATCH(UPPER(ARRAYJOIN({School Lead})), '${kWord}')`;
                searchCondition += `, REGEX_MATCH(UPPER(ARRAYJOIN({School(s)})), '${kWord}')`;
                searchCondition += `, REGEX_MATCH(UPPER(ARRAYJOIN({Teacher})), '${kWord}')`;
                searchCondition += ")"
            } else {
                searchCondition = "OR(";
                searchCondition += `REGEX_MATCH(UPPER(ARRAYJOIN({Session Title})), '(^| )${kWord}( |$)')`;
                searchCondition += `, REGEX_MATCH(UPPER(ARRAYJOIN({Session Description})), '(^| )${kWord}( |$)')`;
                searchCondition += `, REGEX_MATCH(UPPER(ARRAYJOIN({School Lead})), '(^| )${kWord}( |$)')`;
                searchCondition += `, REGEX_MATCH(UPPER(ARRAYJOIN({School(s)})), '(^| )${kWord}( |$)')`;
                searchCondition += `, REGEX_MATCH(UPPER(ARRAYJOIN({Teacher})), '(^| )${kWord}( |$)')`;
                searchCondition += ")"
            }
        }

        if (states && states.length) {
            states.forEach((state, i) => {
                if (i > 0) stateCondition += ", ";
                stateCondition += `{Status} = '${state}'`;
            })

            if (states.length > 1) stateCondition = `OR(${stateCondition})`;
        }

        providerCondition = `FIND(";${providerName};", CONCATENATE(";", SUBSTITUTE(ARRAYJOIN({Provider}), ", ", ";"), ";"))`;

        if (searchCondition || stateCondition) {
            filterByFormula = `AND(${providerCondition}`;
            if (searchCondition) filterByFormula += `, ${searchCondition}`;
            if (stateCondition) filterByFormula += `, ${stateCondition}`;
            filterByFormula += ')';
        } else filterByFormula = providerCondition;

        var sessions = [];
        base(tableName).select({
            filterByFormula,
            sort: [
                { field: 'Session Start Date/Time', direction: 'desc' }
            ]
        }).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                var session = {
                    id: record.id,
                    ...record.fields
                }
                sessions.push(session);
            })
            fetchNextPage()
        }).then(res => {
            resolve(sessions)
        }).catch(error => {
            resolve([])
        })
    });
}

export const getAllUpcomingInSameSchool = (teacherName, schoolName) => {
    return new Promise((resolve, reject) => {
        var sessions = [];
        base(tableName).select({
            filterByFormula: `AND(FIND("${schoolName}", {School(s)}), NOT(FIND("${teacherName}", {Teacher})), {Status} = 'Booked')`,
            sort: [{ field: 'Session Start Date/Time', direction: 'asc' }]
        }).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                var session = {
                    id: record.id,
                    ...record.fields
                }
                sessions.push(session);
            })
            fetchNextPage()
        }).then(res => {
            resolve(sessions)
        }).catch(error => {
            resolve([])
        })
    });
}

export const loadPresenterSessions = (ids, loadingProgress) => {
    return new Promise(async (resolve, reject) => {
        const upcoming = [], past = [], other = [];
        for (const id of ids) {
            const session = await getSessionById(id);
            if (session["Status"] === "Completed") {
                past.push(session);
            } else if (session["Status"] === "Booked") {
                upcoming.push(session);
            } else {
                other.push(session);
            }

            if (loadingProgress) loadingProgress({ upcoming, past, other })
        }

        resolve({ upcoming, past, other });
    });
}
