import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPremiumSessions, updateSession, getSessionById } from '../../airtables/Sessions';
import { LinearProgress } from '@mui/material';
import SessionItem from '../../components/SessionItem';
import './styles.scss';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import OverlayLoading from '../../components/OverlayLoading';
import { isTodayORTomorrowSession } from '../../utils/time';
import { insertEvent, deleteEvent } from '../../airtables/Events';
import { getTeachersByNames } from '../../airtables/Teachers';
import { getFullYearMonth, monthNames } from '../../utils/time';

const Screen = () => {
    const { userInfo, appLocale, userType } = useSelector(state => state.appInfo);
    const { t } = useTranslation();
    const [sessionMap, setSessionMap] = useState({});
    const [sessions, setSessions] = useState([]);
    const [keys, setKeys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        setLoading(true)
        getPremiumSessions().then((res) => {
            setSessions(res);
            setLoading(false);
        }).catch(error => setLoading(false));
    }, [])

    useEffect(() => {
        if (!sessions || sessions.length === 0) {
            setSessionMap({});
            setKeys([]);
        } else {
            const sMap = sessions.reduce((result, sess, id) => {
                const heading = getFullYearMonth(sess["Session Start Date/Time"]);
                if (heading) {
                    if (result[heading]) {
                        result[heading].push({ session: sess, no: id });
                    } else {
                        result[heading] = [{ session: sess, no: id }];
                    }
                }
                return result;
            }, {});
            setSessionMap(sMap);
            setKeys(Object.keys(sMap).sort());
        }
    }, [sessions])

    const cancelRegistration = async (session, no) => {
        if (window.confirm("Are you sure you wish to cancel your registration for this event?")) {
            setProcessing(true);

            let sessionSchoolIds = session["School(s)"];
            const teacherIds = session["Teacher"].filter(tn => tn !== userInfo.id);
            if (userInfo["School Name"] && userInfo["School Name"].length) {
                const sName = userInfo["School Name Text"][0];
                if (session["School Name Text"] && session["School Name Text"].indexOf(sName) >= 0) {
                    const teacherNames = session["Teacher Name"].filter(tn => tn !== userInfo["Teacher Name"]);
                    const teachers = await getTeachersByNames(teacherNames, teacherIds);
                    const shouldRemoveSchool = teachers.reduce((result, teacher) => {
                        if (!result) return false;
                        if (!teacher["School Name Text"] || teacher["School Name Text"].length === 0) return result;
                        if (teacher["School Name Text"].indexOf(sName) < 0) return result;
                        return false;
                    }, true);

                    if (shouldRemoveSchool) {
                        sessionSchoolIds = session["School(s)"].filter(sId => sId !== userInfo["School Name"][0]);
                    }
                }
            }

            const res = await updateSession(session.id, {
                Teacher: teacherIds,
                "School(s)": sessionSchoolIds
            });
            await deleteEvent(userInfo["Email"], session.Session);
            const newSession = { ...res[0].fields, id: res[0].id };
            let nSessions = [...sessions]; nSessions[no] = newSession;
            setSessions(nSessions);
            setProcessing(false);
        }
    }

    const cancelRegistrationPending = async (session, no) => {
        if (window.confirm("Are you sure you wish to cancel your registration for this event?")) {
            setProcessing(true);
            await deleteEvent(userInfo["Email"], session.Session);
            const newSession = await getSessionById(session.id);
            let nSessions = [...sessions]; nSessions[no] = newSession;
            setSessions(nSessions);
            setProcessing(false);
        }
    }

    const requestRegister = async (session, no) => {
        setProcessing(true);
        await insertEvent({
            Name: userInfo["Teacher Name"],
            "E-mail Address": userInfo["Email"],
            School: [userInfo["School Name"][0]],
            Session: [session.id]
        })
        const newSession = await getSessionById(session.id);
        let nSessions = [...sessions]; nSessions[no] = newSession;
        setSessions(nSessions);
        setProcessing(false);
    }

    const getMonthlyGroup = (k) => {
        const arr = k.split("-");
        const m = parseInt(arr[1]);
        return `${monthNames[appLocale][m]} ${arr[0]}`;
    }

    const canJoin = (session) => {
        if (userType === 'Teacher') {
            return session.Teacher && session.Teacher.indexOf(userInfo.id) >= 0 && isTodayORTomorrowSession(session["Session Start Date/Time"]) && session["WebEx/Zoom Link"];
        }

        return true;
    }

    const renderRegistrationStatus = (session, i) => {
        if (userType !== 'Teacher') return null;

        if (session.Teacher && session.Teacher.indexOf(userInfo.id) >= 0) {
            return (
                <>
                    <Button className='btn-session' color="link" disabled={true} size="sm">Registered</Button>
                    <Button
                        className='btn-session'
                        color="danger"
                        size="sm"
                        onClick={(e) => cancelRegistration(session, i)}
                    >Cancel Registration</Button>
                </>
            )
        }

        if (session["Premium Registrations"] && session["Premium Registrations"].indexOf(userInfo.Email) >= 0) {
            return (
                <>
                    <Button className='btn-session' color="link" disabled={true} size="sm">Registration Pending</Button>
                    <Button
                        className='btn-session'
                        color="danger"
                        size="sm"
                        onClick={(e) => cancelRegistrationPending(session, i)}
                    >Cancel Registration</Button>
                </>
            )
        }

        if (session["Capacity (Premium Sessions)"] && (session["# Schools"] >= session["Capacity (Premium Sessions)"])) {
            return (
                <Button
                    className='btn-session'
                    color="warning"
                    size="sm"
                    onClick={(e) => requestRegister(session, i)}
                >Session Full - Join Waitlist</Button>
            )
        }

        return (
            <Button
                className='btn-session'
                color="success"
                size="sm"
                onClick={(e) => requestRegister(session, i)}
            >Register</Button>
        );
    }

    return (
        <div className="main-container">
            <span className="page-topic">{t("events")}</span>
            <div style={{ marginTop: 10 }}>
                {loading ? <LinearProgress /> : null}
                <div className="general-container">
                    {
                        loading ? null : (
                            keys.map((k, i) => (
                                <div key={i}>
                                    <h4 className='monthly-group'><b>{getMonthlyGroup(k)}</b></h4>
                                    <div>{
                                        sessionMap[k].map((sessInfo, j) => (
                                            <div key={j}>
                                                <SessionItem
                                                    session={sessInfo.session}
                                                    viewType="events"
                                                >
                                                    <div className="buttons">
                                                        {renderRegistrationStatus(sessInfo.session, sessInfo.no)}

                                                        {
                                                            sessInfo.session["Flyer"] ? (
                                                                <Button
                                                                    className='btn-session'
                                                                    color="primary"
                                                                    size="sm"
                                                                    onClick={(e) => window.open(sessInfo.session["Flyer"][0].url, "_blank")}
                                                                >View Flyer</Button>
                                                            ) : null
                                                        }

                                                        {
                                                            canJoin(sessInfo.session) ? (
                                                                <Button
                                                                    className='btn-session'
                                                                    color="success"
                                                                    size="sm"
                                                                    onClick={(e) => window.open(sessInfo.session["WebEx/Zoom Link"], "_blank")}
                                                                >Join</Button>
                                                            ) : null
                                                        }
                                                    </div>
                                                </SessionItem>
                                            </div>
                                        ))
                                    }</div>
                                </div>
                            ))
                        )
                    }
                </div>
            </div>
            <OverlayLoading visible={processing} title="Registering..." />
        </div>
    )
}

export default Screen;