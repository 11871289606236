import React from 'react';
import {
    FormGroup,
    Input,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
} from "reactstrap";
import Loading from '../components/Loading';
import { getAllCommunities } from '../airtables/Communities';
import { setSession, getSession } from '../utils/session';
import { filterProviderSessions } from '../airtables/ProviderSessions';
import { LinearProgress } from '@mui/material';
import { CONNECTEDNORTH_SIGNTYPE } from '../config';
import SessionDetail from '../pages/modals/Session';
import { toast } from 'react-toastify';
import { logEvent } from 'firebase/analytics';
import Gap from '../components/Gap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { analytics } from '../firebase';

const sortlist = [
    { id: 0, label: 'Indigenous Provider', icon: 'sort-alpha-down', sort: [{ field: 'Indigenous Provider?', direction: 'desc' }, { field: 'Average Rating', direction: 'desc' }] },
    { id: 1, label: 'Alphabetical (a-z)', icon: 'sort-alpha-down', sort: [{ field: 'Title', direction: 'asc' }] },
    { id: 2, label: 'Alphabetical (z-a)', icon: 'sort-alpha-up', sort: [{ field: 'Title', direction: 'desc' }] },
    { id: 3, label: 'Most Booked (9-0)', icon: 'sort-numeric-up', sort: [{ field: '# Booked', direction: 'desc' }] },
    { id: 4, label: 'Least Booked (0-9)', icon: 'sort-numeric-down', sort: [{ field: '# Booked', direction: 'asc' }] }
];

const culturalGroups = [
    "Algonquin",
    "Anishinaabe",
    "Cayuga",
    "Coast Salish",
    "Dakota",
    "Dene",
    "Kaska",
    "Lakota",
    "Mi'kmaq",
    "Mohawk",
    "Nakoda",
    "North Tutchone",
    "Odawa",
    "Oji-Cree",
    "Ojibwe",
    "Oneida",
    "Onondaga",
    "Plains Cree",
    "Potawatomi",
    "Saulteaux",
    "Seneca",
    "Sioux",
    "South Tutchone",
    "Swampy Cree",
    "Tagish",
    "Tlingit",
    "Tuscarora",
    "Woodlands Cree"
]

class Page extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            subjects: [],
            grades: [
                { name: 'JK' },
                { name: 'K' },
                { name: '1' },
                { name: '2' },
                { name: '3' },
                { name: '4' },
                { name: '5' },
                { name: '6' },
                { name: '7' },
                { name: '8' },
                { name: '9' },
                { name: '10' },
                { name: '11' },
                { name: '12' },
                { name: 'Professional Development' },
            ],
            language: '',
            search: '',
            wellBeingLink: [
                { name: 'Arts & Recreation' },
                { name: 'Culture, Language & Identity' },
                { name: 'Food & Nutrition' },
                { name: 'Hope For the Future' },
                { name: 'Relationship with Staff & Authority' },
                { name: 'Relationship with Students/Peers' },
                { name: 'Parent & Family Involvement' }
            ],
            indigenousGroups: [
                { name: "First Nations" },
                { name: "Inuit" },
                { name: "Métis" }
            ],
            indigenousGroup: "",
            ingigenousCommunity: "",
            culturalGroup: "",

            isCollapsedLanguage: true,
            isCollapsedGrade: true,
            isCollapsedSubject: true,
            isCollapsedWellBeingLink: true,

            subjectLoading: true,
            loading: false,
            isExpanded: true,

            sessions: [],
            sortInfo: sortlist[0],
            openDetailModal: false,
            activeSession: null,
            userType: null,
            subjectMap: {},
            isStart: true,
            communities: []
        }

        this.t = props.t;
        this.i18n = props.i18n;
    }

    componentDidMount = async () => {
        const userType = getSession(CONNECTEDNORTH_SIGNTYPE);
        this.setState({ userType });

        var subjects = this.props.subjects;
        this.setState({ subjects, subjectLoading: false });
        setSession("totalSubjects", subjects);
        var communities = await getAllCommunities();
        this.setState({ communities })
    }

    onSearch = async () => {
        const { search } = this.state;

        this.setState({ loading: true })
        var filterGrades = []
        this.state.grades.forEach(grade => {
            if (grade.checked) filterGrades.push(grade);
        })

        var filterSubject = []
        this.state.subjects.forEach(subject => {
            if (subject.checked) filterSubject.push(subject);
        })

        var filterWellBeingLink = [];
        this.state.wellBeingLink.forEach(linkItem => {
            if (linkItem.checked) filterWellBeingLink.push(linkItem);
        })

        var iGroups = [];
        this.state.indigenousGroups.forEach(ig => {
            if (ig.checked) iGroups.push(ig);
        })

        const searchStr = search ? search.trim() : "";

        const params = {
            search: searchStr ? searchStr : null,
            language: this.state.language ? this.state.language : null,
            filterGrades: filterGrades.length === 0 ? null : filterGrades,
            filterSubject: filterSubject.length === 0 ? null : filterSubject,
            filterWellBeingLink: filterWellBeingLink.length === 0 ? null : filterWellBeingLink,
        }

        try {
            const sessions = await filterProviderSessions(
                params.search,
                params.language,
                params.filterGrades,
                params.filterSubject,
                params.filterWellBeingLink,
                iGroups,
                this.state.ingigenousCommunity,
                this.state.culturalGroup,
                this.state.sortInfo.sort
            )

            logEvent(analytics, 'search', { query: this.state.search, result: sessions.length });
            this.setState({ sessions, isExpanded: false, loading: false, isStart: false })
            // hideLoading();
        } catch (error) {
            // hideLoading();
            console.log(error)
            this.setState({ loading: false })
            toast.error(error.toString())
        }
    }

    async resort(sort) {
        await this.setState({ sortInfo: sort });
        this.onSearch();
    }

    toggleAdvancedSearch() {
        this.setState({ isExpanded: !this.state.isExpanded });
    }

    onRequest() {
        this.setState({ openDetailModal: false, openRequestModal: true });
    }

    onNewSearch() {
        var subs = [...this.state.subjects];
        for (var i = 0; i < subs.length; i++) {
            subs[i].checked = false;
        }
        this.setState({
            sessions: [],
            subjects: subs,
            language: 'English',
            search: '',
            grades: [
                { name: 'JK' },
                { name: 'K' },
                { name: '1' },
                { name: '2' },
                { name: '3' },
                { name: '4' },
                { name: '5' },
                { name: '6' },
                { name: '7' },
                { name: '8' },
                { name: '9' },
                { name: '10' },
                { name: '11' },
                { name: '12' },
                { name: 'Professional Development' },
            ],
            wellBeingLink: [
                { name: 'Arts & Recreation' },
                { name: 'Culture, Language & Identity' },
                { name: 'Food & Nutrition' },
                { name: 'Hope For the Future' },
                { name: 'Relationship with Staff & Authority' },
                { name: 'Relationship with Students/Peers' },
                { name: 'Parent & Family Involvement' }
            ],
            indigenousGroups: [
                { name: "First Nations" },
                { name: "Inuit" },
                { name: "Métis" }
            ],
            indigenousGroup: "",
            ingigenousCommunity: "",
            culturalGroup: "",
            isStart: true,
            isExpanded: true,
        });
    }

    renderSearchBar() {
        const { appLocale, t } = this.props;

        return (
            <div className="search-bar">
                <Gap height={20} />
                <Row>
                    <Col xs="12">
                        <FormGroup>
                            <InputGroup className="mb-4">
                                <Input
                                    placeholder={t("search")}
                                    type="text"
                                    defaultValue={this.state.search}
                                    onChange={(e) => this.setState({ search: e.target.value })}
                                    onKeyDown={(event) => {
                                        if (event.keyCode === 13) this.onSearch();
                                    }}
                                />
                                <InputGroupText style={{ cursor: 'pointer' }} onClick={() => this.onSearch()}>
                                    <i className="fa fa-search" />
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                {this.state.isExpanded ? (!this.state.subjectLoading ? (
                    <Row>
                        <Col>
                            <div className="multicheck-container">
                                <label>{this.t("grade-levels")}</label>
                                <div className="multicheck">
                                    {
                                        this.state.grades.map((grade, i) => (
                                            <div
                                                className={grade.checked ? "multicheck-item item-checked" : "multicheck-item"}
                                                key={i}
                                                onClick={
                                                    () => {
                                                        if (!grade.checked) grade.checked = false;
                                                        var grades = this.state.grades;
                                                        grades[i].checked = !grade.checked
                                                        this.setState({ grades });
                                                    }
                                                }
                                            >
                                                <span>{grade.name}</span>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className="multicheck-container">
                                <label>{this.t("subject-areas")}</label>
                                <div className="multicheck">
                                    {
                                        this.state.subjects.map((subject, i) => {
                                            const subjectName = appLocale === 'fr' ? subject['Subject (FR)'] : subject['Subject'];
                                            if (!subjectName) return null;
                                            return (
                                                <div
                                                    className={subject.checked ? "multicheck-item item-checked" : "multicheck-item"}
                                                    key={i}
                                                    onClick={
                                                        () => {
                                                            if (!subject.checked) subject.checked = false;
                                                            var subjects = this.state.subjects;
                                                            subjects[i].checked = !subject.checked
                                                            this.setState({ subjects });
                                                        }
                                                    }
                                                ><span>{subjectName}</span></div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="multicheck-container">
                                <label>{this.t("well-bing-links")}</label>
                                <div className="multicheck">
                                    {
                                        this.state.wellBeingLink.map((linkItem, i) => (
                                            <div
                                                className={linkItem.checked ? "multicheck-item item-checked" : "multicheck-item"}
                                                key={i}
                                                onClick={
                                                    () => {
                                                        if (!linkItem.checked) linkItem.checked = false;
                                                        var wellBeingLink = this.state.wellBeingLink;
                                                        wellBeingLink[i].checked = !linkItem.checked
                                                        this.setState({ wellBeingLink });
                                                    }
                                                }
                                            >
                                                <span>{this.t(linkItem['name'])}</span>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className="multicheck-container">
                                <label>{this.t("language")}</label>
                                <div className="multicheck">
                                    <div
                                        className={this.state.language === 'English' ? "multicheck-item item-checked" : "multicheck-item"}
                                        onClick={() => this.setState({ language: 'English' })}
                                    ><span>English</span></div>

                                    <div
                                        className={this.state.language === 'French' ? "multicheck-item item-checked" : "multicheck-item"}
                                        onClick={() => this.setState({ language: 'French' })}
                                    ><span>French</span></div>
                                </div>
                            </div>

                            <Row style={{ marginBottom: 20 }}>
                                <Col md={4}>
                                    <div className="multicheck-container">
                                        <label>{this.t("indigenous-groups")}</label>
                                        <div className="multicheck">
                                            {
                                                this.state.indigenousGroups.map((igroup, i) => (
                                                    <div
                                                        className={igroup.checked ? "multicheck-item item-checked" : "multicheck-item"}
                                                        key={i}
                                                        onClick={
                                                            () => {
                                                                if (!igroup.checked) igroup.checked = false;
                                                                var indigenousGroups = this.state.indigenousGroups;
                                                                indigenousGroups[i].checked = !igroup.checked
                                                                this.setState({ indigenousGroups });
                                                            }
                                                        }
                                                    >
                                                        <span>{igroup['name']}</span>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="flexview">
                                        <div className="orview">
                                            <Label>I</Label>
                                            <div className="orspan">
                                                <span>{this.t("or")}</span>
                                            </div>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <FormGroup>
                                                <Label for="ingigenousCommunity">{this.t("community")}</Label>
                                                <Input
                                                    type="select"
                                                    id="ingigenousCommunity"
                                                    defaultValue={this.state.ingigenousCommunity}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            ingigenousCommunity: e.target.value,
                                                            culturalGroup: ''
                                                        })
                                                    }}
                                                >
                                                    <option></option>
                                                    {
                                                        this.state.communities.map((com, i) => (
                                                            <option key={i}>{com.Name}</option>
                                                        ))
                                                    }
                                                </Input>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="flexview">
                                        <div className="orview">
                                            <Label>I</Label>
                                            <div className="orspan">
                                                <span>{this.t("or")}</span>
                                            </div>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <FormGroup>
                                                <Label for="culturalGroup">{this.t("cultural-group")}</Label>
                                                <Input
                                                    type="select"
                                                    id="culturalGroup"
                                                    defaultValue={this.state.culturalGroup}
                                                    // value={this.state.culturalGroup}
                                                    onChange={(e) => this.setState({ culturalGroup: e.target.value })}
                                                >
                                                    <option></option>
                                                    {
                                                        culturalGroups.map((g, i) => (
                                                            <option key={i}>{g}</option>
                                                        ))
                                                    }
                                                </Input>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col align="center" xs="12">

                            <Button
                                color="primary"
                                size="md"
                                type="button"
                                onClick={() => this.onSearch()}
                            ><i className="fa fa-search" />&nbsp;{t("search")}</Button>
                        </Col>
                    </Row>
                ) : (
                    <div style={{ width: '100%', paddingTop: 20 }} align="center">
                        <Loading size={20} />
                    </div>
                )) : null}

                <div style={{ width: '100%' }} align="right">
                    <span style={{ color: 'darkblue', fontSize: 12, cursor: 'pointer' }} onClick={() => this.toggleAdvancedSearch()}>
                        {this.state.isExpanded ? "Collapse" : "Adjust Search Parameters"}
                    </span>
                    <div style={{ width: '100%', height: 2, background: 'lightgray' }} />
                </div>
            </div>
        )
    }

    renderGroupBar(no) {
        const { sortInfo, sessions } = this.state;

        if (sortInfo.label !== "Indigenous Provider") return null;

        const getIndigenousProvider = (session) => {
            if (!session) return false;
            if (!session["Indigenous Provider?"] || session["Indigenous Provider?"].length === 0) return false;
            if (session["Indigenous Provider?"][0]) return true;
            return false;
        }

        if (no === 0 || getIndigenousProvider(sessions[no]) !== getIndigenousProvider(sessions[no - 1])) {
            return (
                <div className="indigenous-group"><span>{getIndigenousProvider(sessions[no]) ? "Indigenous Content Providers" : "Non-Indigenous Content Providers"}</span></div>
            )
        }

        return null;
    }

    renderSessions() {
        if (this.state.loading) {
            return (null)
        }

        const { sessions } = this.state;
        const { subjectMap } = this.props;

        return (
            sessions.length > 0 ? this.state.sessions.map((session, index) => (
                <div key={index}>
                    {this.renderGroupBar(index)}
                    <div className="session-item" onClick={() => window.open(`/cn/session/${session.id}`)}>
                        <div style={{ flex: 1 }}>
                            <div><span style={{ fontSize: 16, color: 'black' }}>{session['Title']}</span></div>
                            <div className="session-item-tags">
                                {
                                    (session['Grade(s)'] && session['Grade(s)'].length) && (
                                        session['Grade(s)'].map((grade, i) => (
                                            <div className="session-item-tag green-tag" key={i}>
                                                <span>{grade}</span>
                                            </div>
                                        ))
                                    )
                                }

                                {
                                    session['Subject'] ? session['Subject'].map((id, i) => (
                                        <div className="session-item-tag pink-tag" key={i}>
                                            <span>{subjectMap[id].Subject}</span>
                                        </div>
                                    )) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )) : (
                <div className="nolist-placeholder" align="center">
                    {
                        !this.state.isStart && (
                            <span>{this.t("no-search-result")}</span>
                        )
                    }
                </div>
            )
        )
    }

    render() {
        const { t } = this.props;

        return (
            <div className="main-container">
                <Row>
                    <Col xs="12" sm="3">
                        <span className="page-topic" onClick={() => this.onNewSearch()}>{t("search")}</span>
                    </Col>
                    <Col xs="12" sm="9" align="right">
                        <a href="/cn/browser/curriculum">
                            <span
                                className="page-topic"
                                style={{ fontSize: 20 }}
                            >{this.t("browse-by-curriculum-linkages")} <i className="fa fa-angle-right" style={{ fontSize: 16 }} /></span>
                        </a>
                    </Col>
                </Row>

                {this.renderSearchBar()}
                {this.state.loading ? <LinearProgress /> : null}
                <div style={{ marginTop: 10 }}>
                    {
                        this.state.sessions.length > 0 ? (
                            <UncontrolledDropdown>
                                <DropdownToggle caret color="secondary">
                                    {this.state.sortInfo.label}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {
                                        sortlist.map((sort, i) => (
                                            <DropdownItem href="#pablo" onClick={e => this.resort(sort)} key={i}>
                                                {sort.label}
                                            </DropdownItem>
                                        ))
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        ) : null
                    }
                    <div className="general-container">
                        {this.renderSessions()}
                    </div>
                </div>
                {
                    (this.state.openDetailModal && this.state.activeSession !== null) && (
                        <SessionDetail
                            session={this.state.activeSession}
                            userType={this.state.userType}
                            onToggle={() => this.setState({ openDetailModal: !this.state.openDetailModal })}
                        />
                    )
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        subjects: state.appInfo.totalSubjects,
        appLocale: state.appInfo.appLocale,
        myFavorites: state.appInfo.myFavorites,
        subjectMap: state.appInfo.subjectMap
    }
}

export default connect(mapStateToProps, null)(withTranslation()(Page))
