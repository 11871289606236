import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Input } from "reactstrap";
import { BestPracticeLabels, BestPracticeReflectionFieldNames } from '../../../utils/constants';

const Commitment = ({ submitConfirm, type }) => {
    const [confirm, setConfirm] = useState(false);
    const { t } = useTranslation();
    const [reflection, setReflection] = useState("");
    const { userInfo } = useSelector(state => state.appInfo);
    const alreadyChecked = userInfo["Best Practice Commitments"] ? userInfo["Best Practice Commitments"].indexOf(BestPracticeLabels[type].name) >= 0 : false;

    useEffect(() => {
        setReflection(userInfo[BestPracticeReflectionFieldNames[type]] ? userInfo[BestPracticeReflectionFieldNames[type]] : '')
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="confirm-reflection">
            <div className="reflection">
                <span><strong>{t("confirm-refection.title")}</strong></span><br />
                {
                    alreadyChecked ? (
                        <label>{t("confirm-refection.already-checked-desc")}</label>
                    ) : (
                        <label>{t("confirm-refection.desc")}</label>
                    )
                }
                <Input
                    type="textarea"
                    rows={6}
                    placeholder={t("confirm-refection.commitment-placeholders." + type)}
                    onChange={e => setReflection(e.target.value)}
                    defaultValue={reflection}
                />
            </div>
            {
                !alreadyChecked && (
                    <div className="confirm custom-control custom-checkbox mb-3">
                        <input
                            className="custom-control-input"
                            id="customCheck1"
                            type="checkbox"
                            defaultChecked={confirm}
                            onChange={(e) => setConfirm(!confirm)}
                        />
                        <label className="custom-control-label" htmlFor="customCheck1">{t("bp-commitment-desc")}</label>
                    </div>
                )
            }
            {
                alreadyChecked ? (
                    <Button
                        className="ml-auto"
                        color="primary"
                        type="button"
                        onClick={() => submitConfirm(reflection)}
                    >{t("update-reflections")}</Button>
                ) : (
                    <Button
                        className="ml-auto"
                        color="primary"
                        type="button"
                        disabled={!confirm}
                        onClick={() => submitConfirm(reflection)}
                    >{t("confirm")}</Button>
                )
            }
            <p style={{ marginTop: 10, fontSize: 14 }}><em>{t("bp-helprequired")}</em></p>
        </div>
    )
}

export default Commitment;
