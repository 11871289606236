import React from 'react';
import Loading from '../../components/Loading';
import {
    Button,
    Modal
} from 'reactstrap'
import { getAllTeams } from '../../airtables/Team';
import { toast } from 'react-toastify';
import { updateSession } from '../../airtables/Sessions';

export default class ReassignSchoolLead extends React.Component {
    constructor() {
        super()
        this.state = {
            showModal: false,
            sessionId: null,
            teams: [],
            leads: {},
            saving: false
        }
    }

    componentDidMount() {
        getAllTeams().then(teams => {
            this.setState({ teams, loading: false });
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            showModal: nextProps.showModal,
            sessionId: nextProps.sessionId,
            leads: nextProps.leads
        }
    }

    onSave() {
        var leads = Object.keys(this.state.leads);
        this.setState({saving: true});
        updateSession(this.state.sessionId, {'School Lead': leads}).then(() => {
            this.setState({saving: false})
            toast.success("Leads are Reassigned Successfully!")
            this.props.onDone();
        }).catch(error => {
            this.setState({saving: false});
            toast.error(error.toString())
        })
    }

    render() {
        return (
            <Modal
                className="modal-dialog-centered"
                isOpen={this.state.showModal}
                toggle={() => this.props.onToggle()}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="modal-title-default">Reassign Session Lead</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.props.onToggle()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div style={{ height: 400, overflow: 'scroll', padding: '0 10px' }}>
                        {
                            this.state.teams.map((team, i) => (
                                <div className="custom-control custom-checkbox mb-3" key={i}>
                                    <input
                                        className="custom-control-input"
                                        id={`customcheckbox${i}`}
                                        type="checkbox"
                                        defaultChecked={this.state.leads[team.id] ? true : false}
                                        onChange={
                                            (e) => {
                                                var leads = this.state.leads;
                                                if (e.target.checked) {
                                                    leads[team.id] = true
                                                } else {
                                                    delete leads[team.id];
                                                }
                                                this.setState({leads});
                                            }
                                        }
                                    />
                                    <label className="custom-control-label" htmlFor={`customcheckbox${i}`}>
                                        {team.Name}
                                </label>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="modal-footer">
                    <Button
                        color="primary"
                        type="button"
                        onClick={() => this.onSave()}
                    >
                        {this.state.saving ? (<Loading size={14} />) : (null)}Save
                    </Button>
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.props.onToggle()}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        )
    }
}