import base from './config';

const tableName = "Curriculum Links"

export const getCurriculumLinks = (grade, region, subject, sort) => {
    return new Promise((resolve, reject) => {
        let filter = "";
        if (grade) {
            filter += `, FIND(" ${grade},", CONCATENATE(" ", ARRAYJOIN({Grade(s)}), ","))` // 
        }

        if (region) {
            filter += `, FIND("${region}", {Region})`
        }

        if (subject) {
            filter += `, FIND("${subject}", {Subject})`
        }

        filter = `AND({Linked Sessions} > 0${filter})`;

        const links = [];
        base(tableName).select({
            filterByFormula: filter,
            sort: sort ? sort : []
        }).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                const link = {
                    id: record.id,
                    ...record.fields
                }

                links.push(link);
            })
            fetchNextPage()
        }).then(res => {
            resolve(links);
        }).catch(error => {
            console.log(error)
            resolve([])
        })
    })
}

export const getLinkFromKeyword = (keyword, region) => {
    return new Promise((resolve, reject) => {
        const lResult = []
        const kWord = keyword.toUpperCase();
        var filterByFormula = `OR(REGEX_MATCH(UPPER({Strand}), '${kWord}'), REGEX_MATCH(UPPER({Topic}), '${kWord}'))`;
        if (!region) {
            filterByFormula = `AND(${filterByFormula}, {Linked Sessions} > 0)`
        } else {
            filterByFormula = `AND(${filterByFormula}, {Linked Sessions} > 0, FIND("${region}", {Region}))`
        }

        base(tableName).select({
            filterByFormula,
            sort: [
                { field: "Subject", direction: 'asc' },
                { field: "ID", direction: 'asc' }
            ]
        }).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                const link = {
                    id: record.id,
                    ...record.fields
                }

                lResult.push(link);
            })
            fetchNextPage()
        }).then(res => {
            resolve(lResult)
        }).catch(error => {
            resolve([])
        })
    });
}