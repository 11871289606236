export const GradeLevels = [
    'K',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    'Professional Development',
    'After School Club'
];

export const CurrentGradeLevels = [
    'JK',
    'K',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12'
]

export const FBUSEREMAIL = 'connectednorth@user.com';
export const FBUSERPASSWORD = 'connectednorthuser';

export const BestPracticeLen = 5;

export const BestPracticeLabels = {
    "introduction": { name: "Introduction", locale: "best-practices-content.intro.title" },
    "meaningful-land-acknowledgement": { name: "Meaningful Land Acknowledgement", locale: "best-practices-content.mla.title" },
    "facilitation-of-student-interactivity": { name: "Facilitation of Student Interactivity", locale: "best-practices-content.fsi.title" },
    "indigenous-histories-contributions": { name: "Indigenous Histories & Contributions", locale: "best-practices-content.ihc.title" },
    "trauma-informed-approaches": { name: "Trauma-informed Approaches", locale: "best-practices-content.tfa.title" },
    "creatively-customized-content": { name: "Creatively Customized Content", locale: "best-practices-content.ccc.title" },
    "recording-practices": { name: "Recording Practices", locale: "best-practices-content.rp.title" }
}

export const BestPracticeReflectionFieldNames = {
    "meaningful-land-acknowledgement": "ReflectionMLA",
    "facilitation-of-student-interactivity": "ReflectionFSI",
    "indigenous-histories-contributions": "ReflectionIHC",
    "trauma-informed-approaches": "ReflectionTIA",
    "creatively-customized-content": "ReflectionCCC"
}