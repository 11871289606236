import React, { useEffect, useState } from 'react';
import {
    Button,
    Modal
} from 'reactstrap';
import { selectProviderSessionById } from '../../airtables/ProviderSessions';
import Gap from '../../components/Gap';
import Loading from '../../components/Loading';
import SessionDetail from '../../pages/modals/Session';

const CurriculumLink = ({ curriculum, onToggle, userType }) => {
    const [loading, setLoading] = useState(true);
    const [sessions, setSessions] = useState([]);
    const [activeSession, setActiveSession] = useState(null);
    const [sessionModal, openSessionModal] = useState(false);

    useEffect(() => {
        const loadSessions = async () => {
            if (curriculum) {
                const sessIds = curriculum["Sessions"];
                const sesses = []
                for (var i = 0; i < sessIds.length; i++) {
                    const session = await selectProviderSessionById(sessIds[i]);
                    if (session['Status'] === 'Active (Open for booking)' ||
                        session['Status'] === 'Consider Another Option (See notes)') {
                        sesses.push(session);
                        setSessions([...sesses]);
                    }
                }
            }
            setLoading(false);
        }

        loadSessions();
    }, [curriculum])

    return (
        <>
            <Modal
                className="modal-dialog-centered"
                isOpen={true}
                toggle={onToggle}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="modal-title-default">Aligned Sessions</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={onToggle}
                    ><span aria-hidden={true}>×</span></button>
                </div>
                <div className="modal-body">
                    <div className="sessions-container">
                        <Gap height={20} />
                        {
                            sessions.map((session, i) => (
                                <div
                                    key={i}
                                    className="session-item"
                                    onClick={e => {
                                        setActiveSession(session);
                                        openSessionModal(true);
                                    }}
                                >
                                    <span>{session["Title"]}</span>
                                </div>
                            ))
                        }
                        {
                            loading && (
                                <div style={{ marginTop: 30 }}>
                                    <Loading size={20} />
                                </div>
                            )
                        }
                        <Gap height={20} />
                    </div>
                </div>
                <div className="modal-footer">
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={onToggle}
                    >Close</Button>
                </div>
            </Modal>

            {
                (sessionModal && activeSession !== null) && (
                    <SessionDetail
                        session={activeSession}
                        userType={userType}
                        onToggle={() => openSessionModal(false)}
                    />
                )
            }
        </>
    );
}

export default CurriculumLink;