import React, { useEffect, useState } from "react";
import {
    Button,
    FormGroup,
    Input,
    InputGroupText,
    InputGroup,
} from "reactstrap";
import * as EmailValidator from 'email-validator';
import { getTeacherByEmail, updateTeacher } from '../../airtables/Teachers';
import { getTeamByEmail, updateTeam } from '../../airtables/Team';
import { getProviderByEmail, getProviderById, updateProvider, getProviderByDomain } from '../../airtables/Providers';
import { getPresenterByEmail, getPresenterById, updatePresenter } from '../../airtables/Presenters';
import { selectClusterByDomain } from '../../airtables/Clusters';
import { setSession, getSession } from "../../utils/session";
import { CONNECTEDNORTH_SIGNINFO, CONNECTEDNORTH_SIGNTYPE, CONNECTEDNORTH_VERIFIED_PHONENUMBER } from '../../config';
import Loading from '../../components/Loading.js';
import { sendVerifySms, verifySmsCode } from '../../utils/sms';
import { useDispatch } from "react-redux";
import { setAppUserInfo, setAppUserType } from '../../redux/actions';
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import './styles.scss';
import { isContainInvalidChar } from "../../utils/url";
import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { analytics } from '../../firebase'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Login = () => {
    const [height, setHeight] = useState(0);
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [inValidCharFromEmail, setInvalidCharFromEmail] = useState(false)
    const [signing, setSigning] = useState(false);
    const dispatch = useDispatch();
    let query = useQuery();
    const url = query.get("url");
    const { t } = useTranslation();

    const [appLoading, setAppLoading] = useState(true);

    const resizeListener = () => {
        setHeight(window.innerHeight);
        setAppLoading(false);
    }

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;

        setTimeout(() => {
            resizeListener();
        })

        window.addEventListener('resize', resizeListener)
        return () => {
            window.removeEventListener("resize", resizeListener);
        }
    }, [])

    const logSignin = async (user, userType) => {
        await Promise.all([
            setUserId(analytics, analytics, user),
            setUserProperties(analytics, 'user_type', userType),
            logEvent(analytics, 'login', {
                email: user,
                userType
            })
        ]);
    }

    const checkSpecialCharsFromEmail = (em) => {
        setInvalidCharFromEmail(isContainInvalidChar(em));
    }

    const onLogin = async () => {
        if (email === '') {
            setError(t("type-email-address"))
            return
        }

        if (!EmailValidator.validate(email)) {
            setError(t("invalid-email"));
            return;
        }

        setSigning(true)
        try {
            const teacher = await getTeacherByEmail(email);
            if (teacher) {
                teacher["Platform Logins"] = teacher["Platform Logins"] ? teacher["Platform Logins"] + 1 : 1;
                let params = {
                    "Platform Logins": teacher["Platform Logins"]
                }
                if (!teacher["Status"]) params.Status = "Active";

                updateTeacher(teacher.id, params);
                await logSignin(teacher['Email'], 'Teacher');
                await setSession(CONNECTEDNORTH_SIGNINFO, teacher);
                await setSession(CONNECTEDNORTH_SIGNTYPE, "Teacher");

                dispatch(setAppUserInfo(teacher));
                dispatch(setAppUserType("Teacher"));

                setSigning(false);
                window.location.href = url ? url : "/cn";
                return;
            }

            const team = await getTeamByEmail(email);
            if (team) {
                const verifiedPhonenumber = await getSession(CONNECTEDNORTH_VERIFIED_PHONENUMBER);
                if (verifiedPhonenumber && verifiedPhonenumber === team['Phone']) {
                    await logSignin(team['Email'], 'Team');
                    await setSession(CONNECTEDNORTH_SIGNINFO, team);
                    await setSession(CONNECTEDNORTH_SIGNTYPE, "Team");

                    dispatch(setAppUserInfo(team));
                    dispatch(setAppUserType("Team"));

                    setSigning(false)
                    window.location.href = url ? url : "/cn";
                } else {
                    await sendVerifySms(team['Phone']);
                    var smsCode = window.prompt(t("enter-sms-for-identity"));
                    try {
                        const res = await verifySmsCode(team['Phone'], smsCode)
                        if (res.valid) {
                            team["Platform Logins"] = team["Platform Logins"] ? team["Platform Logins"] + 1 : 1;
                            updateTeam(team.id, { "Platform Logins": team["Platform Logins"] })
                            await logSignin(team['Email'], 'Team');
                            await setSession(CONNECTEDNORTH_VERIFIED_PHONENUMBER, team['Phone'])
                            await setSession(CONNECTEDNORTH_SIGNINFO, team);
                            await setSession(CONNECTEDNORTH_SIGNTYPE, 'Team');

                            dispatch(setAppUserInfo(team));
                            dispatch(setAppUserType("Team"));

                            setSigning(false);
                            window.location.href = url ? url : "/cn";
                        } else {
                            setError(t("invalid-code"));
                            setSigning(false);
                        }
                    } catch (error) {
                        setError(error.toString());
                        setSigning(false);
                    }
                }
                return;
            }

            let provider = await getProviderByEmail(email);
            if (provider) {
                provider["Platform Logins"] = provider["Platform Logins"] ? provider["Platform Logins"] + 1 : 1;
                updateProvider(provider.id, { "Platform Logins": provider["Platform Logins"] })
                provider = await getProviderById(provider.id);
                await logSignin(provider['Email'], 'Provider');
                await setSession(CONNECTEDNORTH_SIGNINFO, provider);
                await setSession(CONNECTEDNORTH_SIGNTYPE, "Provider");

                dispatch(setAppUserInfo(provider));
                dispatch(setAppUserType("Provider"));

                setSigning(false)
                window.location.href = url ? url : "/cn";
                return;
            }

            let presenter = await getPresenterByEmail(email);
            if (presenter) {
                presenter["Platform Logins"] = presenter["Platform Logins"] ? presenter["Platform Logins"] + 1 : 1;
                updatePresenter(presenter.id, { "Platform Logins": presenter["Platform Logins"] });
                presenter = await getPresenterById(presenter.id);

                await logSignin(presenter['Email'], 'Presenter');
                await setSession(CONNECTEDNORTH_SIGNINFO, presenter);
                await setSession(CONNECTEDNORTH_SIGNTYPE, "Presenter");

                dispatch(setAppUserInfo(presenter));
                dispatch(setAppUserType("Presenter"));

                setSigning(false)
                window.location.href = url ? url : "/cn";
            }

            let dms = email.split("@");
            provider = await getProviderByDomain(dms[1]);
            if (provider) {
                setSigning(false);
                window.location.href = `/register-presenter/${dms[0]}/${provider.id}`;
                return;
            }

            const cluster = await selectClusterByDomain(dms[1]);
            if (cluster) {
                setSigning(false);
                window.location.href = `/register-teacher/${dms[0]}/${cluster.id}`;
                return;
            }

            setError(t("no-exist-user-with-email"));
            setSigning(false);

        } catch (error) {
            setError(error.toString());
            setSigning(false);
        }
    }

    if (appLoading) return (
        <div style={{ position: 'fixed', inset: "0 0 0 0", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Loading size={64} />
        </div>
    );

    return (
        <main className="login-screen">
            <section
                className="continer"
                style={{ height: height }}
            >
                <div className="login-container">
                    <div className="email-input">
                        <FormGroup>
                            <InputGroup className="input-group-alternative">
                                <InputGroupText>
                                    <i className="ni ni-email-83" />
                                </InputGroupText>
                                <Input
                                    bsSize="lg"
                                    placeholder={t("email")}
                                    type="email"
                                    value={email}
                                    onChange={e => {
                                        let em = e.target.value;
                                        em = em.replace(/’/g, "'");
                                        checkSpecialCharsFromEmail(em);
                                        setEmail(em)
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.keyCode === 13) onLogin();
                                    }}
                                />
                            </InputGroup>
                        </FormGroup>
                    </div>
                    <div className="error">
                        {
                            error ? (
                                <span>{error}</span>
                            ) : (null)
                        }

                        {
                            inValidCharFromEmail ? (<span>Not supported special characters!</span>) : null
                        }
                    </div>
                    <div className="action">
                        <Button
                            color="primary"
                            type="button"
                            onClick={() => onLogin()}
                            disabled={signing}
                            size="lg"
                        >
                            {signing ? (<Loading size={14} />) : (null)}{t("sign_in")}
                        </Button>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Login;
