import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Headroom from "headroom.js";
import {
    UncontrolledCollapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
    UncontrolledTooltip
} from "reactstrap";
import { removeSession } from "../../utils/session";
import { CONNECTEDNORTH_SIGNINFO, CONNECTEDNORTH_SIGNTYPE } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { setAppUserInfo, setAppUserType } from '../../redux/actions';
import { useTranslation } from "react-i18next";
import CNNavItem from "./NavItem";

const DemoNavbar = ({ path }) => {
    const [collapseClasses, setCollapseClasses] = useState("");
    const { userType, userInfo } = useSelector(state => state.appInfo);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        let headroom = new Headroom(document.getElementById("navbar-main"));
        headroom.init();
    }, [])

    const onExiting = () => {
        setCollapseClasses("collapsing-out");
    }

    const onExited = () => {
        setCollapseClasses("");
    }

    const onSignout = () => {
        if (window.confirm("Are you sure you want to sign out?")) {
            removeSession(CONNECTEDNORTH_SIGNINFO);
            removeSession(CONNECTEDNORTH_SIGNTYPE);
            window.location.href = "/login";

            dispatch(setAppUserInfo(null));
            dispatch(setAppUserType(null));
        }
    }

    const onToggle = () => {
        var x = window.matchMedia("(max-width: 991px)");
        if (x.matches) {
            document.getElementById("navbar_global").click();
        }
    }

    const renderNavContent = () => {
        switch (userType) {
            case "Team":
                return (
                    <>
                        <CNNavItem path={path + "/upcoming"} title={t("upcoming")} tooltip={t("upcoming_sessions")} icon="fa-calendar" hideNav={onExiting} />
                        <CNNavItem path={path + "/rate"} title={t("manage-past")} tooltip={t("manage-past-sessions")} icon="fa-star" hideNav={onExiting} />
                        <CNNavItem path={path + "/pending"} title={t("requests")} tooltip={t("pending_session_requests")} icon="fa-hourglass" hideNav={onExiting} />
                        <CNNavItem path={path + "/contact"} title={t("contacts")} tooltip={t("contacts")} icon="fa-address-book" hideNav={onExiting} />
                        <CNNavItem path={path + "/events"} title={t("events")} tooltip={t("events")} icon="fa-group" hideNav={onExiting} />
                        <CNNavItem path={path + "/search"} title={t("search")} tooltip={t("search_sessions")} icon="fa-search" hideNav={onExiting} />
                    </>
                );
            case "Teacher":
                return (
                    <>
                        <CNNavItem path={path + "/upcoming"} title={t("upcoming")} tooltip={t("upcoming_sessions")} icon="fa-calendar" hideNav={onExiting} />
                        <CNNavItem path={path + "/rate"} title={t("past_sessions")} tooltip={t("view-rate-past-sessions")} icon="fa-star" hideNav={onExiting} />
                        <CNNavItem path={path + "/pending"} title={t("requests")} tooltip={t("pending_session_requests")} icon="fa-hourglass" hideNav={onExiting} />
                        <CNNavItem path={path + "/favorites"} title={t("menu-favourites")} tooltip={t("my-favourites")} icon="fa-heart" hideNav={onExiting} />
                        {
                            (userInfo["School PT"] && userInfo["School PT"][0] === "recalRmA0o3OZhdlO" && userInfo["School PT"][0] === "unhidepdweek") && (
                                <CNNavItem
                                    path={path + "/pd-week"}
                                    title={t("pd-week")}
                                    tooltip={t("pd-week")}
                                    icon={(
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width={20} height={17} className="nav-icon">
                                            <path d="M160 64c0-35.3 28.7-64 64-64H576c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H336.8c-11.8-25.5-29.9-47.5-52.4-64H384V320c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32v32h64V64L224 64v49.1C205.2 102.2 183.3 96 160 96V64zm0 256c-53 0-96-43-96-96s43-96 96-96s96 43 96 96s-43 96-96 96zm-26.7 32h53.3C260.3 352 320 411.7 320 485.3c0 14.7-11.9 26.7-26.7 26.7H26.7C11.9 512 0 500.1 0 485.3C0 411.7 59.7 352 133.3 352z" />
                                        </svg>
                                    )}
                                    hideNav={onExiting}
                                />
                            )
                        }
                        <CNNavItem path={path + "/events"} title={t("events")} tooltip={t("events")} icon="fa-group" hideNav={onExiting} />
                        {
                            userInfo['Email'] === 'ron.cameron@gapps.rrdsb.com' && (
                                <CNNavItem path={path + "/mystats"} title={t("menu-stats")} tooltip={t("my-stats")} icon="fa-map" hideNav={onExiting} />
                            )
                        }
                        {
                            (userInfo['Limit Booking to Collection'] && userInfo['Limit Booking to Collection'].length) ? (
                                <CNNavItem path={path + "/collection/" + userInfo['Limit Booking to Collection'][0]} title={t("browse")} tooltip={t("search_sessions")} icon="fa-search" hideNav={onExiting} />
                            ) : (
                                <CNNavItem path={path + "/search"} title={t("search")} tooltip={t("search_sessions")} icon="fa-search" hideNav={onExiting} />
                            )
                        }
                    </>
                );
            case "Provider":
                return (
                    <>
                        <CNNavItem path={path + "/upcoming"} title={t("upcoming")} tooltip={t("upcoming_sessions")} icon="fa-calendar" hideNav={onToggle} />
                        <CNNavItem path={path + "/pending"} title={t("requests")} tooltip={t("pending_session_requests")} icon="fa-hourglass" hideNav={onToggle} />
                        <CNNavItem path={path + "/mysessions"} title={t("my-sessions")} tooltip={t("search-my-sessions")} icon="fa-list" hideNav={onToggle} />
                        <CNNavItem
                            path={path + "/best-practices/introduction"}
                            title={t("best-practices")}
                            tooltip={t("best-practices")}
                            icon={(
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width={12} height={16} className="nav-icon">
                                    <path d="M288 358.3c13.98-8.088 17.53-30.04 28.88-41.39c11.35-11.35 33.3-14.88 41.39-28.87c7.98-13.79 .1658-34.54 4.373-50.29C366.7 222.5 383.1 208.5 383.1 192c0-16.5-17.27-30.52-21.34-45.73c-4.207-15.75 3.612-36.5-4.365-50.29c-8.086-13.98-30.03-17.52-41.38-28.87c-11.35-11.35-14.89-33.3-28.87-41.39c-13.79-7.979-34.54-.1637-50.29-4.375C222.5 17.27 208.5 0 192 0C175.5 0 161.5 17.27 146.3 21.34C130.5 25.54 109.8 17.73 95.98 25.7C82 33.79 78.46 55.74 67.11 67.08C55.77 78.43 33.81 81.97 25.72 95.95C17.74 109.7 25.56 130.5 21.35 146.2C17.27 161.5 .0008 175.5 .0008 192c0 16.5 17.27 30.52 21.34 45.73c4.207 15.75-3.615 36.5 4.361 50.29C33.8 302 55.74 305.5 67.08 316.9c11.35 11.35 14.89 33.3 28.88 41.4c13.79 7.979 34.53 .1582 50.28 4.369C161.5 366.7 175.5 384 192 384c16.5 0 30.52-17.27 45.74-21.34C253.5 358.5 274.2 366.3 288 358.3zM112 192c0-44.27 35.81-80 80-80s80 35.73 80 80c0 44.17-35.81 80-80 80S112 236.2 112 192zM1.719 433.2c-3.25 8.188-1.781 17.48 3.875 24.25c5.656 6.75 14.53 9.898 23.12 8.148l45.19-9.035l21.43 42.27C99.46 507 107.6 512 116.7 512c.3438 0 .6641-.0117 1.008-.0273c9.5-.375 17.65-6.082 21.24-14.88l33.58-82.08c-53.71-4.639-102-28.12-138.2-63.95L1.719 433.2zM349.6 351.1c-36.15 35.83-84.45 59.31-138.2 63.95l33.58 82.08c3.594 8.797 11.74 14.5 21.24 14.88C266.6 511.1 266.1 512 267.3 512c9.094 0 17.23-4.973 21.35-13.14l21.43-42.28l45.19 9.035c8.594 1.75 17.47-1.398 23.12-8.148c5.656-6.766 7.125-16.06 3.875-24.25L349.6 351.1z" />
                                </svg>
                            )}
                            hideNav={onToggle}
                        />
                        <CNNavItem path={path + "/myinfo"} title={t("my-info")} tooltip={t("view-edit-contact-information")} icon="fa-cog" hideNav={onToggle} />
                    </>
                );
            case "Presenter":
                return (
                    <>
                        <CNNavItem path={path + "/upcoming"} title={t("upcoming")} tooltip={t("upcoming_sessions")} icon="fa-calendar" hideNav={onExiting} />

                        <CNNavItem
                            path={path + "/best-practices/introduction"}
                            title={t("best-practices")}
                            tooltip={t("best-practices")}
                            icon={(
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width={12} height={16} fill="rgba(255, 255, 255, 0.95)">
                                    <path d="M288 358.3c13.98-8.088 17.53-30.04 28.88-41.39c11.35-11.35 33.3-14.88 41.39-28.87c7.98-13.79 .1658-34.54 4.373-50.29C366.7 222.5 383.1 208.5 383.1 192c0-16.5-17.27-30.52-21.34-45.73c-4.207-15.75 3.612-36.5-4.365-50.29c-8.086-13.98-30.03-17.52-41.38-28.87c-11.35-11.35-14.89-33.3-28.87-41.39c-13.79-7.979-34.54-.1637-50.29-4.375C222.5 17.27 208.5 0 192 0C175.5 0 161.5 17.27 146.3 21.34C130.5 25.54 109.8 17.73 95.98 25.7C82 33.79 78.46 55.74 67.11 67.08C55.77 78.43 33.81 81.97 25.72 95.95C17.74 109.7 25.56 130.5 21.35 146.2C17.27 161.5 .0008 175.5 .0008 192c0 16.5 17.27 30.52 21.34 45.73c4.207 15.75-3.615 36.5 4.361 50.29C33.8 302 55.74 305.5 67.08 316.9c11.35 11.35 14.89 33.3 28.88 41.4c13.79 7.979 34.53 .1582 50.28 4.369C161.5 366.7 175.5 384 192 384c16.5 0 30.52-17.27 45.74-21.34C253.5 358.5 274.2 366.3 288 358.3zM112 192c0-44.27 35.81-80 80-80s80 35.73 80 80c0 44.17-35.81 80-80 80S112 236.2 112 192zM1.719 433.2c-3.25 8.188-1.781 17.48 3.875 24.25c5.656 6.75 14.53 9.898 23.12 8.148l45.19-9.035l21.43 42.27C99.46 507 107.6 512 116.7 512c.3438 0 .6641-.0117 1.008-.0273c9.5-.375 17.65-6.082 21.24-14.88l33.58-82.08c-53.71-4.639-102-28.12-138.2-63.95L1.719 433.2zM349.6 351.1c-36.15 35.83-84.45 59.31-138.2 63.95l33.58 82.08c3.594 8.797 11.74 14.5 21.24 14.88C266.6 511.1 266.1 512 267.3 512c9.094 0 17.23-4.973 21.35-13.14l21.43-42.28l45.19 9.035c8.594 1.75 17.47-1.398 23.12-8.148c5.656-6.766 7.125-16.06 3.875-24.25L349.6 351.1z" />
                                </svg>
                            )}
                            hideNav={onExiting}
                        />

                        <CNNavItem path={path + "/myinfo"} title={t("my-info")} tooltip={t("view-edit-contact-information")} icon="fa-cog" hideNav={onExiting} />
                    </>
                );
            default:
                return null;
        }
    }

    return (
        <header className="header-global">
            <Navbar
                className="navbar-main navbar-transparent navbar-light headroom"
                expand="lg"
                id="navbar-main"
            >
                <Container style={{ padding: 0 }}>
                    <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                        <img
                            alt="..."
                            src={require("../../assets/img/logo.png")}
                        />
                    </NavbarBrand>
                    <button className="navbar-toggler" id="navbar_global">
                        <span className="navbar-toggler-icon" />
                    </button>

                    <UncontrolledCollapse
                        toggler="#navbar_global"
                        navbar
                        className={collapseClasses}
                        onExiting={onExiting}
                        onExited={onExited}
                    >
                        <div className="navbar-collapse-header">
                            <Row>
                                <Col className="collapse-brand" xs="10">
                                    <Link to="/">
                                        <img
                                            alt="..."
                                            style={{ width: '100%', height: 'auto' }}
                                            src={require("../../assets/img/logo1.png")}
                                        />
                                    </Link>
                                </Col>
                                <Col className="collapse-close" xs="2">
                                    <button className="navbar-toggler" id="navbar_global">
                                        <span />
                                        <span />
                                    </button>
                                </Col>
                            </Row>
                        </div>
                        <Nav className="align-items-lg-center ml-lg-auto" navbar>
                            {renderNavContent()}
                        </Nav>
                        <Nav className="align-items-lg-center ml-lg-auto" navbar>
                            {
                                userType === 'Teacher' && (
                                    <NavItem>
                                        <NavLink
                                            className="nav-link-icon"
                                            to={path + "/mysettings"}
                                            id="tooltip112445463"
                                            tag={Link}
                                        >
                                            <i className="fa fa-gear" style={{ fontSize: 16 }} />
                                        </NavLink>
                                        <UncontrolledTooltip delay={0} target="tooltip112445463">
                                            {t("my-settings")}
                                        </UncontrolledTooltip>
                                    </NavItem>
                                )
                            }
                            <NavItem>
                                <NavLink
                                    className="nav-link-icon"
                                    href="#"
                                    id="tooltip112445455"
                                    onClick={e => onSignout()}
                                >
                                    <i className="fa fa-sign-out" style={{ fontSize: 16 }} />
                                </NavLink>
                                <UncontrolledTooltip delay={0} target="tooltip112445455">
                                    {t("sign-out")}
                                </UncontrolledTooltip>
                            </NavItem>
                        </Nav>
                    </UncontrolledCollapse>
                </Container>
            </Navbar>
        </header >
    )
}

export default DemoNavbar;
