import base from './config';
const tableName = "Providers"

export const getProviderByEmail = (email) => {
    const em = email.toUpperCase();
    return new Promise((resolve, reject) => {
        base(tableName).select({
            filterByFormula: `OR(FIND("${em}", UPPER({Email})), FIND("${em}", UPPER({Secondary Email})), FIND("${em}", UPPER({Leadership Email})))`,
            maxRecords: 1
        }).firstPage().then(res => {
            if (res.length > 0) {
                resolve({
                    id: res[0].id,
                    ...res[0].fields
                })
            } else {
                resolve(null)
            }
        }).catch(error => {
            reject(error.toString());
        })
    });
}

export const getProviderById = (id) => {
    return new Promise((resolve, reject) => {
        base(tableName).find(id).then(record => {
            if (record && record._rawJson){
                resolve({
                    ...record._rawJson.fields,
                    id: record._rawJson.id
                })
            } else {
                resolve(null)
            }
        }).catch(error => {
            reject(error.toString())
        })
    })
}

export const getAllProviders = () => {
    return new Promise((resolve, reject) => {
        var providers = [];
        base(tableName).select({
            filterByFormula: `AND({Hide from Directory} = FALSE(), {Status} = "Active")`,
            sort: [
                { field: 'Name', direction: 'asc' }
            ]
        }).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                var provider = {
                    id: record.id,
                    ...record.fields
                }
                providers.push(provider);
            })
            fetchNextPage()
        }).then(res => {
            resolve(providers)
        }).catch(error => {
            resolve([])
        })
    })
}

export const updateProvider = (id, param) => {
    return new Promise((resolve, reject) => {
        base(tableName).update([{
            id, fields: param
        }]).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export const getProviderByDomain = (domain) => {
    const dm = domain.toUpperCase();
    return new Promise((resolve, reject) => {
        base(tableName).select({
            filterByFormula: `'${dm}' = UPPER({Domain})`,
            maxRecords: 1
        }).firstPage().then(res => {
            if (res.length > 0) {
                resolve({
                    id: res[0].id,
                    ...res[0].fields
                })
            } else {
                resolve(null)
            }
        }).catch(error => {
            reject(error.toString());
        })
    });
}
