const apiKey = 'patmUMb77Ctz9Cg87.67d074a8baceb5ee0134afd04ba0d828a37b7914b58f3ba67f88612f50419c56';
const base = 'appP1kThwW9zVEpHr';

const Airtable = require('airtable');
export default new Airtable({ apiKey: apiKey }).base(base);

export const isContainGrades = (record, grades) => {
    if (!grades || grades.length === 0) return true;
    const recordGrades = record['Grade(s)'];
    if (!recordGrades) return false;

    for (var i = 0; i < grades.length; i++) {
        if (recordGrades.indexOf(grades[i].name) < 0) return false;
    }
    return true;
}