import React, { useEffect, useState } from 'react';
import { getCurriculumLinks } from '../../../airtables/CurriculumLinks';
import { distinctGradesFromCLinks, distinctRegionsFromCLinks, getCoreCompetenciesString, getLinkedSessionString, getStrandTopicLabel, isEqualGrade, isEqualRegion } from './manage';
import { Form, FormGroup, Input, Label, Col } from 'reactstrap';
import { LinearProgress } from '@mui/material';
import Gap from '../../../components/Gap';
import './styles.scss';

const TabContent = ({ subjects, userType, userInfo, regionMap, openLink }) => {
    const [loading, setLoading] = useState(false);
    const [grade, setGrade] = useState("");
    const [region, setRegion] = useState(null);
    const [filterGrades, setFilterGrades] = useState([]);
    const [filterRegions, setFilterRegions] = useState([]);
    const [links, setLinks] = useState([]);
    const [displayLinks, setDisplayLinks] = useState([]);

    useEffect(() => {
        if (links && links.length) {
            const grades = distinctGradesFromCLinks(links);
            setFilterGrades(grades);
        } else {
            setFilterGrades([]);
        }
    }, [links])

    useEffect(() => {
        if (!grade) {
            setFilterRegions([]);
            return;
        }

        const ll = [];
        links.forEach(link => {
            if (link["Grade(s)"][0] === grade) {
                ll.push(link);
            }
        })
        if (ll && ll.length) {
            const regs = distinctRegionsFromCLinks(ll, regionMap);
            regs.sort((a, b) => a["Long Name"].localeCompare(b["Long Name"]))
            setFilterRegions(regs);
        } else {
            setFilterRegions([]);
        }
    }, [grade, links, regionMap])

    useEffect(() => {
        if (links && links.length) {
            let res = []
            links.forEach(link => {
                var shouldAdd = true;
                if (grade && link["Grade(s)"][0] !== grade) {
                    shouldAdd = false;
                } else if (region && link.Region && link.Region.indexOf(region) < 0) {
                    shouldAdd = false
                }

                if (shouldAdd) res.push(link);
            })

            if (grade) {
                res.sort((a, b) => a["Region Text"].localeCompare(b["Region Text"]));
            }
            setDisplayLinks(res);
        } else {
            setDisplayLinks([])
        }
    }, [grade, region, links])

    const updateLinks = (s) => {
        setGrade("");
        setRegion(null);
        getLinks(s);
    }

    const getLinks = (sub) => {
        if (sub) {
            if (userType === 'Teacher') {
                const regionId = (userInfo["School PT"] && userInfo["School PT"].length) ? userInfo["School PT"][0] : "";
                if (regionId) {
                    setLoading(true);
                    getCurriculumLinks(
                        null,
                        (regionMap[regionId].Name === 'ON' || regionMap[regionId].Name === 'NU') ? regionMap[regionId].Name : null,
                        sub,
                        [
                            { field: 'Grade(s)', direction: 'asc' },
                            { field: "ID", direction: 'asc' }
                        ]
                    ).then(res => {
                        setLoading(false);
                        setLinks(res);
                    }).catch(error => {
                        setLoading(false);
                    })
                } else {
                    alert("You don't have region Info now.");
                }
            } else if (userType === 'Team') {
                setLoading(true);
                getCurriculumLinks(
                    null,
                    null,
                    sub,
                    [
                        { field: 'Grade(s)', direction: 'asc' },
                        { field: "ID", direction: 'asc' }
                    ]
                ).then(res => {
                    setLoading(false);
                    setLinks(res);
                }).catch(error => {
                    setLoading(false);
                })
            }
        } else {
            setLinks([]);
        }
    }

    let regionShow = true;
    if (userType === 'Teacher') {
        const regionId = (userInfo["School PT"] && userInfo["School PT"].length) ? userInfo["School PT"][0] : "";
        if ((regionMap[regionId].Name === 'ON' || regionMap[regionId].Name === 'NU')) regionShow = false;
    }

    const renderStrandTopicInfo = (link) => {
        var stlbl = getStrandTopicLabel(regionMap, link["Region"] ? link["Region"][0] : "")
        return (
            <div>
                <span className="link-title"><b>{stlbl.strand}</b>: {link["Strand"]}</span><br />
                {
                    link["Topic"] ? (
                        <span className="link-title"><b>{stlbl.topic}</b>: {link["Topic"]}</span>
                    ) : null
                }
                {
                    (link["Core Competencies"] && link["Core Competencies"].length) && (
                        <>
                            <br />
                            <span className="link-title"><b>Core Competency</b>: {getCoreCompetenciesString(link["Core Competencies"])}</span>
                        </>
                    )
                }
            </div>
        )
    }

    return (
        <div style={{ minHeight: 500 }}>
            <Gap height={20} />
            <Form>
                <FormGroup row>
                    <Label
                        for="subject"
                        sm={2}
                        className="selector-label"
                    >Subject:</Label>
                    <Col sm={6}>
                        <Input
                            type="select"
                            id="subject"
                            onChange={e => updateLinks(e.target.value)}
                        >
                            <option value=''></option>
                            {
                                subjects.filter(sub => sub["# Curriculum Links"] > 0).map((sub, i) => (
                                    <option value={sub.Subject} key={i}>{sub.Subject}</option>
                                ))
                            }
                        </Input>
                    </Col>
                </FormGroup>

                {
                    filterGrades.length ? (
                        <FormGroup row>
                            <Label
                                for="grade"
                                className="selector-label"
                                sm={2}
                            >Grade Level:</Label>
                            <Col sm={6}>
                                <Input
                                    id="grade"
                                    type="select"
                                    onChange={e => {
                                        setGrade(e.target.value);
                                        setRegion("");
                                    }}
                                    defaultValue={grade}
                                >
                                    <option value=''></option>
                                    {
                                        filterGrades.map((type, i) => (
                                            <option value={type} key={i}>{type}</option>
                                        ))
                                    }
                                </Input>
                            </Col>
                        </FormGroup>
                    ) : null
                }

                {
                    regionShow && filterRegions.length ? (
                        <FormGroup row>
                            <Label
                                for="region"
                                sm={2}
                                className="selector-label"
                            >Region:</Label>
                            <Col sm={6}>
                                <Input
                                    id="region"
                                    type="select"
                                    onChange={(e) => {
                                        setRegion(e.target.value)
                                    }}
                                    defaultValue={region}
                                >
                                    <option value=''></option>
                                    {
                                        filterRegions.map((reg, i) => (
                                            <option key={i} value={reg.id}>{reg["Long Name"]}</option>
                                        ))
                                    }
                                </Input>
                            </Col>
                        </FormGroup>
                    ) : null
                }

                <Gap height={20} />

                <div className="link-container">
                    {loading && <LinearProgress color="secondary" />}
                    {
                        displayLinks.map((link, i) => (
                            <div key={i}>
                                {
                                    !grade ? ((i === 0 || !isEqualGrade(displayLinks[i - 1], link)) ? (
                                        <div className="groupName">
                                            <span>{link["Grade(s)"][0] !== 'K' ? ("Grade " + link["Grade(s)"][0]) : "Kindergarten"}</span>
                                        </div>
                                    ) : null) : ((i === 0 || !isEqualRegion(displayLinks[i - 1], link)) ? (
                                        <div className="groupName">
                                            <span>{link["Region Text"]}</span>
                                        </div>
                                    ) : null)
                                }
                                <div className="link" onClick={e => openLink(link)}>
                                    {renderStrandTopicInfo(link)}
                                    <div style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                                        <span>{getLinkedSessionString(link["Linked Sessions"])}</span>
                                        <Gap width={10} />
                                        {
                                            userType === 'Team' ? (
                                                <>
                                                    <span>Bookings: {link["Bookings"]}</span>
                                                    <Gap width={10} />
                                                </>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </Form>
            <Gap width={30} />
        </div>
    )
}

export default TabContent;