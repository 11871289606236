import base from './config';
const tableName = "Fireside Chats";

export const getFiresideChatById = (id) => {
    return new Promise((resolve, reject) => {
        base(tableName).find(id).then(record => {
            if (record && record._rawJson){
                resolve({
                    ...record._rawJson.fields,
                    id: record._rawJson.id
                })
            } else {
                resolve(null)
            }
        }).catch(error => {
            reject(error.toString())
        })
    })
}

export const getFiresideChatByProvider = (providerName) => {
    return new Promise((resolve, reject) => {
        var psResult = []
        base(tableName).select({
            filterByFormula: `AND(FIND("${providerName}", {Connected North Provider}), NOT({Web URL} = BLANK()), NOT({Illustration File} = BLANK()))`
        }).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                const ps = {
                    id: record.id,
                    ...record.fields
                }

                psResult.push(ps);
            })
            fetchNextPage()
        }).then(res => {
            resolve(psResult)
        }).catch(error => {
            console.log(error)
            resolve([])
        })
    });
}