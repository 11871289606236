/*eslint-disable*/
import React, { useState } from "react";
import { Modal, Container, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

const CardsFooter = () => {
    const [acknowledgementsModal, setAcknowledgementsModal] = useState(false);
    const { userType } = useSelector(state => state.appInfo);
    const { t } = useTranslation();

    return (
        <>
            <footer className="footer">
                <Container>
                    <Row className="align-items-center justify-content-md-between">
                        <Col md="1" />
                        <Col md="10" align="center">
                            <div className="copyright">
                                © {new Date().getFullYear()}{" "}
                                <a href="https://www.tigweb.org/about/" target="_blank">TakingITGlobal</a>. {t(`thanks-for-being-connectnorth.${userType}`)}&nbsp;
                                <span
                                    className="acknowledgements"
                                    onClick={() => setAcknowledgementsModal(true)}
                                >{t("acknowledgements")}</span>
                            </div>
                        </Col>
                        <Col md="1" />
                    </Row>
                </Container>
            </footer>
            <Modal
                className="modal-dialog-centered"
                isOpen={acknowledgementsModal}
                toggle={() => setAcknowledgementsModal(!acknowledgementsModal)}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="modal-title-default">{t("acknowledgements")}</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setAcknowledgementsModal(!acknowledgementsModal)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p>{t("acknowledgements-desc1")}</p>
                    <p>{t("acknowledgements-desc2")}</p>
                    <p>
                        <Trans i18nKey="acknowledgements-desc3">Thank you to Scotiabank and the <a target="_blank" href="https://www.scotiabank.com/ca/en/about/responsibility-impact/scotiarise.html">ScotiaRISE</a> program for being our Digital Platform Supporter.</Trans>
                    </p>
                </div>
            </Modal>
        </>
    );
}

export default CardsFooter;
