import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavLink, UncontrolledTooltip } from 'reactstrap';

const CNNavItem = ({
    title,
    tooltip,
    path,
    icon,
    hideNav
}) => {
    const tooltipId = useRef(`tooltip${Math.floor(Math.random() * 100)}`).current;
    return (
        <NavItem>
            <NavLink
                className="nav-link-icon"
                to={path}
                id={tooltipId}
                tag={Link}
                onClick={hideNav}
            >
                {
                    typeof icon === 'string' ? (
                        <i className={`fa ${icon}`} />
                    ) : icon
                }
                <span className="nav-link-inner--text ml-2">{title}</span>
            </NavLink>
            <UncontrolledTooltip delay={0} target={tooltipId}>{tooltip}</UncontrolledTooltip>
        </NavItem>
    )
}

export default CNNavItem;