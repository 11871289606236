import base from './config';
const tableName = "Schools"

export const getAllSchools = () => {
    return new Promise((resolve, reject) => {
        var result = []
        base(tableName).select({}).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                const school = {
                    id: record.id,
                    ...record.fields
                }

                result.push(school);
            })
            fetchNextPage()
        }).then(res => {
            resolve(result)
        }).catch(error => {
            resolve([])
        })
    })
}

export const selectSchoolById = (challengeId) => {
    return new Promise((resolve, reject) => {
        base(tableName).find(challengeId).then(res => {
            if (res.fields) {
                resolve({
                    id: res.id,
                    ...res.fields
                })
            } else {
                resolve(null)
            }
        }).catch(error => {
            reject(error.toString())
        })
    })
}

export const getSchoolsFromString = (str) => {
    if (!str)   return []
    const divider = ',';
    const result = str.split(divider)
    if (!result || result.length === 0) return [];
    for(var i = result.length - 1; i >= 0; i--){
        if (result[i].trim() === '') {
            result.splice(i, 1);
        }
        result[i] = result[i].trim();
    }
    return result;
}