import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import Gap from '../components/Gap';
import { LinearProgress } from '@mui/material';
import classnames from 'classnames';
import { getAllUpcomingInSameSchool } from '../airtables/Sessions';
import SessionInfo from '../components/SessionInfo';

const Screen = () => {
    const { userType, userInfo } = useSelector(state => state.appInfo);
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(userInfo["School Name Text"][0])
    const [schools, setSchools] = useState(userInfo["School Name Text"].map(sc => ({
        loading: true,
        name: sc,
        sessions: []
    })));

    useEffect(() => {
        if (userType !== "Teacher") return null;

        (async () => {
            const scs = userInfo["School Name Text"];
            let newSchools = [...schools];
            if (userInfo["School Name Text"] && userInfo["School Name Text"].length) {
                for (var i = 0; i < scs.length; i++) {
                    const sesses = await getAllUpcomingInSameSchool(userInfo['Teacher Name'], scs[i]);
                    newSchools[i] = {
                        ...newSchools[i],
                        loading: false,
                        sessions: sesses
                    }
                    setSchools(newSchools);
                }
            }
        })();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (userType !== "Teacher") return null;

    const renderSchoolSessions = (sessions) => {
        if (!sessions || sessions.length === 0) {
            return (
                <div className="nolist-placeholder" align="center">
                    <span>{t("no-upcoming-sessions-at-school")}</span>
                </div>
            );
        }

        return sessions.map((session, i) => (
            <SessionInfo
                displayInfos={["School Name", "Teacher Name"]}
                key={i}
                session={session}
            />
        ))
    }

    return (
        <div className="main-container">
            <Row>
                <Col>
                    <span className="page-topic">Upcoming at our school</span>
                </Col>
                <Col align="right">
                    <Link to="/cn/upcoming">
                        <span className="page-topic title-link">{t('upcoming_sessions')} <i className='fa fa-arrow-right' /></span>
                    </Link>
                </Col>
            </Row>
            <div style={{ marginTop: 10 }}>
                <div style={{ minHeight: 500 }}>
                    <Nav tabs>
                        {
                            schools.map((school, i) => (
                                <NavItem key={i}>
                                    <NavLink
                                        className={classnames({ active: activeTab === school.name })}
                                        onClick={() => setActiveTab(school.name)}
                                    >{school.name}</NavLink>
                                </NavItem>
                            ))
                        }
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        {
                            schools.map((school, i) => (
                                <TabPane tabId={school.name} key={i} >
                                    <Row>
                                        <Col sm="12">
                                            <Gap height={5} />
                                            {school.loading && <LinearProgress />}
                                            {renderSchoolSessions(school.sessions)}
                                        </Col>
                                    </Row>
                                </TabPane>
                            ))
                        }
                    </TabContent>
                </div>
            </div>
        </div >
    )
}

export default Screen;