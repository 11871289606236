import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

const PhotoSession = ({ session, onPress }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    return (
        <div className="photo-session-item">
            <img
                src={session["Image/Photo"][0].url}
                onClick={onPress}
                alt=""
                id={session.id}
            />
            <Tooltip
                placement="auto"
                isOpen={tooltipOpen}
                target={session.id}
                toggle={() => setTooltipOpen(!tooltipOpen)}
            >{session['Session Title']}</Tooltip>
        </div>
    )
}

export default PhotoSession;