import React, { useEffect, useState } from 'react';
import {
    Button,
    Modal,
    FormGroup,
    InputGroupText,
    InputGroup,
    Input
} from 'reactstrap'
import ReactDatetime from 'react-datetime';
import { selectSchoolById } from '../../airtables/Schools';
import { addSession, updateSession } from '../../airtables/Sessions';
import Loading from '../../components/Loading.js';
import { toast } from 'react-toastify';
import { logEvent } from 'firebase/analytics';
import { GradeLevels } from '../../utils/constants';
import { getProviderById } from '../../airtables/Providers';
import Axios from 'axios';
import { getDateAndTimeString, isSameDay } from '../../utils/time';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { analytics } from '../../firebase';

const getDefaultPreferredTime = (dayRestrictionConf, bookingRequirementDays) => {
    let offset = 14;
    if (dayRestrictionConf) offset = dayRestrictionConf;
    else if (bookingRequirementDays && bookingRequirementDays.length) offset = bookingRequirementDays[0]

    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() + offset, 10, 0, 0);
}

const getDefaultAlternativeTime = (dayRestrictionConf, bookingRequirementDays) => {
    let offset = 28;
    if (dayRestrictionConf) offset = dayRestrictionConf + 14;
    else if (bookingRequirementDays && bookingRequirementDays.length) offset = bookingRequirementDays[0] + 14;

    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() + offset, 10, 0, 0);
}

const RequestForm = ({
    session,
    provider,
    sessionLen,
    onToggle,
    onSubmitted,
    edit,
    requestedSession,
    dayRestrictionConfig
}) => {
    const { userType, userInfo } = useSelector(state => state.appInfo);

    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(getDefaultPreferredTime(dayRestrictionConfig, userInfo["Advance Booking Requirement (Days)"]));
    const [alterDate, setAlterDate] = useState(getDefaultAlternativeTime(dayRestrictionConfig, userInfo["Advance Booking Requirement (Days)"]));
    const [notes, setNotes] = useState("");
    const [school, setSchool] = useState(null);
    const [grades, setGrades] = useState(
        GradeLevels.map(item => ({
            name: item,
            checked: false
        }))
    );
    const [students, setStudents] = useState();
    const [isCalendarToken, existCalendarToken] = useState(false);
    const [preferredTimeValid, setPreferredTimeValid] = useState([]);
    const [alternativeTimeValid, setAlternativeTimeValid] = useState([]);
    const [calcing, setCalcing] = useState(false);
    const [dayRestriction, setDayRestriction] = useState(dayRestrictionConfig ? dayRestrictionConfig : 14);
    const [preferredTimeChanged, setPreferredTimeChanged] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (edit && requestedSession) {
            setStartDate(new Date(requestedSession["Session Start Date/Time"]));
            setAlterDate(new Date(requestedSession["Alternative Date/Time"]));
            setNotes(requestedSession["Notes"]);
            setStudents(requestedSession["Students"]);

            var gs = [];
            GradeLevels.forEach((grade, i) => {
                gs.push({
                    name: grade,
                    checked: (requestedSession["Grade(s)"] ? requestedSession["Grade(s)"].indexOf(grade) >= 0 : false)
                })
            })
            setGrades(gs);
        }
    }, [edit, requestedSession])

    useEffect(() => {
        (async () => {
            const providerInfo = await getProviderById(provider[0]);
            existCalendarToken(providerInfo['Calendar Token'] ? true : false);
        })();
    }, [provider])

    useEffect(() => {
        const init = async () => {
            if (userType === 'Teacher') {
                if (userInfo["Advance Booking Requirement (Days)"] && !dayRestrictionConfig) {
                    setDayRestriction(userInfo["Advance Booking Requirement (Days)"][0])
                }

                const school = await selectSchoolById(userInfo['School Name'][0]);
                setSchool(school);
            }
        }
        init();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const checkValidationOfSelectedTime = (selectedTime, isPreferred, valids) => {
        setCalcing(true)
        if (isCalendarToken) {
            const providerId = provider[0];
            const dateInfo = getDateAndTimeString(selectedTime);
            Axios.get(`https://research.tigweb.org/connectednorth/provider-availability/events.html?provider=${providerId}&date=${dateInfo.day}&time=${dateInfo.time}`).then(res => {
                if (res.data === "No events found.") {
                    valids.push("valid")
                } else {
                    valids.push("invalid")
                }
                if (isPreferred) setPreferredTimeValid(valids);
                else setAlternativeTimeValid(valids);
                setCalcing(false)
            }).catch(error => {
                setCalcing(false)
            })
        } else {
            setCalcing(false)
        }
    }

    const onDone = () => {
        if (loading || calcing || userType !== 'Teacher') {
            return;
        }

        const calcDiffDays = (date1, date2) => {
            if (typeof date1 === 'string') date1 = new Date(date1);
            if (typeof date2 === 'string') date2 = new Date(date2);

            var diff = date1.getTime() - date2.getTime();

            var diffDays = diff / (1000 * 3600 * 24);
            return diffDays
        }

        if (!preferredTimeChanged) {
            alert(t("sessionrequest.confirm-msg"));
            return;
        }

        if (preferredTimeValid.indexOf('invalid') >= 0) {
            alert(t("sessionrequest.p-time-invalid"));
            return;
        }

        if (!students || students <= 0) {
            alert(t("sessionrequest.students-num-msg"));
            return;
        }

        const gap1 = calcDiffDays(startDate, new Date());
        if (gap1 < dayRestriction) {
            alert(t("sessionrequest.day-restrict-msg", { num: dayRestriction }));
            return;
        }

        const gap2 = calcDiffDays(alterDate, new Date());
        if (gap2 < dayRestriction) {
            alert(t("sessionrequest.day-restrict-msg", { num: dayRestriction }));
            return;
        }

        let requestGrades = [];
        grades.forEach((grade) => {
            if (grade.checked) requestGrades.push(grade.name);
        })

        if (requestGrades.length === 0) {
            alert(t("sessionrequest.grades-msg"));
            return;
        }

        var param;
        if (edit) {
            param = {
                'Session Start Date/Time': startDate,
                'Alternative Date/Time': alterDate,
                'Notes': notes,
                'Grade(s)': requestGrades,
                'Students': parseInt(students)
            }

            setLoading(true);
            updateSession(requestedSession.id, param).then(async res => {
                logEvent(analytics, 'update_request_session', {
                    id: requestedSession.id
                })
                setLoading(false)
                toast.success(t("sessionrequest.request-updated-msg"));
                onSubmitted();
            }).catch(error => {
                setLoading(false);
                toast.error(error.toString())
            })
        } else {
            param = {
                'Session Start Date/Time': startDate,
                'Alternative Date/Time': alterDate,
                'Notes': notes,
                'Status': 'Requested',
                'Teacher': [userInfo.id],
                'Cost': session['Cost'],
                'Session Title': [session.id],
                'Subject/Curriculum': session['Subject'],
                'Well-being Link': session['Well-being Link'],
                'Provider': provider,
                'School(s)': userInfo['School Name'],
                'School Lead': school['TIG School Lead'],
                'Length (Minutes)': sessionLen,
                'Self-Requested through Platform': true,
                'Grade(s)': requestGrades,
                'Students': parseInt(students),
                'Send Meeting Invite to:': 'All'
            }

            setLoading(true);
            addSession(param).then(async res => {
                logEvent(analytics, 'request_session', { id: res[0]['_rawJson'].id });
                setLoading(false)
                toast.success(t("sessionrequest.request-received-msg"))
                onSubmitted();
            }).catch(error => {
                console.log("error", error)
                setLoading(false);
                toast.error(error.toString())
            })
        }
    }

    const showSameDayWarning = () => {
        if (isSameDay(startDate, alterDate)) {
            toast.warning(t("sessionrequest.second-date-msg"), 10000);
        }
    }

    const renderTimeValidation = (timeValid) => {
        const renderTimeValidationItem = (item) => {
            if (item === 'valid') {
                return (
                    <span style={{ color: 'green', fontSize: 12 }}>{t("sessionrequest.time-valid-msg")}</span>
                )
            }

            if (item === 'invalid') {
                return (
                    <span style={{ color: 'red', fontSize: 12 }}>{t("sessionrequest.time-invalid-msg")}</span>
                )
            }

            if (item === 'incorrect') {
                return (
                    <span style={{ color: '#cda535', fontSize: 12 }}>{t("sessionrequest.time-warning-msg")}</span>
                )
            }

            if (item === 'past-time') {
                return (
                    <span style={{ color: 'red', fontSize: 12 }}>{t("sessionrequest.past-time")}</span>
                )
            }

            return null;
        }
        return (
            <>
                {
                    timeValid.map((item, i) => <div key={i}>
                        {renderTimeValidationItem(item)}
                    </div>)
                }
            </>
        )
    }

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={true}
            toggle={onToggle}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="modal-title-default">{t("sessionrequest.session-request")}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={onToggle}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="">
                    <FormGroup>
                        <label>{t("sessionrequest.prefer-time")}</label>
                        <InputGroup className="input-group-alternative">
                            <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                            <ReactDatetime
                                value={startDate}
                                inputProps={{
                                    placeholder: t("sessionrequest.prefer-time")
                                }}
                                timeFormat={true}
                                onChange={(e) => {
                                    if (typeof e !== 'string') {
                                        const valids = [];
                                        const selectedDate = e.toDate();
                                        const selectedHour = selectedDate.getHours();
                                        if (selectedDate < new Date()) valids.push("past-time")
                                        if (selectedHour < 8 || selectedHour >= 17) { valids.push("incorrect"); }
                                        setPreferredTimeValid(valids)

                                        checkValidationOfSelectedTime(selectedDate, true, valids);
                                        setStartDate(selectedDate);
                                        showSameDayWarning();
                                        setPreferredTimeChanged(true);
                                    }
                                }}
                            />
                        </InputGroup>
                        {renderTimeValidation(preferredTimeValid)}
                    </FormGroup>

                    <FormGroup>
                        <label>{t("sessionrequest.alternative-time")}</label>
                        <InputGroup className="input-group-alternative">
                            <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                            <ReactDatetime
                                value={alterDate}
                                inputProps={{
                                    placeholder: t("sessionrequest.alternative-time")
                                }}
                                timeFormat={true}
                                onChange={(e) => {
                                    if (typeof e !== 'string') {
                                        const valids = [];
                                        const selectedDate = e.toDate();
                                        const selectedHour = selectedDate.getHours();
                                        if (selectedDate < new Date()) valids.push("past-time")
                                        if (selectedHour < 8 || selectedHour >= 17) { valids.push("incorrect"); }
                                        setAlternativeTimeValid(valids);

                                        checkValidationOfSelectedTime(selectedDate, false, valids);
                                        setAlterDate(selectedDate);
                                        showSameDayWarning();
                                    }
                                }}
                            />
                        </InputGroup>
                        {renderTimeValidation(alternativeTimeValid)}
                    </FormGroup>

                    <FormGroup>
                        <div className="multicheck-container">
                            <label>{t("grade-levels")}</label>
                            <div className="multicheck">
                                {
                                    grades.map((grade, i) => (
                                        <div
                                            className={grade.checked ? "multicheck-item item-checked" : "multicheck-item"}
                                            key={i}
                                            onClick={
                                                () => {
                                                    const gs = [...grades];
                                                    gs[i].checked = !gs[i].checked;
                                                    setGrades(gs);
                                                }
                                            }
                                        >
                                            <span>{grade.name}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <label>{t("sessionrequest.number-of-students")}</label>
                        <Input
                            defaultValue={students}
                            onChange={(e) => setStudents(e.target.value)}
                            type="number"
                        />
                    </FormGroup>

                    <FormGroup>
                        <label>{t("sessionrequest.notes")}</label>
                        <Input
                            defaultValue={notes}
                            placeholder={t("sessionrequest.notes-msg")}
                            rows="3"
                            onChange={(e) => setNotes(e.target.value)}
                            type="textarea"
                        />
                    </FormGroup>
                </div>
            </div>
            <div className="modal-footer">
                <Button
                    color="primary"
                    type="button"
                    onClick={() => onDone()}
                >{loading && (<Loading size={14} />)}{t("sessionrequest.submit")}</Button>
                <Button
                    className="ml-auto"
                    data-dismiss="modal"
                    color="link"
                    type="button"
                    onClick={onToggle}
                >{t("close")}</Button>
            </div>
        </Modal>
    )
}

export default RequestForm;
