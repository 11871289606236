import React, { useEffect, useState } from 'react';
import { getCurriculumLinks } from '../../../airtables/CurriculumLinks';
import {
    distinctRegionsFromCLinks,
    distinctSubjectsFromCLinks,
    getCoreCompetenciesString,
    getLinkedSessionString,
    getStrandTopicLabel,
    isEqualRegion,
    isEqualSubject,
    isExistSubject
} from './manage';
import { Form, FormGroup, Input, Label, Col } from 'reactstrap';
import { GradeLevels } from '../../../utils/constants';
import { LinearProgress } from '@mui/material';
import Gap from '../../../components/Gap';
import './styles.scss';

const TabContent = ({ userType, userInfo, subjectMap, regionMap, openLink }) => {
    const [loading, setLoading] = useState(false);
    const [links, setLinks] = useState([]);
    const [filterSubjects, setFilterSubjects] = useState([]);
    const [filterRegions, setFilterRegions] = useState([]);
    const [subject, setSubject] = useState(null);
    const [region, setRegion] = useState(null);
    const [displayLinks, setDisplayLinks] = useState([]);

    useEffect(() => {
        if (links && links.length) {
            const subs = distinctSubjectsFromCLinks(links, subjectMap);
            subs.sort((a, b) => a["Subject"].localeCompare(b["Subject"]))
            setFilterSubjects([...subs]);
        } else {
            setFilterSubjects([]);
        }
    }, [links, subjectMap])

    useEffect(() => {
        if (!subject) {
            setFilterRegions([]);
            return;
        }

        const ll = [];
        links.forEach(link => {
            if (isExistSubject(subject, link["Subject"])) {
                ll.push(link);
            }
        })
        if (ll && ll.length) {
            const regs = distinctRegionsFromCLinks(ll, regionMap);
            setFilterRegions(regs);
        } else {
            setFilterRegions([]);
        }
    }, [subject, links, regionMap])

    useEffect(() => {
        if (links && links.length) {
            const res = []
            links.forEach(link => {
                var shouldAdd = true;
                if (subject) {
                    if (!isExistSubject(subject, link["Subject"])) {
                        shouldAdd = false;
                    }
                }

                if (region && link.Region && link.Region.indexOf(region) < 0) {
                    shouldAdd = false
                }

                if (shouldAdd) res.push(link);
            })

            if (subject) {
                res.sort((a, b) => a["Region Text"].localeCompare(b["Region Text"]));
            }
            setDisplayLinks(res);
        } else {
            setDisplayLinks([])
        }
    }, [subject, region, links])

    const updateLinks = (g) => {
        setSubject(null);
        setRegion(null);
        getLinks(g);
    }

    const getLinks = (gra) => {
        if (gra) {
            if (userType === 'Teacher') {
                const regionId = (userInfo["School PT"] && userInfo["School PT"].length) ? userInfo["School PT"][0] : "";
                if (regionId) {
                    setLoading(true);
                    getCurriculumLinks(
                        gra,
                        (regionMap[regionId].Name === 'ON' || regionMap[regionId].Name === 'NU') ? regionMap[regionId].Name : null,
                        null,
                        [
                            { field: 'Subject', direction: 'asc' },
                            { field: "ID", direction: 'asc' }
                        ]
                    ).then(res => {
                        setLoading(false);
                        setLinks(res);
                    }).catch(error => {
                        setLoading(false);
                    })
                } else {
                    alert("You don't have region Info now.");
                }
            } else if (userType === 'Team') {
                setLoading(true);
                getCurriculumLinks(
                    gra,
                    null,
                    null,
                    [
                        { field: 'Subject', direction: 'asc' },
                        { field: "ID", direction: 'asc' }
                    ]
                ).then(res => {
                    setLoading(false);
                    setLinks(res);
                }).catch(error => {
                    setLoading(false);
                })
            }
        } else {
            setLinks([]);
        }
    }

    let regionShow = true;
    if (userType === 'Teacher') {
        const regionId = (userInfo["School PT"] && userInfo["School PT"].length) ? userInfo["School PT"][0] : "";
        if ((regionMap[regionId].Name === 'ON' || regionMap[regionId].Name === 'NU')) regionShow = false;
    }

    const renderStrandTopicInfo = (link) => {
        var stlbl = getStrandTopicLabel(regionMap, link["Region"] ? link["Region"][0] : "")
        return (
            <div>
                <span className="link-title"><b>{stlbl.strand}</b>: {link["Strand"]}</span><br />
                {
                    link["Topic"] ? (
                        <span className="link-title"><b>{stlbl.topic}</b>: {link["Topic"]}</span>
                    ) : null
                }
                {
                    (link["Core Competencies"] && link["Core Competencies"].length) && (
                        <>
                            <br />
                            <span className="link-title"><b>Core Competency</b>: {getCoreCompetenciesString(link["Core Competencies"])}</span>
                        </>
                    )
                }
            </div>
        )
    }

    return (
        <div style={{ minHeight: 500 }}>
            <Gap height={20} />
            <Form>
                <FormGroup row>
                    <Label
                        for="grade"
                        sm={2}
                        className="selector-label"
                    >Grade Level:</Label>
                    <Col sm={6}>
                        <Input
                            id="grade"
                            type="select"
                            onChange={(e) => updateLinks(e.target.value)}
                        >
                            <option value=''></option>
                            {
                                GradeLevels.map((type, i) => (
                                    <option key={i}>{type}</option>
                                ))
                            }
                        </Input>
                    </Col>
                </FormGroup>

                {
                    filterSubjects.length ? (
                        <FormGroup row>
                            <Label
                                for="subject"
                                className="selector-label"
                                sm={2}
                            >Subject:</Label>
                            <Col sm={6}>
                                <Input
                                    id="subject"
                                    type="select"
                                    onChange={e => {
                                        setSubject(e.target.value);
                                        setRegion(null);
                                    }}
                                    defaultChecked={subject}
                                >
                                    <option value=''></option>
                                    {
                                        filterSubjects.map((sub, i) => (
                                            <option value={sub.id} key={i}>{sub.Subject}</option>
                                        ))
                                    }
                                </Input>
                            </Col>
                        </FormGroup>
                    ) : null
                }

                {
                    regionShow && filterRegions.length ? (
                        <FormGroup row>
                            <Label
                                for="region"
                                sm={2}
                                className="selector-label"
                            >Region:</Label>
                            <Col sm={6}>
                                <Input
                                    type="select"
                                    id="region"
                                    onChange={e => {
                                        setRegion(e.target.value)
                                    }}
                                    defaultValue={region}
                                >
                                    <option value=''></option>
                                    {
                                        filterRegions.map((reg, i) => (
                                            <option value={reg.id} key={i}>{reg["Long Name"]}</option>
                                        ))
                                    }
                                </Input>
                            </Col>
                        </FormGroup>
                    ) : null
                }

                <Gap height={20} />

                <div className="link-container">
                    {loading && <LinearProgress color="secondary" />}
                    {
                        displayLinks.map((link, i) => (
                            <div key={i}>
                                {
                                    !subject ? ((i === 0 || !isEqualSubject(displayLinks[i - 1], link)) ? (
                                        <div className="groupName">
                                            <span>{link["Subject Text"]}</span>
                                        </div>
                                    ) : null) : (
                                        (i === 0 || !isEqualRegion(displayLinks[i - 1], link)) ? (
                                            <div className="groupName">
                                                <span>{link["Region Text"]}</span>
                                            </div>
                                        ) : null
                                    )
                                }

                                <div className="link" onClick={e => openLink(link)}>
                                    {renderStrandTopicInfo(link)}

                                    <div style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                                        <span>{getLinkedSessionString(link["Linked Sessions"])}</span>
                                        <Gap width={10} />
                                        {
                                            userType === 'Team' ? (
                                                <>
                                                    <span>Bookings: {link["Bookings"]}</span>
                                                    <Gap width={10} />
                                                </>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </Form>
        </div>
    )
}

export default TabContent;