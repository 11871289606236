import base from './config';
const tableName = "Subjects"

export const getAllSubjects = () => {
    return new Promise((resolve, reject) => {
        const subjects = [];
        base(tableName).select(
            {
                filterByFormula: "{Subject} != 'Test'",
                sort: [
                    { field: 'Subject', direction: 'asc' }
                ]
            }
        ).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                if (record.fields.Subject !== 'Therapy (SLP/OT)' && record.fields.Subject !== 'Premium Session') {
                    const subject = {
                        id: record.id,
                        ...record.fields
                    }
                    subjects.push(subject);
                }
            })
            fetchNextPage()
        }).then(res => {
            resolve(subjects)
        }).catch(error => {
            resolve([])
        })
    })
}