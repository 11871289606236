export const getSession = (sessionId) => {
    var sess = window.localStorage.getItem(sessionId);
    if (sess){
        return JSON.parse(sess)
    } else {
        return null;
    }
}

export const setSession = (sessionId, data) => {
    window.localStorage.setItem(sessionId, JSON.stringify(data));
    return true;
}

export const removeSession = (sessionId) => {
    window.localStorage.removeItem(sessionId);
    return true;
}

export const removeAllSession = async () => {
    window.localStorage.clear();
}