import { LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Col, Input, Row } from 'reactstrap';
import Gap from '../../components/Gap';
import { searchSessions } from '../../airtables/Sessions';
import SessionInfo from '../../components/SessionInfo';
import RateSessionModal from '../../pages/modals/Rate';

const Screen = () => {
    const { userType, userInfo } = useSelector(state => state.appInfo);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [keyword, setKeyword] = useState("");
    const [error, setError] = useState("");
    const [sessions, setSessions] = useState([]);
    const [searched, setSearched] = useState(false);

    const [activeSession, setActiveSession] = useState(null);
    const [showRatingModal, setShowRatingModal] = useState(false);

    useEffect(() => {
        onSearch("");
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const onSearch = async (word) => {
        setLoading(true);
        searchSessions(userInfo["Name"], word.trim(), ["Completed", "Cancelled with Payment"]).then(res => {

            setSessions(res);
            setSearched(true);
            setLoading(false);
        }).catch(err => {
            setError(err.toString());
            setSearched(true);
            setLoading(false);
        })
    }

    const postRating = () => {
        setActiveSession(null);
        setShowRatingModal(false)
        onSearch(keyword);
    }

    const renderSessions = () => {
        if (error) {
            return (
                <div className="nolist-placeholder" align="center" style={{ color: 'red' }}>
                    <span>{error}</span>
                </div>
            )
        }

        if (loading || !searched) return null;

        if (!sessions || sessions.length === 0) {
            return (
                <div className="nolist-placeholder" align="center">
                    <span>{t("no-past-sessions")}</span>
                </div>
            )
        }

        return (
            <div>{sessions.map((session, i) => (
                <SessionInfo
                    key={i}
                    session={session}
                    displayInfos={[
                        "School Name",
                        "School Lead",
                        "Teacher Name",
                        "Cancelled with Payment",
                        "Presenters"
                    ]}
                    actionMenu={session.Status === 'Cancelled with Payment' ? [] : ["provider-feedback"]}
                    openProviderFeedbackModal={(e, session) => {
                        setActiveSession(session);
                        setShowRatingModal(true);
                    }}
                />
            ))}</div>
        )
    }

    return (
        <div className="main-container">
            <Row>
                <Col>
                    <span className="page-topic">{t("view-past-sessions")}</span>
                </Col>
                {
                    userType === 'Provider' && (
                        <Col align="right">
                            <Link to="/cn/upcoming">
                                <span className="page-topic title-link">{t('upcoming_sessions')} <i className='fa fa-arrow-right' /></span>
                            </Link>
                        </Col>
                    )
                }
            </Row>
            <div style={{ marginTop: 10 }}>
                <Gap height={20} />
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <Input
                            type="text"
                            onChange={(e) => setKeyword(e.target.value)}
                            disabled={loading}
                            onKeyDown={(event) => {
                                if (event.keyCode === 13) onSearch(keyword);
                            }}
                        />
                    </div>
                    <Gap height={20} />
                    <Button
                        color="primary"
                        onClick={() => onSearch(keyword)}
                        disabled={loading}
                    ><i className="fa fa-search" /> Search</Button>
                </div>
                <Gap height={20} />
                {loading && <LinearProgress />}
                <div style={{ minHeight: 500 }}>{renderSessions()}</div>

                {(showRatingModal && activeSession) ? (
                    <RateSessionModal
                        session={activeSession}
                        onDone={() => postRating()}
                        onToggle={() => setShowRatingModal(!showRatingModal)}
                    />
                ) : null}
            </div>
        </div>
    )
}

export default Screen;