export const monthNames = {
    en: [
        "January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October",
        "November", "December"
    ],
    fr: [
        "janvier", "février", "mars",
        "avril", "mai", "juin",
        "juillet", "août", "septembre",
        "octobre", "novembre", "décembre"
    ]
}

export const getDateTimeString = (date) => {
    if (!date) return "";

    if (typeof date === 'string') {
        date = new Date(date);
    }
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();
    var hour = date.getHours();
    var minute = date.getMinutes();

    var result = '';
    if (month < 10) result += '0';
    result += month.toString();
    result += '/'

    if (day < 10) result += '0';
    result += day.toString();
    result += '/'

    result += year.toString();

    result += " ";
    if (hour < 10) result += '0';
    result += hour.toString();
    result += ':'

    if (minute < 10) result += '0';
    result += minute.toString();
    return result;
}

export const getNiceFormatDateString = (date, locale = "en") => {
    if (!date) return '';

    if (typeof date === 'string') {
        date = new Date(date);
    }

    const month = date.getMonth();
    const day = date.getDate();

    if (locale === 'fr') {
        return `${day} ${monthNames.fr[month]}`;
    }
    return `${monthNames.en[month]} ${day}`;
}

export const getWMDDateString = (date, locale = 'en') => {
    if (!date) return '';

    if (typeof date === 'string') {
        date = new Date(date);
    }

    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();

    return {
        heading: new Intl.DateTimeFormat(locale, { weekday: 'long', month: 'long', day: 'numeric' }).format(date),
        label: `${year}-${month}-${day}`,
        date: date.toISOString()
    }
}

export const getNiceFormatDateTimeString = (date, locale = "en") => {
    if (!date) return '';

    if (typeof date === 'string') {
        date = new Date(date);
    }

    const month = date.getMonth();
    const day = date.getDate();
    // const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const realhour = hour % 12;

    if (locale === 'fr') {
        return `${day} ${monthNames.fr[month]} à ${hour < 10 ? ('0' + hour) : hour}:${minute < 10 ? ('0' + minute) : minute}`;
    }
    return `${monthNames.en[month]} ${day} at ${realhour === 0 ? 12 : realhour}:${minute < 10 ? ('0' + minute) : minute} ${hour < 12 ? 'AM' : 'PM'}`;
}

export const getDateFormat_mmddyyyy = (date) => {
    if (!date) return '';

    if (typeof date === 'string') {
        date = new Date(date);
    }

    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();

    var result = '';
    if (month < 10) result += '0';
    result += month.toString();
    result += '/'

    if (day < 10) result += '0';
    result += day.toString();
    result += '/'

    result += year.toString();
    return result;
}

export const getDateAndTimeString = (date) => {
    if (!date) return '';

    if (typeof date === 'string') {
        date = new Date(date);
    }

    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();

    var hour = date.getHours();
    var minute = date.getMinutes();

    var dayStr = '';
    dayStr += year.toString();
    if (month < 10) dayStr += '0'; dayStr += month.toString();
    if (day < 10) dayStr += '0'; dayStr += day.toString();

    var timeStr = '';
    if (hour < 10) timeStr += '0'; timeStr += hour.toString();
    if (minute < 10) timeStr += '0'; timeStr += minute.toString();

    return { day: dayStr, time: timeStr };
}

export const isSameDay = (day1, day2) => {
    if (!day1 || !day2) return false;
    if (day1.getFullYear() !== day2.getFullYear()) return false;
    if (day1.getMonth() !== day2.getMonth()) return false;
    if (day1.getDate() !== day2.getDate()) return false;
    return true;
}

export const isTodayORTomorrowSession = (date) => {
    if (!date) return false;

    if (typeof date === 'string') {
        date = new Date(date);
    }

    const now = new Date();

    const year = now.getFullYear(), month = now.getMonth(), day = now.getDate();
    const tomorrow = new Date(year, month, day + 2, 0, 0, 0);

    if (date >= new Date() && date < tomorrow) return true;
    return false;
}

export const getFullYearMonth = (date) => {
    if (!date) return '';

    if (typeof date === 'string') {
        date = new Date(date);
    }

    const year = date.getFullYear(), month = date.getMonth();
    return `${year}-${month < 10 ? '0' : ''}${month}`;
}