import React, { useEffect, useState } from 'react';
import { Col, Row } from "reactstrap";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';
import SessionDetail from '../../pages/modals/Session';
import { selectProviderSessionById } from '../../airtables/ProviderSessions';
import Gap from '../../components/Gap';
import SessionInfo from '../../components/SessionInfo';

const Screen = () => {
    const { userType, presenterUpcoming, presenterPast, presenterLoading } = useSelector(state => state.appInfo);
    const [showPastSessions, setShowPastSessions] = useState(false);
    const [activeSession, setActiveSession] = useState(null);
    const [showSessionDetailModal, setShowSessionDetailModal] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setShowPastSessions((presenterPast && presenterPast.length) ? true : false);
    }, [presenterLoading, presenterUpcoming, presenterPast])

    const openSessionDetail = async (e, id) => {
        e.preventDefault();
        if (!id) return;

        setShowSessionDetailModal(true);

        const session = await selectProviderSessionById(id);

        setActiveSession(session);
    }

    return (
        <div className="main-container">
            <Row>
                <Col>
                    <span className="page-topic">{t('upcoming_sessions')}</span>
                </Col>
                {
                    showPastSessions && (
                        <Col align="right">
                            <Link to="upcoming/past">
                                <span className="page-topic title-link">{t("view-past-sessions")} <i className='fa fa-arrow-right' /></span>
                            </Link>
                        </Col>
                    )
                }
            </Row>
            <div style={{ marginTop: 10 }}>
                {presenterLoading ? <LinearProgress /> : null}
                <Gap height={20} />
                <div className="general-container">
                    {
                        (presenterUpcoming && presenterUpcoming.length > 0) ? presenterUpcoming.map((session, index) => (
                            <SessionInfo
                                displayInfos={session["Session Host(s)"] ? [
                                    "Session Host",
                                    "Teacher Name",
                                    "School Location"
                                ] : [
                                    "School Name",
                                    "School Lead",
                                    "Teacher Name",
                                    "School Location"
                                ]}
                                key={index}
                                session={session}
                                actionMenu={[
                                    "copy-link",
                                    "join-session",
                                    "call-teacher",
                                    "email-teacher",
                                    "call-provider",
                                    "email-provider",
                                    "dial-bridge-address",
                                    "reassign-session-lead"
                                ]}
                                openSessionDetail={openSessionDetail}
                            />
                        )) : (
                            presenterLoading ? null : <div className="nolist-placeholder" align="center">
                                <span>{t("no-upcoming-sessions")}</span>
                            </div>
                        )
                    }
                </div>
            </div>

            {
                (showSessionDetailModal && activeSession !== null) && (
                    <SessionDetail
                        session={activeSession}
                        userType={userType}
                        onToggle={() => setShowSessionDetailModal(!showSessionDetailModal)}
                    />
                )
            }
        </div>
    )
}

export default Screen;