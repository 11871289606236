import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PendingPage from './Pending';
import UpcomingPage from './Upcoming';
import PastPage from './Past';
import SameSchoolPage from './SameSchool';
import RatePage from './Rate';
import ContactsPage from './Contacts';
import SearchPage from './Search';
import MySessionsPage from './MySessions';
import MyFavoritesPage from './Favorites';
import BrowserCurriculumPage from './Browser/Curriculum/index.js';
import BrowserPhotoPage from './Browser/Photo/index';
import MyInfoPage from './MyInfo';
import BestPracticePage from './BestPractices';
import MystatsPage from './Mystats';
import MySettingsPage from './Mysettings';
import SurveyPage from './Survey';
import EventsPage from './Events';
import PDWeekPage from './PDWeek';

export const UpcomingStack = (props) => (
    <BrowserRouter>
        <Switch>
            <Route path={`/cn/upcoming`} exact render={props => <UpcomingPage {...props} />} />
            <Route path={`/cn/upcoming/past`} exact render={props => <PastPage {...props} />} />
            <Route path={`/cn/upcoming/same-school`} exact render={props => <SameSchoolPage {...props} />} />
        </Switch>
    </BrowserRouter>
)

export const RateStack = (props) => (
    <BrowserRouter>
        <Switch>
            <Route path={`${props.match.path}`} exact render={props => <RatePage {...props} />} />
        </Switch>
    </BrowserRouter>
)

export const PendingStack = (props) => (
    <BrowserRouter>
        <Switch>
            <Route path={`${props.match.path}`} exact render={props => <PendingPage {...props} />} />
        </Switch>
    </BrowserRouter>
)

export const ContactStack = (props) => (
    <BrowserRouter>
        <Switch>
            <Route path={`${props.match.path}`} exact render={props => <ContactsPage {...props} />} />
        </Switch>
    </BrowserRouter>
)

export const EventsStack = (props) => (
    <BrowserRouter>
        <Switch>
            <Route path={`${props.match.path}`} exact render={props => <EventsPage {...props} />} />
        </Switch>
    </BrowserRouter>
)

export const SearchStack = (props) => (
    <BrowserRouter>
        <Switch>
            <Route path={`${props.match.path}`} exact render={props => <SearchPage {...props} />} />
        </Switch>
    </BrowserRouter>
)

export const MySessionsStack = (props) => (
    <BrowserRouter>
        <Switch>
            <Route path={`${props.match.path}`} exact render={props => <MySessionsPage {...props} />} />
        </Switch>
    </BrowserRouter>
)

export const MySettingsStack = (props) => (
    <BrowserRouter>
        <Switch>
            <Route path={`${props.match.path}`} exact render={props => <MySettingsPage {...props} />} />
        </Switch>
    </BrowserRouter>
)

export const MyInfoStack = (props) => (
    <BrowserRouter>
        <Switch>
            <Route path={`${props.match.path}`} exact render={props => <MyInfoPage {...props} />} />
        </Switch>
    </BrowserRouter>
)

export const BrowserCurriculumStack = (props) => (
    <BrowserRouter>
        <Switch>
            <Route path={`${props.match.path}`} exact render={props => <BrowserCurriculumPage {...props} />} />
        </Switch>
    </BrowserRouter>
)

export const BrowserPhotoStack = (props) => (
    <BrowserRouter>
        <Switch>
            <Route path={`${props.match.path}`} exact render={props => <BrowserPhotoPage {...props} />} />
        </Switch>
    </BrowserRouter>
)

export const FavoritesStack = (props) => (
    <BrowserRouter>
        <Switch>
            <Route path={`${props.match.path}`} exact render={props => <MyFavoritesPage {...props} />} />
        </Switch>
    </BrowserRouter>
)

export const BestPracticeStack = (props) => (
    <BrowserRouter>
        <Switch>
            <Route path={`${props.match.path}`} render={props => <BestPracticePage {...props} />} />
        </Switch>
    </BrowserRouter>
)

export const MystatsStack = (props) => (
    <BrowserRouter>
        <Switch>
            <Route path={`${props.match.path}`} exact render={props => <MystatsPage {...props} />} />
        </Switch>
    </BrowserRouter>
)

export const SurveyStack = (props) => (
    <BrowserRouter>
        <Switch>
            <Route path={`${props.match.path}`} exact render={props => <SurveyPage {...props} />} />
        </Switch>
    </BrowserRouter>
)

export const PDWeekStack = (props) => (
    <BrowserRouter>
        <Switch>
            <Route path={`${props.match.path}`} exact render={props => <PDWeekPage {...props} />} />
        </Switch>
    </BrowserRouter>
)