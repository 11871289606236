import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { disableRatingRequest } from '../airtables/Teachers.js'

const Unsubscribe = (props) => {
    const [isDone, setIsDone] = useState(false);

    useEffect(() => {
        const { match: { params } } = props;

        const tId = params.teacherId;
        disableRatingRequest(tId, true).then(() => {
            setIsDone(true);
        })
    }, [props]);

    if (!isDone) return null;

    return (
        <Redirect to="/cn/rate" />
    )
}

export default Unsubscribe;