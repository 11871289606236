import base from './config';
const tableName = "Team"

export const getTeamByEmail = (email) => {
    const em = email.toUpperCase();
    return new Promise((resolve, reject) => {
        base(tableName).select({
            filterByFormula: `FIND("${em}", UPPER({Email}))`,
            maxRecords: 1
        }).firstPage().then(res => {
            if (res.length > 0) {
                resolve({
                    id: res[0].id,
                    ...res[0].fields
                })
            } else {
                resolve(null)
            }
        }).catch(error => {
            reject(error.toString());
        })
    });
}

export const getTeamById = (id) => {
    return new Promise((resolve, reject) => {
        base(tableName).find(id).then(record => {
            if (record && record._rawJson){
                resolve(record._rawJson.fields)
            } else {
                resolve(null)
            }
        }).catch(error => {
            reject(error.toString())
        })
    })
}

export const getAllTeams = (email) => {
    return new Promise((resolve, reject) => {
        var teams = [];
        base(tableName).select({
            sort: [
                { field: 'Name', direction: 'asc' }
            ],
            filterByFormula: `{Status} != 'Alumni'`
        }).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                var team = {
                    id: record.id,
                    ...record.fields
                }
                teams.push(team);
            })
            fetchNextPage()
        }).then(res => {
            resolve(teams)
        }).catch(error => {
            resolve([])
        })
    });
}

export const updateTeam = (id, param) => {
    return new Promise((resolve, reject) => {
        base(tableName).update([{
            id, fields: param
        }]).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}