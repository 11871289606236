import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from 'reactstrap';
import { CurrentGradeLevels } from '../../utils/constants';
import { updateTeacher } from '../../airtables/Teachers'
import { setAppUserInfo } from '../../redux/actions';
import Loading from '../../components/Loading';
import './styles.scss';
import { toast } from 'react-toastify';

const Screen = () => {
    const { userInfo, totalSubjects } = useSelector(state => state.appInfo)
    const { t } = useTranslation();
    const [taughtSubjects, setTaughtSubjects] = useState(userInfo["Subject(s) Taught"] || []);
    const [taughtGrades, setTaughtGrades] = useState(userInfo["Grade(s) Taught"] || []);
    const [sessionRequest, setSessionRequest] = useState(userInfo["2022 Session Menu Requested"]);
    const [pronouns, setPronouns] = useState(userInfo["Pronouns"]);
    const [changed, setChanged] = useState(false);
    const [saving, setSaving] = useState(false);
    const dispatch = useDispatch();

    const toggleTaughtSubjects = (subject) => {
        const pos = taughtSubjects.indexOf(subject.id);
        if (pos < 0) {
            const newSubs = [...taughtSubjects, subject.id];
            setTaughtSubjects(newSubs);
            setChanged(true);
        } else {
            const newSubs = [...taughtSubjects];
            newSubs.splice(pos, 1);
            setTaughtSubjects(newSubs);
            setChanged(true);
        }
    }

    const toggleTaughtGrades = (grade) => {
        const pos = taughtGrades.indexOf(grade);
        if (pos < 0) {
            const newGrades = [...taughtGrades, grade];
            setTaughtGrades(newGrades);
            setChanged(true);
        } else {
            const newGrades = [...taughtGrades];
            newGrades.splice(pos, 1);
            setTaughtGrades(newGrades);
            setChanged(true);
        }
    }

    const saveChanges = async () => {
        try {
            setSaving(true);
            await updateTeacher(userInfo.id, {
                "Subject(s) Taught": taughtSubjects,
                "Grade(s) Taught": taughtGrades,
                "2022 Session Menu Requested": sessionRequest,
                "Pronouns": pronouns
            });
            const newUserInfo = {
                ...userInfo,
                "Subject(s) Taught": taughtSubjects,
                "Grade(s) Taught": taughtGrades,
                "2022 Session Menu Requested": sessionRequest,
                "Pronouns": pronouns
            }
            dispatch(setAppUserInfo(newUserInfo));
            setChanged(false);
            setSaving(false);
            toast.success(t("my-settings-saving-success"));
        } catch (error) {
            setSaving(false);
            toast.error(error.toString())
        }
    }

    return (
        <div className="main-container">
            <span className="page-topic">{t("my-settings")}</span>

            {
                (userInfo['School Name Text'] && userInfo['School Name Text'][0] !== "École des Trois-Soleils") && !(userInfo['Limit Booking to Collection'] && userInfo['Limit Booking to Collection'].length) ? (
                    <div className="ms-part">
                        <span className="title">{t("mysettings.session-menu-request")}</span>
                        <p>{t("mysettings.session-menu-request-msg")}</p>
                        <div className="custom-control custom-checkbox mb-3">
                            <input
                                className="custom-control-input"
                                id="customCheck1"
                                type="checkbox"
                                defaultChecked={sessionRequest}
                                onChange={(e) => {
                                    setSessionRequest(!sessionRequest)
                                    setChanged(true);
                                }}
                            />
                            <label className="custom-control-label" htmlFor="customCheck1">{t("mysettings.session-menu-request-check")}</label>
                        </div>
                    </div>
                ) : null
            }

            <div className="ms-part">
                <span className="title">{t("pronouns")}</span>
                <p>{t("mysettings.pronoun-msg")}</p>
                <div className="ms-part-container">
                    <Input
                        className='pronouns'
                        defaultValue={pronouns}
                        onChange={(e) => {
                            setPronouns(e.target.value)
                            setChanged(true);
                        }}
                        type="text"
                    />
                </div>
            </div>

            <div className="ms-part">
                <span className="title">{t("mysettings.subjects")}</span>
                <p>{t("mysettings.subjects-msg")}</p>
                <div className="ms-part-container">
                    {
                        totalSubjects.map((sub, i) => (
                            <div
                                className={taughtSubjects.indexOf(sub.id) >= 0 ? "multicheck-item item-checked" : "multicheck-item"}
                                key={i}
                                onClick={() => toggleTaughtSubjects(sub)}
                            ><span>{sub.Subject}</span></div>
                        ))
                    }
                </div>
            </div>

            <div className="ms-part">
                <span className="title">{t("mysettings.grades")}</span>
                <div className="ms-part-container">
                    {
                        CurrentGradeLevels.map((grade, i) => (
                            <div
                                className={taughtGrades.indexOf(grade) >= 0 ? "multicheck-item item-checked" : "multicheck-item"}
                                key={i}
                                onClick={() => toggleTaughtGrades(grade)}
                            ><span>{grade}</span></div>
                        ))
                    }
                </div>
            </div>

            <div className="ms-action">
                <Button
                    type="button"
                    color="primary"
                    disabled={!changed || saving}
                    onClick={() => saveChanges()}
                >{saving && (<Loading size={14} />)}{t("mysettings.save-change")}</Button>
            </div>
        </div>
    )
}

export default Screen;
