import React, { useEffect, useState } from 'react';
import { getCurriculumLinks } from '../../../airtables/CurriculumLinks';
import { distinctGradesFromCLinks, distinctSubjectsFromCLinks, getCoreCompetenciesString, getLinkedSessionString, getStrandTopicLabel, isEqualGrade, isEqualSubject, isExistSubject } from './manage';
import { Form, FormGroup, Input, Label, Col } from 'reactstrap';
import { LinearProgress } from '@mui/material';
import Gap from '../../../components/Gap';
import './styles.scss';

const TabContent = ({ userType, regions, subjectMap, regionMap, openLink }) => {
    const [loading, setLoading] = useState(false);
    const [links, setLinks] = useState([]);
    const [filterSubjects, setFilterSubjects] = useState([]);
    const [filterGrades, setFilterGrades] = useState([]);
    const [subject, setSubject] = useState(null);
    const [grade, setGrade] = useState("");
    const [displayLinks, setDisplayLinks] = useState([]);

    useEffect(() => {
        if (links && links.length) {
            const subs = distinctSubjectsFromCLinks(links, subjectMap);
            setFilterSubjects(subs);
        } else {
            setFilterSubjects([]);
        }
    }, [links, subjectMap])

    useEffect(() => {
        if (!subject) {
            setFilterGrades([]);
            return;
        }

        const ll = [];
        links.forEach(link => {
            if (isExistSubject(subject, link["Subject"])) {
                ll.push(link);
            }
        })
        if (ll && ll.length) {
            const regs = distinctGradesFromCLinks(ll);
            setFilterGrades(regs);
        } else {
            setFilterGrades([]);
        }
    }, [subject, links]);

    useEffect(() => {
        if (links && links.length) {
            const res = []
            links.forEach(link => {
                var shouldAdd = true;
                if (subject && !isExistSubject(subject, link["Subject"])) {
                    shouldAdd = false;
                } else if (grade && link["Grade(s)"] && link["Grade(s)"].indexOf(grade) < 0) {
                    shouldAdd = false
                }

                if (shouldAdd) res.push(link);
            })
            if (subject) {
                res.sort((a, b) => {
                    if (a["Grade(s)"][0] === 'K') return -1;
                    if (b["Grade(s)"][0] === 'K') return 1;
                    return a["Grade(s)"][0] > b["Grade(s)"][0]
                })
            }
            setDisplayLinks(res);
        } else {
            setDisplayLinks([]);
        }
    }, [subject, grade, links])

    const updateLinks = (r) => {
        setSubject(null);
        setGrade("")
        getLinks(r);
    }

    const getLinks = (reg) => {
        if (reg) {
            setLoading(true);
            getCurriculumLinks(
                null,
                reg,
                null,
                [
                    { field: 'Subject', direction: 'asc' },
                    { field: "ID", direction: 'asc' }
                ]
            ).then(res => {
                setLoading(false);
                setLinks(res);
            }).catch(error => {
                setLoading(false);
            })
        } else {
            setLinks([]);
        }
    }

    const renderStrandTopicInfo = (link) => {
        var stlbl = getStrandTopicLabel(regionMap, link["Region"] ? link["Region"][0] : "")
        return (
            <div>
                <span className="link-title"><b>{stlbl.strand}</b>: {link["Strand"]}</span><br />
                {
                    link["Topic"] ? (
                        <span className="link-title"><b>{stlbl.topic}</b>: {link["Topic"]}</span>
                    ) : null
                }
                {
                    (link["Core Competencies"] && link["Core Competencies"].length) && (
                        <>
                            <br />
                            <span className="link-title"><b>Core Competency</b>: {getCoreCompetenciesString(link["Core Competencies"])}</span>
                        </>
                    )
                }
            </div>
        )
    }

    return (
        <div style={{ minHeight: 500 }}>
            <Gap height={20} />
            <Form>
                <FormGroup row>
                    <Label
                        for="region"
                        sm={2}
                        className="selector-label"
                    >Region:</Label>
                    <Col sm={6}>
                        <Input
                            type="select"
                            id="region"
                            onChange={e => {
                                updateLinks(e.target.value)
                            }}
                        >
                            <option value=''></option>
                            {
                                regions.filter(reg => reg["# Curriculum Links"] > 0).map((reg, i) => (
                                    <option value={reg.Name} key={i}>{reg["Long Name"]}</option>
                                ))
                            }
                        </Input>
                    </Col>
                </FormGroup>

                {
                    filterSubjects.length ? (
                        <FormGroup row>
                            <Label
                                for="subject"
                                className="selector-label"
                                sm={2}
                            >Subject:</Label>
                            <Col sm={6}>
                                <Input
                                    id="subject"
                                    type="select"
                                    onChange={e => {
                                        setSubject(e.target.value);
                                        setGrade("");
                                    }}
                                    defaultValue={subject}
                                >
                                    <option value=''></option>
                                    {
                                        filterSubjects.map((sub, i) => (
                                            <option value={sub.id} key={i}>{sub.Subject}</option>
                                        ))
                                    }
                                </Input>
                            </Col>
                        </FormGroup>
                    ) : null
                }

                {
                    filterGrades.length ? (
                        <FormGroup row>
                            <Label
                                for="grade"
                                sm={2}
                                className="selector-label"
                            >Grade Level:</Label>
                            <Col sm={6}>
                                <Input
                                    id="grade"
                                    type="select"
                                    onChange={(e) => {
                                        setGrade(e.target.value)
                                    }}
                                    defaultValue={grade}
                                >
                                    <option value=''></option>
                                    {
                                        filterGrades.map((type, i) => (
                                            <option key={i}>{type}</option>
                                        ))
                                    }
                                </Input>
                            </Col>
                        </FormGroup>
                    ) : null
                }

                <Gap height={20} />

                <div className="link-container">
                    {loading && <LinearProgress color="secondary" />}
                    {
                        displayLinks.map((link, i) => (
                            <div key={i}>
                                {
                                    !subject ? ((i === 0 || !isEqualSubject(displayLinks[i - 1], link)) ? (
                                        <div className="groupName">
                                            <span>{link["Subject Text"]}</span>
                                        </div>
                                    ) : null) : ((i === 0 || !isEqualGrade(displayLinks[i - 1], link)) ? (
                                        <div className="groupName">
                                            <span>{link["Grade(s)"]}</span>
                                        </div>
                                    ) : null)
                                }
                                <div className="link" onClick={e => openLink(link)}>
                                    {renderStrandTopicInfo(link)}
                                    <div style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                                        <span>{getLinkedSessionString(link["Linked Sessions"])}</span>
                                        <Gap width={10} />
                                        {
                                            userType === 'Team' ? (
                                                <>
                                                    <span>Bookings: {link["Bookings"]}</span>
                                                    <Gap width={10} />
                                                </>
                                            ) : null
                                        }
                                        <span>Grade(s): {link["Grade(s)"]}</span>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </Form>
            <Gap width={30} />
        </div>
    )
}

export default TabContent;