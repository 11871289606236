import React, { useState } from 'react';
import Gap from '../../../components/Gap';
import { Button, Input } from 'reactstrap';
import { LinearProgress } from '@mui/material';
import { getLinkFromKeyword } from '../../../airtables/CurriculumLinks';
import { getCoreCompetenciesString, getLinkedSessionString, getStrandTopicLabel, isEqualSubject } from './manage';

const TabContent = ({ userType, userInfo, openLink, regionMap }) => {
    const [keyword, setKeyword] = useState("");
    const [searching, setSearching] = useState(false);
    const [links, setLinks] = useState([]);
    const [error, setError] = useState("");

    const searchLinks = async (word) => {
        if (!word || !word.trim()) return;

        setSearching(true);
        setError("");
        setLinks([]);
        try {
            if (userType === 'Teacher') {
                const region = userInfo['School PT'] ? userInfo['School PT'][0] : null;
                if (!region) {
                    alert("User has no region information as a teacher.");
                    return;
                }
                const ll = await getLinkFromKeyword(word, regionMap[region]['Name']);
                setLinks(ll);
            } else {
                const ll = await getLinkFromKeyword(word, null);
                setLinks(ll);
            }

        } catch (err) { setError(err); }
        setSearching(false)
    }

    const renderStrandTopicInfo = (link) => {
        var stlbl = getStrandTopicLabel(regionMap, link["Region"] ? link["Region"][0] : "")
        return (
            <div>
                <span className="link-title"><b>{stlbl.strand}</b>: {link["Strand"]}</span><br />
                {
                    link["Topic"] ? (
                        <span className="link-title"><b>{stlbl.topic}</b>: {link["Topic"]}</span>
                    ) : null
                }
                {
                    (link["Core Competencies"] && link["Core Competencies"].length) && (
                        <>
                            <br />
                            <span className="link-title"><b>Core Competency</b>: {getCoreCompetenciesString(link["Core Competencies"])}</span>
                        </>
                    )
                }
            </div>
        )
    }

    return (
        <div style={{ minHeight: 500 }}>
            {searching && <LinearProgress />}
            <Gap height={20} />
            <div style={{ display: 'flex', maxWidth: 700 }}>
                <div style={{ flex: 1 }}>
                    <Input
                        type="text"
                        onChange={(e) => setKeyword(e.target.value)}
                        disabled={searching}
                        onKeyDown={(event) => {
                            if (event.keyCode === 13) searchLinks(keyword);
                        }}
                    />
                </div>
                <Gap height={20} />
                <Button color="primary" onClick={() => searchLinks(keyword)} disabled={searching}><i className="fa fa-search" /> Search</Button>
            </div>

            <Gap height={20} />
            {error && (<div><span style={{ color: 'red' }}>{error.toString()}</span></div>)}
            <div>
                {
                    links.map((link, i) => (
                        <div key={i}>
                            {
                                ((i === 0 || !isEqualSubject(links[i - 1], link)) ? (
                                    <div className="groupName">
                                        <span>{link["Subject Text"] ? link["Subject Text"].replace(/["]/g, "") : ""}</span>
                                    </div>
                                ) : null)
                            }

                            <div className="link" onClick={e => openLink(link)}>
                                {renderStrandTopicInfo(link)}

                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                    <div style={{ flex: 1 }}>
                                        <span>{getLinkedSessionString(link["Linked Sessions"])}</span>
                                    </div>
                                    {
                                        userType === 'Team' ? (
                                            <>
                                                <span className="additional-info">Bookings: {link["Bookings"]}</span>
                                                <Gap width={10} />

                                                <span className="additional-info">Region: {link["Region Text"]}</span>
                                                <Gap width={10} />
                                            </>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default TabContent;