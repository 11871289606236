import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { searchSessions } from '../../airtables/Sessions';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import SessionList from './other';

const Screen = () => {
    const { t } = useTranslation();
    const { userInfo, userType } = useSelector(state => state.appInfo);
    const [showPastSessions, setShowPastSessions] = useState(false);
    const [activeTab, setActiveTab] = useState('upcoming');

    useEffect(() => {
        (async () => {
            if (userType === 'Provider') {
                const pastSessions = await searchSessions(userInfo["Name"], "", ["Completed", "Cancelled with Payment"]);
                if (pastSessions.length > 0) {
                    setShowPastSessions(true)
                }
            }
        })();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const emptyTeachingInfo = () => {
        if (!userInfo) return false;
        if (userInfo["Subject(s) Taught"] && userInfo["Subject(s) Taught"].length) return false;
        if (userInfo["Grade(s) Taught"] && userInfo["Grade(s) Taught"].length) return false;
        return true;
    }

    return (
        <div className="main-container">
            <Row>
                <Col><span className="page-topic">{t('upcoming_sessions')}</span></Col>
                {
                    userType === 'Provider' && showPastSessions && (
                        <Col align="right">
                            <Link to="upcoming/past">
                                <span className="page-topic title-link">{t("view-past-sessions")} <i className='fa fa-arrow-right' /></span>
                            </Link>
                        </Col>
                    )
                }

                {
                    userType === "Teacher" && (
                        <Col align="right">
                            <Link to="upcoming/same-school">
                                <span className="page-topic title-link">{t("view-sessions-in-same-school")} <i className='fa fa-arrow-right' /></span>
                            </Link>
                        </Col>
                    )
                }
            </Row>

            <div style={{ marginTop: 10 }}>
                {
                    (userType === "Teacher" && emptyTeachingInfo()) && (
                        <div className="notify notify-info" style={{ marginBottom: 20 }}>
                            <span>
                                <Trans i18nKey="teacher-setting-msg">
                                    Help us customize recommendations to your needs. Please <a href="/cn/mysettings">let us know what subject(s) and grade(s)</a> you're teaching!
                                </Trans>
                            </span>
                        </div>
                    )
                }

                {
                    (userType === 'Team' && userInfo['Administrator']) ? (
                        <div className="main-tabs">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === 'upcoming' })}
                                        onClick={() => setActiveTab('upcoming')}
                                    >{t("my-upcoming")}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === 'all' })}
                                        onClick={() => setActiveTab('all')}
                                    >{t("all-upcoming")}</NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="upcoming">
                                    <SessionList />
                                </TabPane>
                                <TabPane tabId="all">
                                    <SessionList isAdmin={true} />
                                </TabPane>
                            </TabContent>
                        </div>
                    ) : (
                        <SessionList />
                    )
                }
            </div>
        </div>
    )
}

export default Screen;