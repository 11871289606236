import React from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'reactstrap'
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import './styles.scss';
import { ListSubheader } from '@mui/material';

const AssignPresenter = ({ onToggle, onSelect, session }) => {
    const { presentersForProvider } = useSelector(state => state.appInfo);

    return (
        <Modal
            className="modal-dialog-centered assign-presenter-modal"
            isOpen={true}
            toggle={() => onToggle()}
        >
            <div className="modal-body">
                <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <nav aria-label="main mailbox folders">
                        <List
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Please select a Presenter for this session.
                                </ListSubheader>
                            }
                        >
                            {
                                presentersForProvider.map((presenter, i) => {
                                    const prevPresenter = (session["Presenters"] && session["Presenters"].length) ? session["Presenters"][0] : null;
                                    return (
                                        <ListItem disablePadding key={i} onClick={() => {
                                            if (prevPresenter === presenter.id) return;
                                            if (onSelect) onSelect(session.id, presenter.id);
                                        }}>
                                            <ListItemButton>
                                                <ListItemIcon className='checked'>
                                                    {prevPresenter === presenter.id && <CheckIcon />}
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={presenter.Name}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography
                                                                className="presenter-info"
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                <span>{presenter.Email}</span>
                                                                <span>{presenter.Phone}</span>
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </nav>
                </Box>
            </div>
        </Modal>
    )
}

export default AssignPresenter;