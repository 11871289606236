import React, { useEffect, useRef, useState } from 'react';
import { getTagById } from '../../../airtables/Tags'
import { selectProviderSessionById } from '../../../airtables/ProviderSessions';
import { LinearProgress } from '@mui/material';
import { GradeLevels } from '../../../utils/constants';
import { useSelector } from 'react-redux';

const Screen = (props) => {
    const tagId = props.match.params.tagId;
    const [loading, setLoading] = useState(true);
    const [tag, setTag] = useState(null);
    const [sessions, setSessions] = useState([]);
    const [error, setError] = useState("");
    const [progress, setProgress] = useState(0);
    const [total, setTotal] = useState(0);
    const [sessionsMap, setSessionsMap] = useState({});
    const { totalSubjects } = useSelector(state => state.appInfo);
    var dataMap = useRef({}).current;
    var sessionDataMap = useRef({}).current;

    useEffect(() => {
        (async () => {
            setLoading(true);

            const t = await getTagById(tagId);

            if (!t) {
                setError("Invalid request!");
                return;
            }

            if (!t['Provider Sessions']) {
                setError("No results were found.");
                return;
            }

            const ids = t['Provider Sessions'];
            setTag(t);
            setTotal(ids.length);

            if (t["Group By"]) {
                await manageDataMap(t);
            }

            const sesses = [];
            for (var i = 0; i < ids.length; i++) {
                var session = await selectProviderSessionById(ids[i]);

                setProgress(i + 1);

                if (session.Status !== 'Consider Another Option' && session.Status !== 'Active (Open for booking)')  continue;

                if (t['Group By']) {
                    manageSessionMap(t, session)
                }

                sesses.push(session);
                sesses.sort((a, b) => {
                    if (a['Title'] > b['Title']) return 1;
                    return -1;
                });
                setSessions([...sesses]);
            }
            setLoading(false);
        })();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const manageDataMap = async (t) => {
        switch (t["Group By"]) {
            case "Subject":
                totalSubjects.forEach(sub => {
                    dataMap[sub.id] = {
                        ...sub,
                        title: sub.Subject
                    };
                })
                break;
            case "Grade":
                GradeLevels.forEach(grade => {
                    dataMap[grade] = {
                        title: grade
                    };
                });
                break;
            default:
                break;
        }
    }

    const manageSessionMap = (t, session) => {
        switch (t["Group By"]) {
            case "Subject":
                const subs = session['Subject'];
                subs.forEach(sub => {
                    const subject = dataMap[sub];
                    if (!sessionDataMap[subject.title]) {
                        sessionDataMap = {
                            ...sessionDataMap,
                            [subject.title]: []
                        };
                    }
                    sessionDataMap[subject.title].push(session);

                    setSessionsMap({ ...sessionDataMap });
                })
                break;
            case "Grade":
                const grades = session['Grade(s)'];
                grades.forEach(g => {
                    const grade = dataMap[g];
                    if (!sessionDataMap[grade.title]) {
                        sessionDataMap = {
                            ...sessionDataMap,
                            [grade.title]: []
                        };
                    }
                    sessionDataMap[grade.title].push(session);

                    setSessionsMap({ ...sessionDataMap });
                });
                break;
            default:
                return;
        }
    }

    const renderSessions = () => {
        if (tag['Group By']) {
            const keys = Object.keys(sessionsMap);
            keys.sort();
            
            return (
                keys.map((k, i) => (
                    <div key={i} className='tag-group'>
                        <span>{k}</span>
                        {
                            sessionsMap[k].map((session, j) => (
                                <div
                                    className="tag-session"
                                    key={`${i}_${j}`}
                                    onClick={() => window.open(`/cn/session/${session.id}`)}
                                >
                                    <span className="title">{session['Title']}</span>
                                    {session.Description ? (
                                        <div>
                                            <p className="short-desc">{session.Description}</p>
                                        </div>
                                    ) : null}
                                </div>
                            ))
                        }
                    </div>
                ))
            )
        }

        return (
            sessions.map((session, index) => (
                <div
                    className="search-session"
                    key={index}
                    onClick={() => window.open(`/cn/session/${session.id}`)}
                >
                    <span className="title">{session['Title']}</span>
                    {session.Description ? (
                        <div>
                            <p className="short-desc">{session.Description}</p>
                        </div>
                    ) : null}
                </div>
            ))
        )
    }

    return (
        <div className="main-container">
            <span className="page-topic">{tag?.Tag}</span>
            <p>{tag?.Description}</p>
            <div style={{ marginTop: 20, minHeight: 500 }}>
                {loading && (
                    <LinearProgress
                        variant={total ? 'determinate' : 'indeterminate'}
                        value={total ? (progress / total * 100) : 0}
                    />
                )}
                <div>
                    {
                        sessions.length > 0 ? renderSessions() : (
                            <div className="nolist-placeholder" align="center">
                                <span>{error}</span>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Screen;