import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MeaningfulLandAcknowledgement from './pages/MeaningfulLandAcknowledgement'
import FacilitationStudentInteractivity from './pages/FacilitationStudentInteractivity';
import IndigenousHistoriesContributions from './pages/IndigenousHistoriesContributions';
import TraumaInformedApproaches from './pages/TraumaInformedApproaches';
import CreativelyCustomizedContent from './pages/CreativelyCustomizedContent';
import IntroductionContent from './pages/Introduction';
import RecordingPractices from './pages/RecordingPractices';
import { useDispatch, useSelector } from 'react-redux';
import { BestPracticeLabels, BestPracticeReflectionFieldNames } from '../../utils/constants';
import { updateProvider } from '../../airtables/Providers';
import { updatePresenter } from '../../airtables/Presenters';
import { setAppUserInfo } from '../../redux/actions';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import StepProgress from '../../components/StepProgress';
import './styles.scss';
import { isCompletedBestPractices, isFirstVisitAfterBPCompleted } from '../../utils/bp-utils';

const Screen = (props) => {
    const page = props.match.params.page;
    const [pageType, setPageType] = useState("")
    const { userInfo, userType } = useSelector(state => state.appInfo);
    const BPInfo = userInfo["Best Practice Commitments"];
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const pages = [
        "introduction",
        "meaningful-land-acknowledgement",
        "facilitation-of-student-interactivity",
        "creatively-customized-content",
        "indigenous-histories-contributions",
        "trauma-informed-approaches"
    ]

    if (userType === 'Provider' && (!userInfo["Recording Allowed"] || pageType === 'recording-practices'))   pages.push("recording-practices");

    useEffect(() => {
        if (userType === "Presenter") return;
        if (userInfo['Delivered 21-22'] === 0) return;
        if (!userInfo['Other Surveys'] || !userInfo['Other Surveys'].length) {
            window.location.href = "/cn/survey"
            return;
        }

        if (isCompletedBestPractices(userInfo, userType)) {
            if (isFirstVisitAfterBPCompleted(userInfo, userType)) {
                updateProvider(userInfo.id, { 'All BP Modules Completed': true })
            } /* else if (userInfo["22-23 Provider Reviewed"]) {
                return ;
            } else {
                window.location.href = "/cn/mysessions";
            } */
        }
    }, [userInfo, userType])

    useEffect(() => {
        setPageType(page)
    }, [page])

    const nextPage = () => {
        const order = pages.indexOf(pageType);
        return pages[((order + 1) % (pages.length))];
    }

    const updateCommitments = async (refelction) => {
        let updateFunc;
        if (userType === "Provider") updateFunc = updateProvider;
        if (userType === "Presenter") updateFunc = updatePresenter;
        if (updateFunc) {
            const newCommitments = [...(BPInfo ? BPInfo : [])];
            if (newCommitments.indexOf(BestPracticeLabels[pageType].name) < 0) {
                newCommitments.push(BestPracticeLabels[pageType].name);
            }
            const res = await updateFunc(userInfo.id, {
                "Best Practice Commitments": newCommitments,
                [BestPracticeReflectionFieldNames[pageType]]: refelction ? refelction : ""
            });
            const newUserInfo = {
                id: res[0].id,
                ...res[0].fields
            }

            if (isCompletedBestPractices(newUserInfo, userType)) {
                window.location.href = "/cn/mysessions"
            } else {
                dispatch(setAppUserInfo(newUserInfo));
                history.push(nextPage());
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }
        }
    }

    const renderPageContent = () => {
        switch (pageType) {
            case "introduction":
                return <IntroductionContent />
            case "meaningful-land-acknowledgement":
                return <MeaningfulLandAcknowledgement commitment={updateCommitments} />;
            case "facilitation-of-student-interactivity":
                return <FacilitationStudentInteractivity commitment={updateCommitments} />;
            case "indigenous-histories-contributions":
                return <IndigenousHistoriesContributions commitment={updateCommitments} />;
            case "trauma-informed-approaches":
                return <TraumaInformedApproaches commitment={updateCommitments} />;
            case "creatively-customized-content":
                return <CreativelyCustomizedContent commitment={updateCommitments} />;
            case "recording-practices":
                return <RecordingPractices />
            default:
                return null;
        }
    }

    const isCompletedPage = (page) => {
        if (page === 'introduction')    return true;
        if (page === 'recording-practices') return (userInfo["Recording Allowed"] ? true : false);
        return (BPInfo && BPInfo.indexOf(BestPracticeLabels[page].name) >= 0);
    }

    const NavItem = ({ nav, label, checked, active }) => {
        return (
            <Link to={nav}>
                <div className={active ? "bp-nav-item active" : "bp-nav-item"}>
                    <div className="commitment-status">
                        {checked ? <span style={{ color: 'green' }}>&#10004;</span> : <span style={{ color: 'red' }}>&#9744;</span>}
                    </div>
                    <span className="bp-nav-label">{label}</span>
                </div>
            </Link>
        )
    }

    return (
        <div className="main-container">
            {
                userType === "Provider" && userInfo['Delivered 21-22'] > 0 && !userInfo["22-23 Provider Reviewed"] && (
                    <>
                        <StepProgress
                            steps={[t("provider-step-progress.survey"), t("provider-step-progress.best-practices"), t("provider-step-progress.my-sessions")]}
                            activeStep={1}
                            completedSteps={[0]}
                        />
                        <div style={{ height: 20 }} />
                    </>
                )
            }

            <span className="page-topic">{t("best-practices")}</span>

            <div className="bp-container">
                <div className="bp-nav-bar">
                    {
                        pages.map((p, i) => (
                            <NavItem
                                key={i}
                                nav={p}
                                label={t(BestPracticeLabels[p].locale)}
                                checked={isCompletedPage(p)}
                                active={pageType === p}
                            />
                        ))
                    }
                </div>
                <div className="bp-content">{renderPageContent()}</div>
            </div>
        </div>
    )
}

export default Screen;
