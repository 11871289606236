import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { LinearProgress } from '@mui/material';
import { selectProviderSessionById } from '../airtables/ProviderSessions';
import { getTeacherById } from '../airtables/Teachers';
import { setMyFavorites } from '../redux/actions';
import { useTranslation } from 'react-i18next';

const Screen = () => {
    const [loading, setLoading] = useState(true);
    const [sessions, setSessions] = useState([]);
    const { subjectMap, userInfo } = useSelector(state => state.appInfo);
    const [progress, setProgress] = useState(0);
    const [total, setTotal] = useState(0);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const getSubjectName = (subjects) => {
        if (!subjects || subjects.length === 0) return "No subject";

        return subjects.reduce((result, subId, index) => {
            if (index !== 0) result += ", ";
            result += subjectMap[subId]['Subject'];
            return result;
        }, "")
    }

    useEffect(() => {
        (async () => {
            setLoading(true);
            const teacherInfo = await getTeacherById(userInfo.id);
            const favorites = teacherInfo['Favourites'] ? teacherInfo['Favourites'] : [];
            setTotal(favorites.length);
            dispatch(setMyFavorites(favorites))

            var sesslist = [];
            for (var i = 0; i < favorites.length; i++) {
                const session = await selectProviderSessionById(favorites[i]);
                sesslist.push({
                    ...session,
                    SubjectText: getSubjectName(session['Subject'])
                });
                setProgress(i + 1);
            }

            sesslist.sort((a, b) => {
                return a["SubjectText"].localeCompare(b["SubjectText"]);
            })
            setSessions([...sesslist]);
            setLoading(false);
        })();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="main-container">
            <Row>
                <Col><span className="page-topic">My Favourites</span></Col>
                <Col align="right"></Col>
            </Row>
            <div style={{ marginTop: 20, minHeight: 500 }}>
                {loading && (
                    <LinearProgress
                        variant={total ? 'determinate' : 'indeterminate'}
                        value={total ? (progress / total * 100) : 0}
                    />
                )}
                <div>
                    {
                        (sessions && sessions.length) ? sessions.map((session, i) => (
                            <div key={i} className="favorite">
                                {(i === 0 || session["SubjectText"] !== sessions[i - 1]["SubjectText"]) && (
                                    <span className="subject-name">{session["SubjectText"]}</span>
                                )}
                                <div
                                    className="favorite-session"
                                    onClick={() => window.open(`/cn/session/${session.id}`)}
                                >
                                    <span className="title">{session['Title']}</span>
                                    {session.Description ? (
                                        <div>
                                            <p className="short-desc">{session.Description}</p>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        )) : (
                            <div className="nolist-placeholder" align="center">
                                <span>{t("no-favorites")}</span>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Screen;