import React, { useEffect, useRef, useState } from 'react';
import Commitment from '../components/Commitment';
import traumatoleranceImg from '../../../assets/img/traumatolerance.png';
import wellbeingImg from '../../../assets/img/wellbeing.png';
import Vimeo from '@u-wave/react-vimeo';
import { updateVideoHistory } from '../videotrack';
import { useDispatch, useSelector } from 'react-redux';
import { setAppUserInfo } from '../../../redux/actions';
import { logEvent } from 'firebase/analytics'
import { Trans, useTranslation } from 'react-i18next';
import { analytics } from '../../../firebase';

const url1 = "https://www.connectednorth.org/en/wellbeing"
const url2 = "https://nctr.ca/records/reports/#trc-reports"
const url3 = "https://takingitglobal.uberflip.com/i/1355551-connected-north-a-journey-of-transformation-well-being/0?";
const url4 = "https://amzn.to/3sU9wcs";
const url5 = "https://amzn.to/3wOoiE0";

const NavContent = ({ commitment }) => {
    const ref = useRef(null);
    const [videoWidth, setVideoWidth] = useState(0);
    const [watchStarted, setWatchStarted] = useState(false);
    const { userInfo, userType } = useSelector(state => state.appInfo);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const trackVideo = async (type) => {
        logEvent(analytics, `video-${type}`, {
            viewer: userInfo['Name'],
            video: `truth-reconciliation-sandi-boucher`
        })
    }

    useEffect(() => {
        setVideoWidth(ref.current.offsetWidth);
    }, [])

    return (
        <div style={{ width: '100%', marginTop: 10 }} ref={ref}>
            <div className="main-content">
                <p><strong>{t("best-practices-content.tfa.title")}</strong></p>
                <p>{t("best-practices-content.tfa.para1")}</p>

                <p><u>{t("best-practices-content.tfa.para2")}</u></p>
                <div className="embeded-conatiner">
                    {
                        videoWidth !== 0 && (
                            <Vimeo
                                video="https://vimeo.com/707085016/3bdf046a00"
                                width={videoWidth}
                                onPlay={async () => {
                                    if (!watchStarted) {
                                        setWatchStarted(true);
                                        trackVideo('play');
                                        const nUserInfo = await updateVideoHistory(userType, userInfo, "Sandi Boucher", "starting");
                                        dispatch(setAppUserInfo(nUserInfo));
                                    }
                                }}
                                onEnd={async () => {
                                    trackVideo('full-watch');
                                    const nUserInfo = await updateVideoHistory(userType, userInfo, "Sandi Boucher", "finished");
                                    dispatch(setAppUserInfo(nUserInfo));
                                }}
                            />
                        )
                    }
                </div>
                <p>{t("best-practices-content.tfa.para3")}</p>
                <p>
                    <Trans i18nKey={"best-practices-content.tfa.para4.lead"}>Both the intergenerational impact and the legacy of residential schools are prominent in the North (<a href={url2} target="_blank" rel="noopener noreferrer">Truth and Reconciliation Commission</a>, 2015):</Trans>
                </p>
                <ul>
                    <li>{t("best-practices-content.tfa.para4.li1")}</li>
                    <li>{t("best-practices-content.tfa.para4.li2")}</li>
                    <li>{t("best-practices-content.tfa.para4.li3")}</li>
                    <li>{t("best-practices-content.tfa.para4.li4")}</li>
                    <li>{t("best-practices-content.tfa.para4.li5")}</li>
                </ul>

                <div>
                    <p>{t("best-practices-content.tfa.para5")}</p>
                    <img src={traumatoleranceImg} style={{ float: 'right', marginLeft: 20, marginBottom: 20 }} alt="traumatolerance" />
                    <p>{t("best-practices-content.tfa.para6")}</p>
                    <p><em>
                        <Trans i18nKey={"best-practices-content.tfa.para7"}>Reflection: How do you typically respond when you feel like people are not paying attention to your level of expectation?  It is important to recognize that students have <strong>many learning styles</strong>.  This may include observation or even doing something else at the same time (for example doodling, standing or sitting in a different location of the room).  It is not the role of the content provider to make any remarks that take a disciplinary or condescending approach (for example, singling out a student for one of those behaviours) but rather for us to respond and engage in a <strong>dialogue that is based on respect</strong>.  As additional context, many of the students might have a hard time paying attention because other needs may not have been met (for example hunger, sleep deprivation, mental illness, family problems).  It is not our place as guests to make any judgments or assumptions, but rather to visit with classrooms with an open heart and an open mind.</Trans>
                    </em></p>
                    <p><strong>{t("best-practices-content.tfa.para8.lead")}</strong></p>
                    <ul>
                        <li>
                            <strong>{t("best-practices-content.tfa.para8.li1.lead")}</strong> - {t("best-practices-content.tfa.para8.li1.content")}
                        </li><br />
                        <li>
                            <strong>{t("best-practices-content.tfa.para8.li2.lead")}</strong> - {t("best-practices-content.tfa.para8.li2.content")}
                        </li><br />
                        <li>
                            <strong>{t("best-practices-content.tfa.para8.li3.lead")}</strong> - {t("best-practices-content.tfa.para8.li3.content")}
                        </li>
                    </ul>
                </div>
                <p><img src={wellbeingImg} alt="Connected North's Well-being Framework" style={{ flex: 1, width: 620, resizeMode: 'contain' }} /></p>
                <p>{t("best-practices-content.tfa.para9.lead")}</p>
                <ol>
                    <li>
                        <Trans i18nKey={"best-practices-content.tfa.para9.li1"}>Indigenous students are given <strong>exposure to the world</strong> beyond their communities through virtual field trips.  This ignites greater curiosity and interest in learning beyond the classroom and a sense of connectedness while allowing for greater understanding of subject matter being covered within the curriculum.</Trans>
                    </li>
                    <li>
                        <Trans i18nKey={"best-practices-content.tfa.para9.li2"}>Exchanges with other classrooms where students are able to learn together and share aspects of their culture with one another helps to strengthen their sense of <strong>identity and belonging</strong>.</Trans>
                    </li>
                    <li>
                        <Trans i18nKey={"best-practices-content.tfa.para9.li3"}>When students are able to present themselves, their questions and their work to external audiences, it ignites a sense of <strong>self confidence and purpose</strong>.</Trans>
                    </li>
                    <li>
                        <Trans i18nKey={"best-practices-content.tfa.para9.li4"}>When students are able to interact and hear from <strong>Indigenous role models</strong> ranging from elders, storytellers, artists, entrepreneurs and other professionals, the experiences shared ignite a sense of <strong>hope</strong> for the future and provide an important source of <strong>encouragement and motivation</strong>.</Trans>
                    </li>
                    <li>
                        <Trans i18nKey={"best-practices-content.tfa.para9.li5"}>When students are given <strong>voice and choice</strong> in their education with opportunities for personalized access to training, skills development and mentorship, they become more engaged and experience a sense of happiness and joy in their learning.</Trans>
                    </li>
                    <li>
                        <Trans i18nKey={"best-practices-content.tfa.para9.li6"}>When teachers take part in professional development learning with other teachers in the north along with professional facilitators and guest speakers with expertise on health related topics, they are able to increase their <strong>knowledge, skills and capacity</strong> to respond to challenges that arise.</Trans>
                    </li>
                </ol>
                <p>{t("best-practices-content.tfa.para10")}</p>

                <p>
                    <Trans i18nKey={"best-practices-content.tfa.para11"} values={{ url: url1 }}>As part of this holistic approach, we encourage content providers to reflect on the ways their sessions can link to one or more elements of the Well-Being Framework: <a href={url1} target="_blank" rel="noopener noreferrer">{{ url1 }}</a></Trans>
                </p>

                <p>
                    <Trans i18nKey={"best-practices-content.tfa.para12"}>To read more about our journey cultivating transformation and wellbeing, you can read Connected North's recent program evaluation, <a href={url3} target="_blank" rel="noopener noreferrer">A Journey of Transformation &amp; Well-Being</a>, prepared by Dr Cameron Norman PhD MDes CE.</Trans>
                </p>

                <strong>{t("best-practices-content.tfa.para13.lead")}</strong>
                <ul className="ul2">
                    <li><a href={url4} target="_blank" rel="noopener noreferrer">{t("best-practices-content.tfa.para13.li1")}</a></li>
                    <li><a href={url5} target="_blank" rel="noopener noreferrer">{t("best-practices-content.tfa.para13.li2")}</a></li>
                </ul>
            </div>

            <Commitment submitConfirm={commitment} type="trauma-informed-approaches" />
        </div>
    )
}

export default NavContent
