import { BestPracticeLen } from './constants';

export const isCompletedBestPractices = (userInfo, userType) => {
    if (userType !== "Provider") return false;

    const BPInfo = userInfo["Best Practice Commitments"];
    if (BPInfo && BPInfo.length === BestPracticeLen) {
        if (!userInfo["Recording Allowed"]) return false;
        return true;
    } else {
        return false;
    }
}

export const isFirstVisitAfterBPCompleted = (userInfo, userType) => {
    if (!isCompletedBestPractices(userInfo, userType))  return false;

    if (userInfo["All BP Modules Completed"])   return false;
    return true;
}