import React, { useEffect, useRef, useState } from 'react';
import example1 from '../../../assets/img/example1.png'
import example2 from '../../../assets/img/example2.png'
import Commitment from '../components/Commitment';
import Vimeo from '@u-wave/react-vimeo';
import { useSelector } from 'react-redux';
import { logEvent } from "firebase/analytics";
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { analytics } from '../../../firebase';

const NavContent = ({ commitment }) => {
    const ref = useRef(null);
    const [videoWidth, setVideoWidth] = useState(0);
    const [watchStarted, setWatchStarted] = useState(false);
    const { userInfo } = useSelector(state => state.appInfo);
    const { t } = useTranslation();

    useEffect(() => {
        setVideoWidth(ref.current.offsetWidth);
    }, [])

    const trackVideo = async (type) => {
        logEvent(analytics, `video-${type}`, {
            viewer: userInfo['Name'],
            video: `vimeo-638026030`
        })
    }

    const onPlay = () => {
        if (watchStarted) {
            setWatchStarted(true);
            trackVideo('play');
        }
    }
    const onEnd = () => {
        trackVideo('full-watch');
    }

    return (
        <div style={{ width: '100%', marginTop: 10 }} ref={ref}>
            <div className="main-content">
                <p><strong>{t("best-practices-content.fsi.title")}</strong></p>

                <p>{t("best-practices-content.fsi.para1")}</p>

                <strong>{t("best-practices-content.fsi.para2.lead")}</strong>
                <ul>
                    <li>{t("best-practices-content.fsi.para2.li1")}</li>
                    <li>{t("best-practices-content.fsi.para2.li2")}</li>
                    <li>{t("best-practices-content.fsi.para2.li3")}</li>
                    <li>{t("best-practices-content.fsi.para2.li4")}</li>
                    <li>{t("best-practices-content.fsi.para2.li5")}</li>
                </ul>

                <strong>{t("best-practices-content.fsi.para3.lead")}</strong>
                <ul>
                    <li>{t("best-practices-content.fsi.para3.li1")}</li>
                    <li>{t("best-practices-content.fsi.para3.li2")}</li>
                    <li>{t("best-practices-content.fsi.para3.li3")}</li>
                    <li>{t("best-practices-content.fsi.para3.li4")}</li>
                </ul>

                <strong>{t("best-practices-content.fsi.para4.lead")}</strong>
                <ul>
                    <li>{t("best-practices-content.fsi.para4.li1")}</li>
                    <li>{t("best-practices-content.fsi.para4.li2")}</li>
                    <li>{t("best-practices-content.fsi.para4.li3")}</li>
                </ul>

                <p>{t("best-practices-content.fsi.para5")}</p>

                <p>{t("best-practices-content.fsi.para6")}</p>

                <p>{t("best-practices-content.fsi.para7")}</p>

                <p>{t("best-practices-content.fsi.para8")}</p>

                <p>{t("best-practices-content.fsi.para9")}</p>

                {t("best-practices-content.fsi.para10.lead")}
                <ul>
                    <li>{t("best-practices-content.fsi.para10.li1")}</li>
                    <li>{t("best-practices-content.fsi.para10.li2")}</li>
                    <li>{t("best-practices-content.fsi.para10.li3")}</li>
                    <li>{t("best-practices-content.fsi.para10.li4")}</li>
                    <li>{t("best-practices-content.fsi.para10.li5")}</li>
                    <li>{t("best-practices-content.fsi.para10.li6")}</li>
                    <li>{t("best-practices-content.fsi.para10.li7")}</li>
                </ul>

                <p>{t("best-practices-content.fsi.para11.lead")}</p>

                <Row>
                    <Col sm="6">
                        <img src={example1} alt="example 1" className="fac-img" />
                        <span>{t("best-practices-content.fsi.para11.col1")}</span>
                    </Col>
                    <Col sm="6">
                        <img src={example2} alt="example 2" className="fac-img" />
                        <span>{t("best-practices-content.fsi.para11.col2")}</span>
                    </Col>
                </Row>

                <div className="embeded-conatiner">
                    {
                        videoWidth !== 0 && (
                            <Vimeo
                                video="https://vimeo.com/638019398/f4062cc9d4"
                                width={videoWidth}
                                onPlay={() => onPlay()}
                                onEnd={() => onEnd()}
                            />
                        )
                    }
                </div>
                <center><span>{t("best-practices-content.fsi.para12")}</span></center>

                <div className="embeded-conatiner">
                    {
                        videoWidth !== 0 && (
                            <Vimeo
                                video="https://vimeo.com/638026178/481105f7dc"
                                width={videoWidth}
                            />
                        )
                    }
                </div>
                <center><span>{t("best-practices-content.fsi.para13")}</span></center>

            </div>

            <Commitment submitConfirm={commitment} type="facilitation-of-student-interactivity" />
        </div>
    )
}

export default NavContent
