import base from './config';
const tableName = "Presenters"

export const getPresenterByEmail = (email) => {
    const em = email.toUpperCase();
    return new Promise((resolve, reject) => {
        base(tableName).select({
            filterByFormula: `FIND("${em}", UPPER({Email}))`,
            maxRecords: 1
        }).firstPage().then(res => {
            if (res.length > 0) {
                resolve({
                    id: res[0].id,
                    ...res[0].fields
                })
            } else {
                resolve(null)
            }
        }).catch(error => {
            reject(error.toString());
        })
    });
}

export const getPresenterById = (id) => {
    return new Promise((resolve, reject) => {
        base(tableName).find(id).then(record => {
            if (record && record._rawJson){
                resolve({
                    ...record._rawJson.fields,
                    id: record._rawJson.id
                })
            } else {
                resolve(null)
            }
        }).catch(error => {
            reject(error.toString())
        })
    })
}

export const createPresenter = (provider, email, name, phone) => {
    return new Promise((resolve, reject) => {
        base(tableName).create([{
            fields: {
                "Name": name,
                "Email": email,
                "Phone": phone,
                "Provider": [
                    provider
                ], 
                "Self Signup": true,
                "Platform Logins": 1
            }
        }]).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export const updatePresenter = (id, param) => {
    return new Promise((resolve, reject) => {
        base(tableName).update([{
            id, fields: param
        }]).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export const removePresenter = (id) => {
    return new Promise((resolve, reject) => {
        base(tableName).destroy([id]).then(() => {
            resolve()
        }).catch(error => {
            reject(error)
        })
    })
}