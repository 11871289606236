import base from './config';
const tableName = "C2L"

export const getC2LById = (id) => {
    return new Promise((resolve, reject) => {
        base(tableName).find(id).then(record => {
            if (record && record._rawJson){
                resolve({
                    ...record._rawJson.fields,
                    id: record._rawJson.id
                })
            } else {
                resolve(null)
            }
        }).catch(error => {
            reject(error.toString())
        })
    })
}

export const updateC2L = (id, param) => {
    return new Promise((resolve, reject) => {
        base(tableName).update([{
            id, fields: param
        }]).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}