import React from 'react';
import { useSelector } from 'react-redux';
import ProviderInfo from './provider';
import PresenterInfo from './presenter';

const Screen = () => {
    const { userType } = useSelector(state => state.appInfo);

    if (userType === 'Provider') return <ProviderInfo />
    if (userType === 'Presenter') return <PresenterInfo />
    return null
}

export default Screen;