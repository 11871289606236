import React, { useEffect, useState } from 'react';
import ReactTimeAgo from 'react-time-ago'
import { getNiceFormatDateTimeString } from '../../utils/time';
import SessionReject from '../../pages/modals/SessionReject';
import { useDispatch, useSelector } from 'react-redux';
import { updateTeacher } from '../../airtables/Teachers'
import { setMyFavorites } from '../../redux/actions';
import Loading from '../../components/Loading';
import ReactStars from "react-rating-stars-component";
import './styles.scss';
import { getNiceFormatDateString } from '../../utils/time';
import { useTranslation } from 'react-i18next';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap';
import { toast } from 'react-toastify';

const SessionInfo = ({
    session,
    displayInfos = [],
    gotoBooking,
    onRefresh,
    actionMenu = [],
    assignPresenter,
    reassignSessionLead,
    requestSupport,
    openSessionDetail,
    openInternalIDModal,
    changeStatus,
    editRequest,
    cancelRequest,
    bookConfirm,
    rateSession,
    requestPayment,
    generateInvoice,
    openSessionResult,
    openProviderFeedbackModal
}) => {
    const { userType, userInfo, appLocale, myFavorites, presentersForProvider } = useSelector(state => state.appInfo);
    const [rejectModal, setRejectModal] = useState(false);
    const [favoriteState, setFavoriteState] = useState('loading');
    const providerSessionId = session['Session Title'] ? session['Session Title'][0] : "";
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const canDisplayInfo = (infoName) => {
        if (!displayInfos) return false;

        return displayInfos.indexOf(infoName) >= 0
    }

    const canDisplayMenu = (menuName) => {
        if (!actionMenu) return false;
        return actionMenu.indexOf(menuName) >= 0;
    }

    useEffect(() => {
        if (userType === 'Teacher' && canDisplayInfo("Favorite")) {
            setFavoriteState(myFavorites.indexOf(providerSessionId) >= 0 ? 'favorite' : 'unfavorite');
        }
    }, [myFavorites]) // eslint-disable-line react-hooks/exhaustive-deps

    const gotoURL = (e, url) => {
        e.preventDefault();
        window.location.href = url;
    }

    const toggleFavorite = async () => {
        if (!canDisplayInfo("Favorite")) return;
        if (favoriteState === 'loading') return;

        if (favoriteState === 'favorite') {
            setFavoriteState('loading');
            const favorites1 = [...myFavorites].filter(v => v !== providerSessionId);
            await updateTeacher(userInfo['id'], { Favourites: favorites1 });
            setFavoriteState('unfavorite')
            dispatch(setMyFavorites(favorites1));
        } else if (favoriteState === 'unfavorite') {
            setFavoriteState('loading');
            const favorites2 = [...myFavorites, providerSessionId]
            await updateTeacher(userInfo['id'], { Favourites: favorites2 });
            setFavoriteState('favorite');
            dispatch(setMyFavorites(favorites2));
        }
    }

    const schoolLocation = () => {
        const pts = session["School P/T Text"].split(",").map(pt => pt.trim());

        return session["School Community"].map((school, i) => (
            <div className="session-item-tag yellow-tag" key={i}>
                <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={16} height={16}><path d="M57.7 193l9.4 16.4c8.3 14.5 21.9 25.2 38 29.8L163 255.7c17.2 4.9 29 20.6 29 38.5v39.9c0 11 6.2 21 16 25.9s16 14.9 16 25.9v39c0 15.6 14.9 26.9 29.9 22.6c16.1-4.6 28.6-17.5 32.7-33.8l2.8-11.2c4.2-16.9 15.2-31.4 30.3-40l8.1-4.6c15-8.5 24.2-24.5 24.2-41.7v-8.3c0-12.7-5.1-24.9-14.1-33.9l-3.9-3.9c-9-9-21.2-14.1-33.9-14.1H257c-11.1 0-22.1-2.9-31.8-8.4l-34.5-19.7c-4.3-2.5-7.6-6.5-9.2-11.2c-3.2-9.6 1.1-20 10.2-24.5l5.9-3c6.6-3.3 14.3-3.9 21.3-1.5l23.2 7.7c8.2 2.7 17.2-.4 21.9-7.5c4.7-7 4.2-16.3-1.2-22.8l-13.6-16.3c-10-12-9.9-29.5 .3-41.3l15.7-18.3c8.8-10.3 10.2-25 3.5-36.7l-2.4-4.2c-3.5-.2-6.9-.3-10.4-.3C163.1 48 84.4 108.9 57.7 193zM464 256c0-36.8-9.6-71.4-26.4-101.5L412 164.8c-15.7 6.3-23.8 23.8-18.5 39.8l16.9 50.7c3.5 10.4 12 18.3 22.6 20.9l29.1 7.3c1.2-9 1.8-18.2 1.8-27.5zm48 0c0 141.4-114.6 256-256 256S0 397.4 0 256S114.6 0 256 0S512 114.6 512 256z" /></svg> {school}, {pts[i]}</span>
            </div>
        ))
    }

    const copyLink = async (text) => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(text);
        } else {
            document.execCommand('copy', true, text);
        }
        toast.info("Copied to Clipboard!")
    }

    const renderAdditionalInfos = () => {
        return (
            <>
                {canDisplayInfo("Provider Name") && session['Provider Name'].map((provider, i) => (
                    <div className="session-item-tag pink-tag" key={i}>
                        <span>{provider}</span>
                    </div>
                ))}

                {(canDisplayInfo("Grade(s)") && session['Grade(s)'] && session['Grade(s)'].length) && session['Grade(s)'].map((grade, i) => (
                    <div className="session-item-tag green-tag" key={i}>
                        <span>{grade}</span>
                    </div>
                ))}

                {(canDisplayInfo("School Name") && session['School Name Text'] && session['School Name Text'].length) && session['School Name Text'].map((school, i) => (
                    <div className="session-item-tag blue-tag" key={i}>
                        <span><i className="fa fa-wrench"></i> {school}</span>
                    </div>
                ))}

                {(canDisplayInfo("Teacher Name") && session['Teacher Name'] && session['Teacher Name'].length) && session['Teacher Name'].map((teacher, i) => (
                    <div className="session-item-tag green-tag" key={i}>
                        <span><i className="fa fa-graduation-cap"></i> {teacher}</span>
                    </div>
                ))}

                {(canDisplayInfo("Primary Subject") && session['Primary Subject Text']) && session['Primary Subject Text'].map((subject, i) => (
                    <div className="session-item-tag green-tag" key={i}>
                        <span>{subject}</span>
                    </div>
                ))}

                {(canDisplayInfo("School Location") && session["School Community"]) && schoolLocation()}

                {(canDisplayInfo("School Lead") && session['School Lead Text']) && (
                    <div className="session-item-tag darkred-tag">
                        <span><i className="fa fa-wrench"></i> {session['School Lead Text']}</span>
                    </div>
                )}

                {
                    (canDisplayInfo("Presenters") && session['Presenters'] && session['Presenters'].length) && session['Presenter Name'].map((presenter, i) => (
                        <div className="session-item-tag brown-tag" key={i}>
                            <span>{presenter}</span>
                        </div>
                    ))
                }

                {(canDisplayInfo("Session Host") && session['Session Host Text']) && session['Session Host Text'].map((host, i) => (
                    <div className="session-item-tag green-tag" key={i}>
                        <span><i className="fa fa-life-ring"></i> {host}</span>
                    </div>
                ))}

                {(canDisplayInfo("Provider Internal ID") && session["Provider Internal ID"]) && (
                    <div className="session-item-tag yellow-tag">
                        <span>{session["Provider Internal ID"]}</span>
                    </div>
                )}

                {(canDisplayInfo("Resources Ship Date") && session["Resources Ship Date"] && session["Resources Ship Date"].length) && (
                    <div className="session-item-tag pink-tag">
                        <span>{t("supplied-shipped", { date: getNiceFormatDateString(session["Resources Ship Date"][0], appLocale) })}</span>
                    </div>
                )}

                {(canDisplayInfo("Cancelled with Payment") && session.Status === "Cancelled with Payment") && (
                    <div className="session-item-tag red-tag">
                        <span>Cancelled with Payment</span>
                    </div>
                )}
            </>
        )
    }

    const renderActionMenu = () => {
        if (!actionMenu || actionMenu.length === 0) return ;

        return (
            <UncontrolledDropdown>
                <DropdownToggle color="secondary">
                    <i className="fa fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu>
                    {canDisplayMenu("join-session") && (
                        <DropdownItem href="#" onClick={e => window.open(session["WebEx/Zoom Link"], "_blank")} disabled={!session["WebEx/Zoom Link"] || !session["WebEx/Zoom Link"].startsWith("http")}>
                            {t("join-session")}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("copy-link") && (
                        <DropdownItem href="#" onClick={e => copyLink(session["WebEx/Zoom Link"])}>{t("copy-session-link")}</DropdownItem>
                    )}

                    {canDisplayMenu("track-supplies") && (
                        <DropdownItem
                            href="#"
                            onClick={e => window.open(session["Resource Tracking URL"][0], "_blank")}
                            disabled={!(session["Resource Tracking URL"] && session["Resource Tracking URL"].length && session["Resource Tracking URL"][0] && session["Resource Tracking URL"][0].startsWith("http"))}
                        >{t("track-supplies")}</DropdownItem>
                    )}

                    {canDisplayMenu("call-school-lead") && (
                        <DropdownItem href="#" onClick={e => gotoURL(e, `tel:${session['School Lead Phone']}`)} disabled={!session["Bridge Address / SIP URI"] || session["Bridge Address / SIP URI"].startsWith("http")}>
                            {i18n.t("call-sbd", { somebody: session['School Lead Text'] })}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("email-school-lead") && (
                        <DropdownItem href="#" onClick={e => gotoURL(e, `mailto:${session['School Lead Email']}`)}>
                            {i18n.t("email-sbd", { somebody: session['School Lead Text'] })}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("call-session-host") && (
                        <DropdownItem href="#" onClick={e => gotoURL(e, `tel:${session['Host Phone']}`)} disabled={!session["Bridge Address / SIP URI"] || session["Bridge Address / SIP URI"].startsWith("http")}>
                            {i18n.t("call-sbd", { somebody: session['Session Host Text'] })}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("email-session-host") && (
                        <DropdownItem href="#" onClick={e => gotoURL(e, `mailto:${session['Session Host Email']}`)}>
                            {i18n.t("email-sbd", { somebody: session['Session Host Text'] })}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("call-teacher") && (
                        <DropdownItem href="#" onClick={e => gotoURL(e, `tel:${session['Teacher Phone']}`)} disabled={!session["Bridge Address / SIP URI"] || session["Bridge Address / SIP URI"].startsWith("http")}>
                            {i18n.t("call-sbd", { somebody: session['Teacher Name'] })}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("email-teacher") && (
                        <DropdownItem href="#" onClick={e => gotoURL(e, `mailto:${session['Teacher Email']}`)}>
                            {i18n.t("email-sbd", { somebody: session['Teacher Name'] })}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("call-provider") && (
                        <DropdownItem href="#" onClick={e => gotoURL(e, `tel:${session['Provider Phone']}`)}>
                            {i18n.t("call-sbd", { somebody: session['Provider Name'] })}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("email-provider") && (
                        <DropdownItem href="#" onClick={e => gotoURL(e, `mailto:${session['Provider Email']}`)}>
                            {i18n.t("email-sbd", { somebody: session['Provider Name'] })}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("dial-bridge-address") && (
                        <DropdownItem href="#" onClick={e => gotoURL(e, `webexteams://meet?sip=${session['Bridge Address / SIP URI']}`)} disabled={!(session['Bridge Address / SIP URI'] && session['Bridge Address / SIP URI'].search('@') >= 0)}>
                            {i18n.t("dial-sbd", { somebody: session['Bridge Address / SIP URI'] })}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("reassign-session-lead") && (
                        <DropdownItem href="#" onClick={e => reassignSessionLead(e, session)} disabled={!reassignSessionLead}>
                            {t("reassign-session-lead")}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("request-support") && (
                        <DropdownItem href="#" onClick={e => requestSupport(e, session)} disabled={!requestSupport}>
                            {t("request-support")}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("open-detail") && (
                        <DropdownItem href="#" onClick={e => openSessionDetail(e, session['Session Title'] ? session['Session Title'][0] : null)} disabled={!openSessionDetail}>
                            {t("open-session-detail")}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("internal-id") && (
                        <DropdownItem href="#" onClick={e => openInternalIDModal(session)} disabled={!openInternalIDModal}>
                            {session["Provider Internal ID"] ? t("edit-internal-id") : t("add-internal-id")}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("assign-presenter") && (
                        <DropdownItem href="#" onClick={e => assignPresenter(session)} disabled={!assignPresenter || !presentersForProvider || !presentersForProvider.length}>
                            {t("assign-presenter")}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("edit-request") && (
                        <DropdownItem href="#" onClick={e => editRequest(session)} disabled={!editRequest}>
                            {t("edit-request")}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("cancel-request") && (
                        <DropdownItem href="#" onClick={e => cancelRequest(session)} disabled={!cancelRequest}>
                            {t("cancel-request")}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("book-confirm") && (
                        <DropdownItem href="#" onClick={e => bookConfirm(e, session)} disabled={!bookConfirm}>
                            {t("book-confirm")}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("status-unfulfilled") && (
                        <DropdownItem href="#" onClick={e => changeStatus(e, session, 'Unfulfilled')} disabled={!changeStatus}>
                            {t("set-status-unfulfilled")}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("rate-session") && (
                        <DropdownItem href="#" onClick={e => rateSession(e, session)} disabled={!rateSession}>
                            {t("rate-session")}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("request-payment") && (
                        <DropdownItem href="#" onClick={e => requestPayment(e, session)} disabled={!requestPayment}>
                            {t("request-payment")}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("generate-invoice") && (
                        <DropdownItem href="#" onClick={e => generateInvoice(e, session)} disabled={!generateInvoice}>
                            {t("generate-invoice")}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("status-completed") && (
                        <DropdownItem href="#" onClick={e => changeStatus(e, session, 'Completed')} disabled={!changeStatus}>
                            {t("set-status-completed")}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("status-cancelledwithoutpayment") && (
                        <DropdownItem href="#" onClick={e => changeStatus(e, session, 'Cancelled without Payment')} disabled={!changeStatus}>
                            {t("set-status-cancelledwithoutpayment")}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("status-cancelledpayment") && (
                        <DropdownItem href="#" onClick={e => changeStatus(e, session, 'Cancelled with Payment')} disabled={!changeStatus}>
                            {t("set-status-cancelledpayment")}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("session-result") && (
                        <DropdownItem href="#" onClick={e => openSessionResult(e, session)} disabled={!openSessionResult}>
                            {t("open-session-result")}
                        </DropdownItem>
                    )}

                    {canDisplayMenu("provider-feedback") && (
                        <DropdownItem href="#" onClick={e => openProviderFeedbackModal(e, session)} disabled={!openProviderFeedbackModal || (session["Provider Rating"] ? true : false)}>
                            {t("leave-feedback")}
                        </DropdownItem>
                    )}
                </DropdownMenu>
            </UncontrolledDropdown>
        )
    }

    return (
        <div className="session-item">
            <div style={{ flex: 1 }}>
                <div>
                    <span style={{ fontSize: 16, color: 'black' }}>{session['Custom Session Title'] ? session['Custom Session Title'] : session['Session Title Text']}</span>
                </div>

                {canDisplayInfo("Session Description") && (<div><span style={{ fontSize: 12, color: 'gray' }}>{session['Session Description']}</span></div>)}

                <div className="session-item-tags">{renderAdditionalInfos()}</div>

                {(canDisplayInfo("Teacher Rating") && session["Rating"]) && (
                    <div style={{ display: 'inline-block' }}>
                        <div style={{ float: 'left', marginRight: 5 }}>
                            <ReactStars
                                value={session["Rating"]}
                                count={5}
                                edit={false}
                                size={16}
                                isHalf={false}
                                emptyIcon={<i className="fa fa-star"></i>}
                                fullIcon={<i className="fa fa-star"></i>}
                                activeColor="#ffd700"
                            />
                        </div>
                        <span>{session.Feedback}</span>
                    </div>
                )}
                <div className="">
                    {
                        (canDisplayInfo("Session Accept & Reject/Session Time") && userType === 'Provider' && userInfo['Autobook']) ? (
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div>
                                    <div>
                                        <span>Option 1: {getNiceFormatDateTimeString(new Date(session['Session Start Date/Time']), appLocale)}</span>&nbsp;&nbsp;&nbsp;
                                        <button
                                            className="btn_flat"
                                            onClick={(e) => gotoBooking(e, session['Session Start Date/Time'], session['Alternative Date/Time'], `Option 1: ${getNiceFormatDateTimeString(new Date(session['Session Start Date/Time']), appLocale)}`)}
                                        >[accept and confirm]</button>
                                    </div>
                                    {
                                        session['Alternative Date/Time'] ? (
                                            <div>
                                                <span>Option 2: {getNiceFormatDateTimeString(new Date(session['Alternative Date/Time']), appLocale)}</span>&nbsp;&nbsp;&nbsp;
                                                <button
                                                    className="btn_flat"
                                                    onClick={(e) => gotoBooking(e, session['Alternative Date/Time'], session['Session Start Date/Time'], `Option 2: ${getNiceFormatDateTimeString(new Date(session['Alternative Date/Time']), appLocale)}`)}
                                                >[accept and confirm]</button>
                                            </div>
                                        ) : null
                                    }
                                </div>
                                <div style={{ flex: 1 }} />
                                <div>
                                    <button
                                        className="btn_flat"
                                        style={{ color: 'darkred' }}
                                        onClick={() => setRejectModal(true)}
                                    >[Reject]</button>
                                </div>
                            </div>
                        ) : (
                            session['Session Start Date/Time'] ? <span style={{ color: 'gray' }}>
                                <ReactTimeAgo date={new Date(session['Session Start Date/Time'])} locale={appLocale} />, {appLocale === 'fr' ? "le" : "on"} {getNiceFormatDateTimeString(
                                    new Date(session['Session Start Date/Time']),
                                    appLocale
                                )}
                            </span> : null
                        )
                    }
                </div>
            </div>

            {renderActionMenu()}

            {/* {children} */}
            {
                canDisplayInfo("Favorite") && (
                    <div style={{ marginLeft: 10 }}>
                        <button
                            type="button"
                            className='btn btn-secondary'
                            style={{ width: 66 }}
                            onClick={() => toggleFavorite()}
                            disabled={favoriteState === "loading"}
                        >{favoriteState === "loading" ? (
                            <Loading size={14} />
                        ) : (
                            <i className={favoriteState === 'unfavorite' ? 'fa fa-heart-o' : 'fa fa-heart favorite'} />
                        )}
                        </button>
                    </div>
                )
            }

            {
                (userType === 'Provider' && rejectModal) && (
                    <SessionReject
                        sessionId={session.id}
                        onToggle={() => setRejectModal(false)}
                        onPost={() => { if (onRefresh) onRefresh(); }}
                    />
                )
            }
        </div>
    );
}

export default SessionInfo;
