import {
    SET_APP_USERINFO,
    SET_APP_USERTYPE,
    SET_PASS_SESSION_PAGE_TYPE,
    SET_APP_LOCALE,
    SET_SUBJECT_MAP,
    SET_TOTAL_SUBJECTS,
    SET_MY_FAVORITES,
    ADD_FAVORITE,
    REMOVE_FAVORITE,
    SET_PRESENTER_UPCOMING,
    SET_PRESENTER_PAST,
    SET_PRESENTER_LOADING,

    SET_PRESENTERS_FOR_PROVIDER
} from './types';

const initialState = {
    userInfo: null,
    userType: null,
    passSessionPageType: 'rate',
    appLocale: 'en',
    totalSubjects: [],
    subjectMap: {},
    myFavorites: [],
    presenterLoading: true,
    presenterUpcoming: [],
    presenterPast: [],
    presentersForProvider: []
}

const appInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_APP_USERINFO:
            return {
                ...state,
                userInfo: action.payload
            }

        case SET_APP_USERTYPE:
            return {
                ...state,
                userType: action.payload
            }

        case SET_PASS_SESSION_PAGE_TYPE:
            return {
                ...state,
                passSessionPageType: action.payload
            }

        case SET_APP_LOCALE:
            return {
                ...state,
                appLocale: action.payload
            }

        case SET_SUBJECT_MAP:
            return {
                ...state,
                subjectMap: action.payload
            }

        case SET_TOTAL_SUBJECTS:
            return {
                ...state,
                totalSubjects: action.payload
            }

        case SET_MY_FAVORITES:
            return {
                ...state,
                myFavorites: action.payload
            }

        case ADD_FAVORITE:
            const favorites1 = [
                ...state.myFavorites,
                action.payload
            ]
            return {
                ...state,
                myFavorites: favorites1
            }

        case SET_PRESENTER_LOADING:
            return {
                ...state,
                presenterLoading: action.payload
            }

        case SET_PRESENTER_UPCOMING:
            return {
                ...state,
                presenterUpcoming: action.payload
            }

        case SET_PRESENTER_PAST:
            return {
                ...state,
                presenterPast: action.payload
            }

        case REMOVE_FAVORITE:
            const favorites2 = state.myFavorites.filter((v) => v !== action.payload)
            return {
                ...state,
                myFavorites: favorites2
            }

        case SET_PRESENTERS_FOR_PROVIDER:
            const presenters = [...action.payload];
            presenters.sort((a, b) => a.Name.localeCompare(b.Name));
            return {
                ...state,
                presentersForProvider: presenters
            }

        default:
            return state;
    }
}

export default appInfoReducer;