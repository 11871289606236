import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/scss/argon-design-system-react.scss?v1.1.0";
import "./assets/scss/custom.scss";

import Login from './pages/Login';
import Main from './pages/Main';
import GlobalRate from './pages/GlobalRate/teacher';
import GlobalProviderRate from './pages/GlobalRate/provider';
import GlobalTeamRate from './pages/GlobalRate/team';
import Unsubscribe from './pages/Unsubscribe';
import ExternalAccept from './pages/ExternalAccept';
import RegisterPresenter from './pages/RegisterPresenter';
import RegisterTeacher from './pages/RegisterTeacher';
import { ToastContainer } from 'react-toastify';
import { getSession } from './utils/session';
import { CONNECTEDNORTH_SIGNINFO, CONNECTEDNORTH_SIGNTYPE } from './config';
import {
  setAppUserInfo,
  setAppUserType,
  setAppLocale,
  setSubjectMap,
  setTotalSubjects,
  setMyFavorites
} from './redux/actions';
import { getTeacherByEmail } from './airtables/Teachers';
import { getTeamByEmail } from './airtables/Team';
import { getProviderByEmail } from './airtables/Providers';
import { getPresenterByEmail } from './airtables/Presenters';
import { getAllSubjects } from './airtables/Subjects';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import './firebase';

const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);

const App = () => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { userInfo } = useSelector(state => state.appInfo);

  useEffect(() => {
    const init = async () => {
      var ui = await getSession(CONNECTEDNORTH_SIGNINFO);
      var ut = await getSession(CONNECTEDNORTH_SIGNTYPE);

      if (ut && ui?.id) {
        switch (ut) {
          case "Teacher":
            ui = await getTeacherByEmail(ui['Email']);
            break;
          case "Team":
            ui = await getTeamByEmail(ui['Email']);
            break;
          case "Provider":
            ui = await getProviderByEmail(ui['Email']);
            break;
          case "Presenter":
            ui = await getPresenterByEmail(ui['Email']);
            break;
          default:
            break;
        }
      }

      dispatch(setAppUserInfo(ui));
      dispatch(setAppUserType(ut));
      if (ut === 'Teacher') {
        dispatch(setMyFavorites(ui['Favourites'] ? ui['Favourites'] : []));
      }

      const subjectList = await getAllSubjects();
      dispatch(setTotalSubjects(subjectList));

      var subjectMap = {};
      subjectList.forEach((subject, i) => {
        subjectMap[subject.id] = subject;
      })
      dispatch(setSubjectMap(subjectMap));
      setLoading(false);
    }

    init();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // dispatch(setAppLocale("fr"));
    // i18n.changeLanguage("fr");
    // return;
    if (userInfo && userInfo.hasOwnProperty("Preferred Language") && userInfo["Preferred Language"] === 'French') {
      dispatch(setAppLocale("fr"));
      i18n.changeLanguage("fr");
    } else {
      dispatch(setAppLocale("en"));
      i18n.changeLanguage("en");
    }
  }, [userInfo])  // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return null;

  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Switch>
          <Redirect exact path="/" to="/cn" />
          <Route path="/cn" render={props => <Main {...props} />} />
          <Route path="/rate/:teacherId/:sessionId/:rating" render={props => <GlobalRate {...props} />} />
          <Route path="/prorate/:providerId/:sessionId/:initialRating" render={props => <GlobalProviderRate {...props} />} />
          <Route path="/teamrate/:teamId/:sessionId/:rating" render={props => <GlobalTeamRate {...props} />} />
          <Route path="/accept/:providerId/:sessionId/:option" render={props => <ExternalAccept {...props} />} />
          <Route path="/rate/:teacherId/unsubscribe" render={props => <Unsubscribe {...props} />} />
          <Route path="/login" exact render={props => <Login {...props} />} />
          <Route path="/register-presenter/:username/:providerId" exact render={props => <RegisterPresenter {...props} />} />
          <Route path="/register-teacher/:username/:clusterId" exact render={props => <RegisterTeacher {...props} />} />
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </Suspense>
  )
}

export default App;