export const distinctSubjectsFromCLinks = (links, subjectsMap) => {
    const nlinks = [...links];
    const subs = [];
    const sMap = {};

    if (!nlinks || nlinks.length === 0) return subs;
    nlinks.forEach(link => {
        const subjects = link["Subject"];
        if (subjects && subjects.length) {
            subjects.forEach(sub => {
                if (!sMap[sub]) {
                    sMap[sub] = true;
                    subs.push(subjectsMap[sub]);
                }
            })
        }
    })
    subs.sort((a, b) => {
        return a["Subject"] >= b["Subject"];
    })
    return subs;
}

export const distinctGradesFromCLinks = (links) => {
    const nlinks = [...links];
    const grades = [];
    var gMap = {};
    if (!nlinks || nlinks.length === 0) return grades;
    nlinks.forEach(link => {
        if (!gMap[link["Grade(s)"][0]]) {
            grades.push(link["Grade(s)"][0]);
            gMap[link["Grade(s)"][0]] = true;
        }
    })
    grades.sort((a, b) => {
        if (a === 'K') return -1;
        if (b === 'K') return 1;
        return a > b;
    })
    return grades;
}

export const distinctRegionsFromCLinks = (links, regionsMap) => {
    const nlinks = [...links];
    const regs = [];
    // let prevId = null;
    if (!nlinks || nlinks.length === 0) return regs;

    const regionIds = [];
    nlinks.forEach(link => {
        if (link && link.Region) {
            link.Region.forEach(reg => {
                if (regionIds.indexOf(reg) < 0) {
                    regionIds.push(reg);
                    regs.push(regionsMap[reg]);
                }
            })
        }
    })
    regs.sort((a, b) => {
        return a["Name"] >= b["Name"];
    })
    return regs;
}

export const isExistSubject = (subject, ss) => {
    let is = false;
    if (!ss || ss.length === 0) return is;
    ss.forEach(s => {
        if (s === subject) {
            is = true
        }
    })

    return is;
}

export const getLinkedSessionString = (links) => {
    if (!links) {
        return `No Sessions aligned to this Curriculum Expectation`;
    }

    if (links === 1) {
        return `1 Session aligned to this Curriculum Expectation`;
    }

    return `${links} Sessions aligned to this Curriculum Expectation`;
}

export const getCoreCompetenciesString = (competencies) => {
    if (!competencies || competencies.length === 0) return '';
    var sResult = ""
    competencies.forEach((c, i) => {
        sResult += (i > 0 ? ", " : "") + c;
    })

    return sResult;
}

export const getStrandTopicLabel = (regionMap, regionId) => {
    if (!regionId || !regionMap[regionId]) {
        return {
            strand: "Strand",
            topic: "Topic"
        };
    }

    const regionName = regionMap[regionId].Name;

    if (regionName === "BC" || regionName === "YT") {
        return {
            strand: "Area of Learning",
            topic: "Content"
        }
    } else if (regionName === "SK") {
        return {
            strand: "Indicator",
            topic: "Outcome"
        }
    } else {
        return {
            strand: "Strand",
            topic: "Topic"
        };
    }
}

export const isEqualRegion = (link1, link2) => {
    if (link1["Region Text"] === link2["Region Text"]) return true;
    return false
}

export const isEqualSubject = (link1, link2) => {
    if (link1["Subject Text"] === link2["Subject Text"]) return true;
    return false
}

export const isEqualGrade = (link1, link2) => {
    if (link1["Grade(s)"][0] === link2["Grade(s)"][0]) return true;
    return false
}