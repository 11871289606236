import base from './config';
const tableName = "Teachers"

export const getTeacherByEmail = (email) => {
    const em = email.toUpperCase();
    return new Promise((resolve, reject) => {
        base(tableName).select({
            filterByFormula: `OR(FIND("${em}", UPPER({Email})), FIND("${em}", UPPER({Secondary Email})))`,
            maxRecords: 1
        }).firstPage().then(res => {
            if (res.length > 0) {
                resolve({
                    id: res[0].id,
                    ...res[0].fields
                })
            } else {
                resolve(null)
            }
        }).catch(error => {
            reject(error.toString());
        })
    });
}

export const getTeacherById = (id) => {
    return new Promise((resolve, reject) => {
        base(tableName).find(id).then(record => {
            if (record && record._rawJson){
                resolve(record._rawJson.fields)
            } else {
                resolve(null)
            }
        }).catch(error => {
            reject(error.toString())
        })
    })
}

export const getAllTeachers = (leader, team) => {
    return new Promise((resolve, reject) => {
        var teachers = [];
        const filterByFormula = leader ? `AND({School Lead} = '${team}', FIND('${leader}', {School Lead}), {Teacher Name} != '')` : `{Teacher Name} != ''`;
        base(tableName).select({
            filterByFormula,
            sort: [
                { field: 'Teacher Name', direction: 'asc' }
            ]
        }).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                var teacher = {
                    id: record.id,
                    ...record.fields
                }
                teachers.push(teacher);
            })
            fetchNextPage()
        }).then(res => {
            resolve(teachers)
        }).catch(error => {
            resolve([])
        })
    })
}

export const getTeachersByNames = (names, ids) => {
    const nameListStr = names.reduce((result, name) => { return result += name + ","; }, "");
    var teachers = [];
    return new Promise((resolve, reject) => {
        const filterByFormula = `Find(CONCATENATE({Teacher Name}, ","), "${nameListStr}")`;
        base(tableName).select({
            filterByFormula,
            sort: [
                { field: 'Teacher Name', direction: 'asc' }
            ]
        }).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                var teacher = {
                    id: record.id,
                    ...record.fields
                }
                teachers.push(teacher);
            })
            fetchNextPage()
        }).then(res => {
            resolve(teachers.filter(teacher => ids.indexOf(teacher.id) >= 0))
        }).catch(error => {
            resolve([])
        })
    })
}

export const disableRatingRequest = (id, val) => {
    return new Promise((resolve, reject) => {
        base(tableName).update([{
            id, fields: {
                "Disable Rating Requests": val
            }
        }]).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export const updateTeacher = (id, param) => {
    return new Promise((resolve, reject) => {
        base(tableName).update([{
            id, fields: param
        }]).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export const createTeacher = (param) => {
    return new Promise((resolve, reject) => {
        base(tableName).create([{
            fields: param
        }]).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}