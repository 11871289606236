import React from 'react';
import { useSelector } from 'react-redux';
import PresenterUpcomingScreen from './presenter';
import UpcomingScreen from './main';

const Screen = () => {
    const { userType } = useSelector(state => state.appInfo);

    if (userType === 'Presenter') return <PresenterUpcomingScreen />
    return <UpcomingScreen />
}

export default Screen;