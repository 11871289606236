import React, { useEffect, useRef, useState } from 'react';
import { LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Row, UncontrolledDropdown } from 'reactstrap';
import { updateProvider } from '../../airtables/Providers';
import { updatePresenter, createPresenter, removePresenter } from '../../airtables/Presenters';
import { setAppUserInfo } from '../../redux/actions';
import { setSession } from '../../utils/session';
import { CONNECTEDNORTH_SIGNINFO } from '../../config';
import { useTranslation } from 'react-i18next';
import MapWithAKmlLayer from '../../components/MapWithAKmlLayer';
import './styles.scss';
import OverlayLoading from '../../components/OverlayLoading';
import PresenterFormModal from '../modals/PresenterForm';
import { setPresentersForProvider } from '../../redux/actions';
import uploadFile from '../../airtables/uploadfile';
import * as EmailValidator from 'email-validator';

const Screen = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { userInfo, presentersForProvider } = useSelector(state => state.appInfo);
    const fileInputRef = useRef();

    const [updating, setUpdating] = useState(false);
    const [edit, setEdit] = useState(false);
    const [name, setName] = useState("");
    const [pronouns, setPronouns] = useState("")
    const [email, setEmail] = useState("");
    const [secondaryEmail, setSecondaryEmail] = useState("");
    const [bio, setBio] = useState("");
    const [logo, setLogo] = useState("");
    const [logoFile, setLogoFile] = useState();
    const [instructions, setInstructions] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("");
    const [timezone, setTimezone] = useState("");
    const [recordingAllowed, setRecordingAllowed] = useState("");
    const [receiveRequestNotifications, setReceiveRequestNotifications] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [processingTitle, setProcessingTitle] = useState("");
    const [activePresenter, setActivePresenter] = useState(null);
    const [activePresenterNo, setActivePresenterNo] = useState();
    const [showPresenterModal, setShowPresenterModal] = useState(false);

    useEffect(() => {
        setName(userInfo['Contact Name']);

        setPronouns(userInfo["Pronouns"]);
        setEmail(userInfo['Email']);
        setPhoneNumber(userInfo['Phone']);
        setTimezone(userInfo["Timezone"]);
        setBio(userInfo["Introduction/Bio"]);
        setRecordingAllowed(userInfo["Recording Allowed"]);
        setReceiveRequestNotifications(userInfo["Autobook"]);
        setInstructions(userInfo["Special Instructions"] ? userInfo["Special Instructions"] : '')
        if (userInfo["Logo/Photo"] && userInfo["Logo/Photo"].length) {
            if (userInfo["Logo/Photo"][0].thumbnails) {
                setLogo(userInfo["Logo/Photo"][0].thumbnails.large.url)
            } else {
                setLogo(userInfo["Logo/Photo"][0].url)
            }
        }

        if (userInfo["Individual/Org/Post-Sec."] !== "Individual") {
            setSecondaryEmail(userInfo["Secondary Email"]);
        }
    }, [userInfo])

    const cancelUpdate = () => {
        setName(userInfo['Contact Name']);

        setPronouns(userInfo["Pronouns"]);
        setEmail(userInfo['Email']);
        setBio(userInfo["Introduction/Bio"])
        setPhoneNumber(userInfo['Phone']);
        setTimezone(userInfo["Timezone"]);
        setRecordingAllowed(userInfo["Recording Allowed"]);
        setReceiveRequestNotifications(userInfo["Autobook"]);
        setInstructions(userInfo["Special Instructions"] ? userInfo["Special Instructions"] : '')

        if (userInfo["Logo/Photo"] && userInfo["Logo/Photo"].length) {
            setLogo(userInfo["Logo/Photo"][0].thumbnails.large.url)
        } else {
            setLogo("");
        }

        if (userInfo["Individual/Org/Post-Sec."] !== "Individual") {
            setSecondaryEmail(userInfo["Secondary Email"]);
        }
        setLogoFile(null);
        setEdit(false)
    }

    const updateInfo = async () => {
        if (!EmailValidator.validate(email)) {
            alert("Invalid Email address");
            return
        }

        if (userInfo["Individual/Org/Post-Sec."] !== "Individual" && !EmailValidator.validate(secondaryEmail)) {
            alert("Invalid Secondary Email address");
            return
        }

        setUpdating(true);
        try {
            let params = {
                "Contact Name": name,
                "Email": email.trim(),
                "Phone": phoneNumber,
                "Timezone": timezone,
                "Introduction/Bio": bio,
                "Recording Allowed": recordingAllowed,
                "Pronouns": pronouns,
                "Special Instructions": instructions,
                "Autobook": receiveRequestNotifications
            }

            if (logoFile) {
                const uRes = await uploadFile(logoFile);
                params["Logo/Photo"] = [{ url: uRes }];
                setLogoFile(null);
            }

            if (userInfo["Individual/Org/Post-Sec."] !== "Individual") {
                params["Secondary Email"] = secondaryEmail;
            }

            const result = await updateProvider(userInfo.id, params);

            const info = { id: result[0].id, ...result[0].fields };
            await setSession(CONNECTEDNORTH_SIGNINFO, info);
            dispatch(setAppUserInfo(info));
        } catch (error) { }
        setUpdating(false);
        setEdit(false);
    }

    const archivePresenter = async (presenter, no) => {
        setProcessingTitle("Archiving...");
        setProcessing(true);
        await updatePresenter(presenter.id, {
            Archived: true
        });
        let newPs = [...presentersForProvider];
        newPs[no].Archived = true;
        dispatch(setPresentersForProvider(newPs))
        setProcessingTitle("");
        setProcessing(false);
    }

    const editPresenter = async (presenter, no) => {
        setActivePresenter(presenter);
        setActivePresenterNo(no);
        setShowPresenterModal(true)
    }

    const addPresenter = () => {
        setActivePresenter(null);
        setActivePresenterNo(-1);
        setShowPresenterModal(true);
    }

    const updatePresenterFromModal = async (email, name, phone) => {
        setShowPresenterModal(false);

        setProcessingTitle("Archiving...");
        setProcessing(true);
        if (activePresenter) {
            const pInfo = await updatePresenter(activePresenter.id, {
                "Name": name,
                "Email": email,
                "Phone": phone,
            });
            const presenterInfo = { ...pInfo[0].fields, id: pInfo[0].id }
            let newPs = [...presentersForProvider];
            newPs[activePresenterNo] = presenterInfo;
            dispatch(setPresentersForProvider(newPs));
        } else {
            const pInfo = await createPresenter(userInfo.id, email, name, phone);
            const presenterInfo = { ...pInfo[0].fields, id: pInfo[0].id }
            let newPs = [...presentersForProvider, presenterInfo];
            dispatch(setPresentersForProvider(newPs));
        }
        setProcessingTitle("");
        setProcessing(false);

        setActivePresenter(null);
        setActivePresenterNo(-1);
    }

    const deletePresenter = async (presenter, i) => {
        if (window.confirm("Are you sure you want to delete this presenter?")) {
            setProcessingTitle("Deleting...");
            setProcessing(true);
            await removePresenter(presenter.id);

            let newPs = [...presentersForProvider];
            newPs.splice(i, 1);
            dispatch(setPresentersForProvider(newPs));

            setProcessingTitle("");
            setProcessing(false);
        }
    }

    return (
        <div className="main-container">
            {
                userInfo["Unique Schools"] >= 3 && (
                    <div style={{ marginBottom: 20 }}>
                        <span className="page-topic">{userInfo["Unique Schools"]} {t("schools-reached")}</span>
                        <MapWithAKmlLayer recId={userInfo.id} type="provider_schools" />
                    </div>
                )
            }

            <Row>
                <Col>
                    <span className="page-topic">{t("my-info")}</span>
                </Col>
                <Col align="right">
                    {
                        edit ? (
                            <>
                                <Button color="link" size="sm" onClick={() => cancelUpdate()}>{t("cancel")}</Button>
                                <Button
                                    color="primary"
                                    size="sm"
                                    onClick={() => updateInfo()}
                                    disabled={(!name || !email)}
                                >{t("save")}</Button>
                            </>
                        ) : (
                            <Button color="link" size="sm" onClick={() => setEdit(true)}>{t("edit")}</Button>
                        )
                    }
                </Col>
            </Row>
            {updating && <LinearProgress />}
            <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'center' }}>
                <div style={{ padding: 20 }}>
                    {
                        edit ? (
                            <div>
                                {
                                    logo ? (
                                        <img src={logo} style={{ maxWidth: 400, height: 'auto' }} alt="" />
                                    ) : <div className="no-img" />
                                }

                                <input
                                    type="file"
                                    accept="application/image"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={e => {
                                        setLogoFile(e.target.files[0]);
                                        setLogo(URL.createObjectURL(e.target.files[0]));
                                    }}
                                />

                                <div style={{ paddingTop: 10 }}>
                                    <Button size="sm" onClick={() => fileInputRef.current.click()} type="button" color="secondary" >Select Image</Button>
                                </div>
                            </div>
                        ) : (
                            logo ? (
                                <div>
                                    <img src={logo} style={{ maxWidth: 400, height: 'auto' }} alt="" />
                                </div>
                            ) : null
                        )
                    }
                </div>
                <div style={{ maxWidth: 500, minHeight: 500, padding: 20 }} align="left">
                    <FormGroup row>
                        <Label for="name" sm={4}>{t("contact-name")}</Label>
                        <Col sm={8} className="v-center">
                            {
                                edit ? (
                                    <Input
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Contact Name"
                                        defaultValue={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                ) : (
                                    <span>{name}</span>
                                )
                            }
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="name" sm={4}>{t("pronouns")}</Label>
                        <Col sm={8} className="v-center">
                            {
                                edit ? (
                                    <Input
                                        type="text"
                                        name="pronouns"
                                        id="pronouns"
                                        placeholder={t("pronouns")}
                                        defaultValue={pronouns}
                                        onChange={e => setPronouns(e.target.value)}
                                    />
                                ) : (
                                    <span>{pronouns}</span>
                                )
                            }
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="email" sm={4}>{t("email")}</Label>
                        <Col sm={8} className="v-center">
                            {
                                edit ? (
                                    <Input
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                        defaultValue={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                ) : (
                                    <span>{email}</span>
                                )
                            }
                        </Col>
                    </FormGroup>

                    {
                        (userInfo["Individual/Org/Post-Sec."] !== "Individual") && (
                            <>
                                <FormGroup row style={{ marginBottom: 0 }}>
                                    <Label for="secondary-email" sm={4}>{t("secondary-email")}</Label>
                                    <Col sm={8} className="v-center">
                                        {
                                            edit ? (
                                                <Input
                                                    type="email"
                                                    name="secondary-email"
                                                    id="secondary-email"
                                                    placeholder="Email"
                                                    defaultValue={secondaryEmail}
                                                    onChange={e => setSecondaryEmail(e.target.value)}
                                                />
                                            ) : (
                                                <span>{secondaryEmail}</span>
                                            )
                                        }
                                    </Col>
                                </FormGroup>

                                {edit && <span style={{ fontSize: '0.85rem' }}>{t("secondary-email-msg")}</span>}
                            </>
                        )
                    }

                    <FormGroup row style={{ marginTop: 20 }}>
                        <Label for="phone" sm={4}>{t("phone-number")}</Label>
                        <Col sm={8} className="v-center">
                            {
                                edit ? (
                                    <Input
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        placeholder="Phone Number"
                                        defaultValue={phoneNumber}
                                        onChange={e => setPhoneNumber(e.target.value)}
                                    />
                                ) : (
                                    <span>{phoneNumber}</span>
                                )
                            }
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="timezone" sm={4}>{t("timezone")}</Label>
                        <Col sm={8} className="v-center">
                            {
                                edit ? (
                                    <Input
                                        type="select"
                                        name="timezone"
                                        id="timezone"
                                        defaultValue={timezone}
                                        onChange={e => setTimezone(e.target.value)}
                                    >
                                        <option></option>
                                        <option>Canada/Eastern</option>
                                        <option>Canada/Central</option>
                                        <option>Canada/Mountain</option>
                                        <option>Canada/Saskatchewan</option>
                                        <option>Canada/Pacific</option>
                                        <option>Canada/Yukon</option>
                                        <option>US/Alaska</option>
                                        <option>Other</option>
                                    </Input>
                                ) : (
                                    <span>{timezone}</span>
                                )
                            }
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="recordingAllowed" sm={4}>{t("recording-allowed")}</Label>
                        <Col sm={8} className="v-center">
                            {
                                edit ? (
                                    <Input
                                        type="select"
                                        name="recordingAllowed"
                                        id="recordingAllowed"
                                        defaultValue={recordingAllowed}
                                        onChange={e => setRecordingAllowed(e.target.value)}
                                    >
                                        <option></option>
                                        <option>Yes</option>
                                        <option>Ask each time</option>
                                        <option>No</option>
                                    </Input>
                                ) : (
                                    <span>{recordingAllowed}</span>
                                )
                            }
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="recordingAllowed" sm={4}>{t("introduction-bio")}</Label>
                        <Col sm={8} className="v-center">
                            {
                                edit ? (
                                    <Input
                                        type="textarea"
                                        name="introductionBio"
                                        id="introductionBio"
                                        style={{ height: 150 }}
                                        value={bio}
                                        onChange={e => setBio(e.target.value)}
                                    ></Input>
                                ) : (
                                    <span>{bio}</span>
                                )
                            }
                        </Col>
                    </FormGroup>

                    <FormGroup row style={{ marginBottom: 0 }}>
                        <Label for="receiveNotifications" sm={4}>{t("receive-request-notifications")}</Label>
                        <Col sm={8} className="v-center">
                            {
                                edit ? (
                                    <input
                                        id="receiveNotifications"
                                        type="checkbox"
                                        defaultChecked={receiveRequestNotifications}
                                        onChange={(e) => setReceiveRequestNotifications(e.target.checked)}
                                    />
                                ) : (receiveRequestNotifications && (<i className="fa fa-check" />))
                            }
                        </Col>
                    </FormGroup>

                    <span style={{ fontSize: '0.85rem' }}>{t("myinfo-receive-request-notifications-desc")}</span>

                    <FormGroup row style={{ marginTop: '1rem' }}>
                        <Label for="instructions" sm={4}>{t("availability-booking-instructions")}</Label>
                        <Col sm={8} className="v-center">
                            {
                                edit ? (
                                    <Input
                                        type="textarea"
                                        name="instructions"
                                        id="instructions"
                                        style={{ height: 150 }}
                                        value={instructions}
                                        placeholder="Please provide any general notes on availability, for example, only available Tuesday and Thursday, or special booking instructions, such as a booking web form or process"
                                        onChange={e => setInstructions(e.target.value)}
                                    >
                                    </Input>
                                ) : <span>{instructions}</span>
                            }
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="timezone" sm={4}>{t("google-calendar")}</Label>
                        <Col sm={8} className="v-center">
                            {
                                userInfo['Calendar Token'] ? (
                                    <span>✓ {t("connected")} (<a href={`https://research.tigweb.org/connectednorth/provider-availability/disconnect.html?provider=${userInfo.id}`}>{t("disconnect")}</a>)</span>
                                ) : (
                                    <a href={`https://research.tigweb.org/connectednorth/provider-availability/calendarauth.html?provider=${userInfo.id}`}><span>{t("connect-google-calendar")}</span></a>
                                )
                            }
                        </Col>
                    </FormGroup>

                    <span style={{ fontSize: '0.85rem' }}>{t("myinfo-google-calendar-desc")}</span>
                </div>
            </div>

            {
                userInfo["Individual/Org/Post-Sec."] !== 'Individual' && (
                    <div style={{ marginTop: 20 }}>
                        <Row>
                            <Col>
                                <span className="page-topic">Presenters</span>
                            </Col>
                            <Col align="right">
                                <Button color="primary" size="sm" onClick={() => addPresenter()}><i className="fa fa-plus" />&nbsp;Add Presenter</Button>
                            </Col>
                        </Row>
                        <div>
                            {
                                presentersForProvider.map((presenter, i) => (
                                    <div className="presenter-container" key={i}>
                                        <div style={{ flex: 1, display: 'flex' }}>
                                            <div style={{ flex: 1 }}><span>{presenter.Name}{
                                                presenter.Archived ? (<span>(Archived)</span>) : null
                                            }</span></div>
                                            <div style={{ flex: 1 }}><span>{presenter.Email}</span></div>
                                            <div style={{ flex: 1 }}><span>{presenter.Phone}</span></div>
                                        </div>
                                        <UncontrolledDropdown>
                                            <DropdownToggle color="secondary">
                                                <i className="fa fa-ellipsis-v" />
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => editPresenter(presenter, i)}>Edit</DropdownItem>
                                                {
                                                    !presenter.Archived ? (
                                                        <DropdownItem onClick={() => archivePresenter(presenter, i)}>Archive</DropdownItem>
                                                    ) : null
                                                }
                                                {
                                                    (!presenter["Sessions Delivered"]) ? (
                                                        <DropdownItem onClick={() => deletePresenter(presenter, i)}>Delete</DropdownItem>
                                                    ) : null
                                                }
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                ))
                            }
                        </div>
                        <OverlayLoading visible={processing} title={processingTitle} />
                        {
                            showPresenterModal ? (
                                <PresenterFormModal
                                    presenter={activePresenter}
                                    onToggle={() => {
                                        setShowPresenterModal(false);
                                        setActivePresenter(null);
                                        setActivePresenterNo(-1);
                                    }}
                                    onSave={updatePresenterFromModal}
                                />
                            ) : null
                        }
                    </div>
                )
            }
        </div>
    )
}

export default Screen;
