import React, { useEffect, useState } from 'react';
import { LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { updatePresenter } from '../../airtables/Presenters';
import { setAppUserInfo } from '../../redux/actions';
import { setSession } from '../../utils/session';
import { CONNECTEDNORTH_SIGNINFO } from '../../config';
import { useTranslation } from 'react-i18next';

const Screen = () => {
    const [updating, setUpdating] = useState(false);
    const [edit, setEdit] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [pronouns, setPronouns] = useState("")
    const { userInfo } = useSelector(state => state.appInfo);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        setName(userInfo['Name']);
        setEmail(userInfo['Email']);
        setPhoneNumber(userInfo['Phone']);
        setPronouns(userInfo["Pronouns"]);
    }, [userInfo])

    const cancelUpdate = () => {
        setName(userInfo['Name']);
        setEmail(userInfo['Email']);
        setPhoneNumber(userInfo['Phone']);
        setPronouns(userInfo["Pronouns"]);
        setEdit(false)
    }

    const updateInfo = async () => {
        setUpdating(true);
        try {
            const result = await updatePresenter(
                userInfo.id,
                {
                    "Name": name,
                    "Email": email.trim(),
                    "Phone": phoneNumber,
                    "Pronouns": pronouns
                }
            )
            const info = {
                id: result[0].id,
                ...result[0].fields
            };
            await setSession(CONNECTEDNORTH_SIGNINFO, info);
            dispatch(setAppUserInfo(info));
        } catch (error) { }
        setUpdating(false);
        setEdit(false);
    }

    return (
        <div className="main-container">
            <Row>
                <Col>
                    <span className="page-topic">{t("my-info")}</span>
                </Col>
                <Col align="right">
                    {
                        edit ? (
                            <>
                                <Button color="link" size="sm" onClick={() => cancelUpdate()}>{t("cancel")}</Button>
                                <Button
                                    color="primary"
                                    size="sm"
                                    onClick={() => updateInfo()}
                                    disabled={(!name || !email)}
                                >{t("save")}</Button>
                            </>
                        ) : (
                            <Button color="link" size="sm" onClick={() => setEdit(true)}>{t("edit")}</Button>
                        )
                    }
                </Col>
            </Row>
            {updating && <LinearProgress />}
            <div style={{ marginTop: 10 }} align="center">
                <div style={{ maxWidth: 500, minHeight: 500, padding: '0 20px', paddingTop: 40 }} align="left">
                    <FormGroup row>
                        <Label for="name" sm={4}>{t("contact-name")}</Label>
                        <Col sm={8} className="v-center">
                            {
                                edit ? (
                                    <Input
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Contact Name"
                                        defaultValue={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                ) : (
                                    <span>{name}</span>
                                )
                            }
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="name" sm={4}>{t("pronouns")}</Label>
                        <Col sm={8} className="v-center">
                            {
                                edit ? (
                                    <Input
                                        type="text"
                                        name="pronouns"
                                        id="pronouns"
                                        placeholder={t("pronouns")}
                                        defaultValue={pronouns}
                                        onChange={e => setPronouns(e.target.value)}
                                    />
                                ) : (
                                    <span>{pronouns}</span>
                                )
                            }
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="email" sm={4}>{t("email")}</Label>
                        <Col sm={8} className="v-center">
                            {
                                edit ? (
                                    <Input
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                        defaultValue={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                ) : (
                                    <span>{email}</span>
                                )
                            }
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="phone" sm={4}>{t("phone-number")}</Label>
                        <Col sm={8} className="v-center">
                            {
                                edit ? (
                                    <Input
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        placeholder="Phone Number"
                                        defaultValue={phoneNumber}
                                        onChange={e => setPhoneNumber(e.target.value)}
                                    />
                                ) : (
                                    <span>{phoneNumber}</span>
                                )
                            }
                        </Col>
                    </FormGroup>
                </div>
            </div>
        </div>
    )
}

export default Screen;
