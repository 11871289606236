import base from './config';
const tableName = "Other Surveys"

export const submitSurvey = (param) => {
    return new Promise((resolve, reject) => {
        base(tableName).create([{
            "fields": param
        }]).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error)
        })
    })
}