import base from './config';
const tableName = "Communities"

export const getAllCommunities = () => {
    return new Promise((resolve, reject) => {
        const communities = [];
        base(tableName).select(
            {
                filterByFormula: "{# Providers} > 0",
                sort: [
                    { field: 'Name', direction: 'asc' }
                ]
            }
        ).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                    const community = {
                        id: record.id,
                        ...record.fields
                    }
                    communities.push(community);
            })
            fetchNextPage()
        }).then(res => {
            resolve(communities)
        }).catch(error => {
            resolve([])
        })
    })
}