import React from 'react';
import {
    Button,
    Modal,
    FormGroup,
    Input
} from 'reactstrap'
import Loading from '../../components/Loading.js';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { addRequestSupport } from '../../airtables/TechnicalSupport';

export default class RequestSupport extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            session: props.session,
            loading: false,

            'Request Type': [],
            'Description of Support Request': ''
        }

        this.requestTypes = [
            'Session Technical Support',
            'Booking Support',
            'Technical Test'
        ]
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            showModal: nextProps.showModal,
        }
    }

    setFormValue(name, val) {
        this.setState({ [name]: val });
    }

    onSubmit() {
        if (this.state['Request Type'].length === 0 || !this.state['Description of Support Request']) {
            toast.warning('Please input request info.')
            return;
        }

        this.setState({loading: true});
        const param = {
            'Link to Sessions': [this.state.session.id],
            'Request Type': this.state['Request Type'],
            'Description of Support Request': this.state['Description of Support Request']
        }

        addRequestSupport(param).then(res => {
            this.setState({loading: false});
            toast.success("Requested successfully!")
            this.props.onToggle();
        }).catch(error => {
            this.setState({loading: false});
            toast.error(error.toString())
        })
    }

    render() {

        return (
            <Modal
                className="modal-dialog-centered"
                isOpen={this.state.showModal}
                toggle={() => this.props.onToggle()}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="modal-title-default">Request Support</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.props.onToggle()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="">
                        <FormGroup>
                            <label>Request Type</label>
                            <Select
                                className="form-style"
                                isMulti={true}
                                value={this.state['Request Type'].map(type => ({ value: type, label: type }))}
                                placeholder="Request Type"
                                onChange={(selectedOptions) => this.setFormValue('Request Type', selectedOptions.map(option => option.value))}
                                options={this.requestTypes.map(type => ({ value: type, label: type }))}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label>Description of Support Request</label>
                            <Input
                                defaultValue={this.state.Notes}
                                placeholder="Description of Support Request"
                                rows="3"
                                onChange={(e) => this.setFormValue('Description of Support Request', e.target.value)}
                                type="textarea"
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button
                        className="my-4"
                        color="primary"
                        type="button"
                        onClick={() => this.onSubmit()}
                    >
                        {this.state.loading ? (<Loading size={14} />) : (null)}Submit
                    </Button>
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.props.onToggle()}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        )
    }
}