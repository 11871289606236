import React from 'react';

const CustomFile = ({ attachment, onRemove, onDownload }) => {
    const { file, thumbs, uploaded } = attachment;

    const isImage = (type) => {
        if (!type) return false;
        if (type.indexOf("image") === 0) return true;
        return false;
    }

    const { name, type } = file;

    const renderContent = () => {
        if (isImage(type)) {
            return <img src={file.url ? file.url : URL.createObjectURL(file)} alt="" />
        }

        if (thumbs) {
            return <img src={thumbs.url} alt="" />
        }

        return <span>{name}</span>;
    }

    return (
        <div className="file-view">
            <div className="file-view-container">
                {renderContent()}
            </div>

            <div className="btn-remove" onClick={() => onRemove()}><i className="fa fa-trash-o"></i></div>
            {
                uploaded && (<div className="btn-download" onClick={() => onDownload()}><i className="fa fa-download"></i></div>)
            }
        </div>
    )
}

export default CustomFile;