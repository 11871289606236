import React, { useEffect, useState } from 'react';
import './styles.scss';

import { getProviderById } from '../../airtables/Providers'
import { getProviderSessions, selectProviderSessionById } from '../../airtables/ProviderSessions';
import { getFiresideChatByProvider } from '../../airtables/FiresideChats';
import { getC2LById } from '../../airtables/C2L';

import { LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getValidUrl } from '../../utils/url';
import VideoPlayer from '../../components/VideoPlayer'

const Screen = (props) => {
    const { match: { params: { providerId } } } = props;
    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [provider, setProvider] = useState();
    const [sessions, setSessions] = useState([]);
    const [error, setError] = useState();
    const [firesideChats, setFiresideChats] = useState([]);
    const [c2l, setC2L] = useState();

    const [featuredVideoSampleSession, setFeaturedVideoSampleSession] = useState(null);
    const [mainContainerWidth, setMainConainerWidth] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            resizeListener()
        })

        window.addEventListener('resize', resizeListener)
        return () => {
            window.removeEventListener('resize', resizeListener)
            document.title = 'Connected North'
        }
    }, [])

    const resizeListener = () => {
        setMainConainerWidth(
            document
                .getElementsByClassName('main-container')[0]
                .getBoundingClientRect().width,
        )
    }

    useEffect(() => {
        (async () => {
            const p = await getProviderById(providerId);
            if (p) {
                document.title = p["Name"];
                setProvider(p);

                if (p["Featured Video Sample Session"]) {
                    const pSession = await selectProviderSessionById(p["Featured Video Sample Session"][0]);
                    if (pSession) setFeaturedVideoSampleSession(pSession);
                }

                if (p["Fireside Chats"] && p["Fireside Chats"].length) {
                    const chats = await getFiresideChatByProvider(p.Name);
                    setFiresideChats(chats);
                }
                const ss = await getProviderSessions(p["Name"], { field: 'Session Title', direction: 'asc' }, true);
                setSessions(ss);

                if (p["C2L"] && p["C2L"].length) {
                    const cl = await getC2LById(p["C2L"][0]);
                    if (cl) setC2L(cl);
                }
                setLoading(false)
            } else {
                setLoading(false);
                setError("There is no provider.")
            }
        })();
    }, [providerId])

    const renderContent = () => {
        if (loading) return null;

        if (error) {
            return (<div align="center">{error}</div>)
        }

        return (
            <div align="left" style={{ display: 'grid' }}>
                <div className="content">
                    {(provider["Logo/Photo"] && provider["Logo/Photo"].length) ? (
                        <img src={provider["Logo/Photo"][0].url} style={{ float: 'right', marginLeft: 10, marginBottom: 10, maxWidth: 300 }} alt="Content Provider" />
                    ) : null}
                    <p>{provider["Introduction/Bio"]}</p>
                </div>

                {
                    provider["Embed Sample"] ? (
                        <div style={{ paddingTop: 30 }}>
                            {featuredVideoSampleSession ? <span className="item-label">
                                Session Preview: <a target='_blank' rel="noopener noreferrer" href={`/cn/session/${featuredVideoSampleSession.id}`}>{featuredVideoSampleSession["Session Title"]}</a>
                            </span> : <span className="item-label">Session Preview</span>}
                            <div style={{ height: 10 }} />
                            <VideoPlayer
                                videoData={provider["Embed Sample"]}
                                width={mainContainerWidth}
                                // onPlay={() => recordPlayHistory()}
                                onPlay={() => { }}
                            />
                        </div>
                    ) : null
                }

                <div style={{ paddingTop: 30 }}>
                    <span className="item-label">{sessions.length} Sessions Offered</span>
                    <div>
                        {
                            sessions.map((session, i) => (
                                <div
                                    className="search-session"
                                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0 20px', height: 80 }}
                                    key={i}
                                >
                                    <div
                                        style={{
                                            flex: 1,
                                            minHeight: 80,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center'
                                        }}
                                        onClick={() => window.open(`/cn/session/${session.id}`)}
                                    >
                                        <div>
                                            <span className="title">{session['Session Title']}</span>
                                        </div>
                                        {session['# Booked (21-22)'] ? (
                                            <span style={{ fontSize: 12 }}>{i18n.t("booked-time", { bookedTime: session['# Booked (21-22)'] })}</span>
                                        ) : (
                                            <span style={{ fontSize: 12 }}>{t("not-booked")}</span>
                                        )
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>

                {
                    firesideChats.length > 0 && (
                        <div style={{ paddingTop: 30 }}>
                            <span className="item-label">Explore {provider["First Name"]}'s Fireside Chat</span>
                            <div>
                                {
                                    firesideChats.map((chat, i) => (
                                        <div key={i} style={{ marginTop: 5 }} className="firesidechat">
                                            {
                                                (chat["Illustration File"] && chat["Illustration File"].length) && (
                                                    <img
                                                        src={chat["Illustration File"][0].thumbnails.large.url}
                                                        alt=""
                                                        className='firesidechat-logo'
                                                        onClick={() => {
                                                            if (chat["Web URL"]) window.open(getValidUrl(chat["Web URL"]), '_blank')
                                                        }}
                                                    />
                                                )
                                            }
                                            <p>{chat.Quote}</p>
                                            <a href={getValidUrl(chat["Web URL"])} target="_blank" rel="noopener noreferrer"><span>Read more &raquo;</span></a>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }

                {
                    c2l ? (
                        <div style={{ paddingTop: 30 }}>
                            <span className="item-label">Create to Learn with {provider["First Name"]}</span>
                            <div style={{ display: 'flex', paddingTop: 7 }}>
                                {
                                    c2l["Description"] ? (
                                        <div>
                                            <p>{c2l["Description"]}</p>
                                            <a href={getValidUrl(c2l["Web URL"])} target="_blank" rel="noopener noreferrer"><span>Check it out &raquo;</span></a>
                                        </div>
                                    ) : null
                                }
                                <img
                                    alt=""
                                    src={c2l["Thumbnail"][0].thumbnails.large.url}
                                    style={{ maxWidth: '60%', cursor: 'pointer' }}
                                    onClick={() => {
                                        if (c2l["Web URL"]) window.open(getValidUrl(c2l["Web URL"]), '_blank')
                                    }}
                                />
                            </div>
                        </div>
                    ) : null
                }
            </div>
        )
    }

    return (
        <div className="main-container">
            <span className="page-topic">{provider ? provider["Name"] : " "}</span>
            {loading && <LinearProgress />}
            <div style={{ marginTop: 20 }} align="center">{renderContent()}</div>
        </div>
    )
}

export default Screen;
