import React from 'react';
import {
    Modal,
    Button,
    FormGroup,
    Input
} from 'reactstrap';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { getDateFormat_mmddyyyy } from '../../utils/time';
import { requestPayment } from '../../airtables/Payments';
import Select from 'react-select';
import CustomImage from '../../components/CustomImage';

export default class RequestPaymentModal extends React.Component {
    constructor(props) {
        super(props)

        const session = props.session;

        this.state = {
            showModal: false,
            submitting: false,

            session: props.session,
            amount: session['Cost'].toString(),
            paymentType: '',
            chequeAddress: '',
            emtEmail: session['Provider Email'][0],
            attachments: [],
            submittedDate: new Date(),
            userInfo: props.userInfo,
        }

        this.paymentTypes = [
            'EMT',
            'Cheque'
        ]
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            showModal: nextProps.showModal
        }
    }

    onAttachFile(file) {
        if (file) {
            var attachments = this.state.attachments;
            attachments.push({
                file, loading: true
            })
            this.setState({ attachments });
            this.imgInputRef.value = '';
        }
    }

    onSubmit() {
        if (!this.state.amount || parseFloat(this.state.amount) === 0) {
            alert('Please type amount to be paid.');
            return;
        }

        if (!this.state.paymentType) {
            alert('Please select payment type.');
            return;
        }

        if (this.state.paymentType === 'EMT' && !this.state.emtEmail) {
            alert('Please type EMT email address.');
            return;
        }

        if (this.state.paymentType === 'Cheque' && !this.state.chequeAddress) {
            alert('Please type Cheque address.');
            return;
        }

        var param = {
            'Requested by': [this.state.userInfo.id],
            'Provider': this.state.session['Provider'],
            'Amount': parseFloat(this.state.amount),
            'Session(s)': [this.state.session.id],
            'Date Submitted': getDateFormat_mmddyyyy(this.state.submittedDate),
            'Payment Type': this.state.paymentType,
            'Attachments': this.state.attachments.map((attach => ({url: attach.uploadUrl})))
        }

        if (this.state.paymentType === 'EMT') {
            param['EMT E-mail (if EMT)'] = this.state.emtEmail
        }

        if (this.state.paymentType === 'Cheque') {
            param['Cheque Address (if Cheque)'] = this.state.chequeAddress
        }

        this.setState({ submitting: true })
        requestPayment(param).then(res => {
            this.setState({ submitting: false });
            this.props.onDone();
            toast.success("Reqested successfully!")
        }).catch(error => {
            this.setState({ submitting: false })
            toast.error(error.toString())
        })
    }

    render() {
        const { session } = this.state;
        return (
            <Modal
                className="modal-dialog-centered"
                isOpen={this.state.showModal}
                toggle={() => this.props.onToggle()}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="modal-title-default">Request Payment</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.props.onToggle()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="">
                        <FormGroup>
                            <label>How do you rate this session?</label>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginVertical: 5 }}>
                                <div style={{ background: 'lightpink' }} className="form-input">
                                    <span>{session['Provider Name']}</span>
                                </div>
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <label>Date Submitted</label>
                            <Input
                                type="text"
                                className="form-style"
                                defaultValue={getDateFormat_mmddyyyy(this.state.submittedDate)}
                                readOnly
                            />
                        </FormGroup>

                        <FormGroup>
                            <label>Amount to be paid</label>
                            <Input
                                type="text"
                                className="form-style"
                                defaultValue={this.state.amount}
                                onChange={e => this.setState({ amount: e.target.value })}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label>Payment Type</label>
                            <Select
                                className="form-style"
                                value={{ value: this.state['paymentType'], label: this.state['paymentType'] }}
                                placeholder="Type of Session"
                                onChange={(selectedOption) => this.setState({ paymentType: selectedOption.value })}
                                options={this.paymentTypes.map(type => ({ value: type, label: type }))}
                            />
                        </FormGroup>

                        {
                            this.state.paymentType === 'Cheque' ? (
                                <FormGroup>
                                    <label>Cheque Address</label>
                                    <Input
                                        type="text"
                                        className="form-style"
                                        defaultValue={this.state.chequeAddress}
                                        onChange={e => this.setState({ chequeAddress: e.target.value })}
                                    />
                                </FormGroup>
                            ) : (null)
                        }

                        {
                            this.state.paymentType === 'EMT' ? (
                                <FormGroup>
                                    <label>EMT E-mail</label>
                                    <Input
                                        type="email"
                                        className="form-style"
                                        defaultValue={this.state.emtEmail}
                                        onChange={e => this.setState({ emtEmail: e.target.value })}
                                    />
                                </FormGroup>
                            ) : (null)
                        }

                        <FormGroup>
                            <label>Attachments</label>
                            <input 
                                type="file" 
                                accept="image/*" 
                                ref={ref => this.imgInputRef = ref} 
                                style={{ display: 'none' }} 
                                onChange={e => this.onAttachFile(e.target.files[0])}
                            />
                            {
                                this.state.attachments.length > 0 ? (
                                    <div className="horizontal-scrollview">
                                        {
                                            this.state.attachments.map((attachment, index) => (
                                                <CustomImage attachment={attachment} key={index} onUploadDone={(uploadUrl) => {
                                                    var attachments = this.state.attachments;
                                                    attachments[index].uploadUrl = uploadUrl;
                                                    attachments[index].loading = false
                                                    this.setState({ attachments });
                                                }} />
                                            ))
                                        }
                                    </div>
                                ) : null
                            }
                            <div style={{marginTop: 5}}>
                                <Button size="sm" onClick={() => this.imgInputRef.click()} type="button" color="secondary" >Attach Image</Button>
                            </div>
                        </FormGroup>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button
                        color="primary"
                        type="button"
                        onClick={() => this.onSubmit()}
                    >
                        {this.state.submitting ? (<Loading size={14} />) : (null)}Submit
                    </Button>
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.props.onToggle()}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        )
    }
}