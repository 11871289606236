import React, { useState } from 'react';
import {
    Button,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
    Modal
} from 'reactstrap';
import Loading from '../../components/Loading';
import ReactDatetime from 'react-datetime';
import { updateSession } from '../../airtables/Sessions';
import { getDateAndTimeString } from '../../utils/time';
import Axios from 'axios';
import { useSelector } from 'react-redux';

const SessionReject = ({ sessionId, onToggle, onPost }) => {
    const { userInfo } = useSelector(state => state.appInfo);

    const [submitting, setSubmitting] = useState(false);
    const [comment, setComment] = useState("");
    const [startDate, setStartDate] = useState();
    const [alterDate, setAlterDate] = useState();
    const [preferredTimeValid, setPreferredTimeValid] = useState([]);
    const [alternativeTimeValid, setAlternativeTimeValid] = useState([]);
    const [commentValid, setCommentValid] = useState();
    const [calcing, setCalcing] = useState(false);

    const isCalendarToken = userInfo["Calendar Token"] ? true : false;

    const checkValidationOfSelectedTime = (selectedTime, isPreferred, valids) => {
        setCalcing(true)
        if (isCalendarToken) {
            const providerId = userInfo.id;
            const dateInfo = getDateAndTimeString(selectedTime);
            Axios.get(`https://research.tigweb.org/connectednorth/provider-availability/events.html?provider=${providerId}&date=${dateInfo.day}&time=${dateInfo.time}`).then(res => {
                if (res.data === "No events found.") {
                    valids.push("valid")
                } else {
                    valids.push("invalid")
                }
                if (isPreferred) setPreferredTimeValid(valids);
                else setAlternativeTimeValid(valids);
                setCalcing(false)
            }).catch(error => {
                setCalcing(false)
            })
        } else {
            setCalcing(false)
        }
    }

    const renderTimeValidation = (timeValid) => {
        const renderTimeValidationItem = (item) => {
            if (item === 'valid') {
                return (
                    <span style={{ color: 'green', fontSize: 12 }}>This time appears to be available in the provider's calendar.</span>
                )
            }

            if (item === 'invalid') {
                return (
                    <span style={{ color: 'red', fontSize: 12 }}>The provider's calendar appears busy at this time. We suggest selecting an alternate time to propose!</span>
                )
            }

            if (item === 'incorrect') {
                return (
                    <span style={{ color: '#cda535', fontSize: 12 }}>Warning: This time seems to be very early or very late. Please ensure you have selected the correct AM or PM option!</span>
                )
            }

            if (item === 'past-time') {
                return (
                    <span style={{ color: 'red', fontSize: 12 }}>You are selecting past time now.</span>
                )
            }

            return null;
        }

        return (
            <>
                {
                    timeValid.map((item, i) => <div key={i}>
                        {renderTimeValidationItem(item)}
                    </div>)
                }
            </>
        )
    }

    const onSubmit = async () => {
        if (submitting || calcing) return;
        if (commentValid === 'invalid') return;
        if (!startDate) return;
        if (!alterDate) return;

        if (preferredTimeValid.indexOf('invalid') >= 0) {
            alert("The Preferred Date & Time is invalid.");
            return;
        }

        setSubmitting(true);
        updateSession(
            sessionId,
            {
                "Provider Booking Response": `${comment}\nOption 1: ${startDate.toString()}\nOption 2: ${alterDate.toString()}`,
                "Status": "Request Rejected by Provider"
            }
        ).then(res => {
            onToggle();
            if (onPost) onPost();
        }).catch(error => {
            alert(error.toString());
        });
    }

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={true}
            toggle={onToggle}
            backdrop={false}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="modal-title-default">Reject - Neither time works for me</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => {
                        if (submitting) return;
                        onToggle()
                    }}
                ><span aria-hidden={true}>×</span></button>
            </div>
            <div className="modal-body">
                <label>Please provide additional details on future availability or ideal booking times for the teacher</label>
                <Form>
                    <FormGroup>
                        <Input
                            type="textarea"
                            style={{ minHeight: 100 }}
                            onChange={(e) => {
                                setComment(e.target.value)
                                setCommentValid(!e.target.value ? 'invalid' : 'valid')
                            }}
                            invalid={commentValid === 'invalid'}
                            disabled={submitting}
                        />
                    </FormGroup>
                    <FormGroup>
                        <InputGroup
                            className="input-group-alternative"
                            style={{ border: preferredTimeValid.indexOf('invalid') > 0 ? '1px solid #fb6340' : 0 }}
                        >
                            <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                            <ReactDatetime
                                inputProps={{
                                    placeholder: "Date/Time 1",
                                    style: {
                                        color: 'black',
                                        fontSize: 14,
                                    },
                                    disabled: submitting
                                }}
                                timeFormat={true}
                                onChange={(e) => {
                                    if (typeof e !== 'string') {
                                        const valids = [];
                                        const selectedDate = e.toDate();
                                        const selectedHour = selectedDate.getHours();
                                        if (selectedDate < new Date()) valids.push("past-time")
                                        if (selectedHour < 8 || selectedHour >= 17) { valids.push("incorrect"); }
                                        setPreferredTimeValid(valids)

                                        checkValidationOfSelectedTime(selectedDate, true, valids);
                                        setStartDate(selectedDate);
                                    }
                                }}
                            />
                        </InputGroup>
                        {renderTimeValidation(preferredTimeValid)}
                    </FormGroup>
                    <FormGroup>
                        <InputGroup
                            className="input-group-alternative"
                            style={{ border: alternativeTimeValid.indexOf('invalid') > 0 ? '1px solid #fb6340' : 0 }}
                        >
                            <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                            <ReactDatetime
                                inputProps={{
                                    placeholder: "Date/Time 2",
                                    style: {
                                        color: 'black',
                                        fontSize: 14,
                                    },
                                    disabled: submitting
                                }}
                                timeFormat={true}
                                onChange={(e) => {
                                    if (typeof e !== 'string') {
                                        const valids = [];
                                        const selectedDate = e.toDate();
                                        const selectedHour = selectedDate.getHours();
                                        if (selectedDate < new Date()) valids.push("past-time")
                                        if (selectedHour < 8 || selectedHour >= 17) { valids.push("incorrect"); }
                                        setAlternativeTimeValid(valids);

                                        checkValidationOfSelectedTime(selectedDate, false, valids);
                                        setAlterDate(selectedDate);
                                    }
                                }}
                            />
                        </InputGroup>
                        {renderTimeValidation(alternativeTimeValid)}
                    </FormGroup>
                </Form>
            </div>
            <div className="modal-footer">
                <Button
                    className="ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={onToggle}
                >Close</Button>
                <Button
                    color="success"
                    type="button"
                    onClick={() => onSubmit()}
                    disabled={submitting || calcing}
                >{submitting && (<Loading size={14} />)}SUBMIT</Button>
            </div>
        </Modal>
    )
}

export default SessionReject;