import React, { useEffect, useState } from 'react';
import { Button, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { getSessions, getUnpaidSessions, getCompletedSessions, updateSession, getAllCompletedSessions } from '../airtables/Sessions';
import SessionItem from '../components/SessionItem';
import { LinearProgress } from '@mui/material';
import {
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { toast } from 'react-toastify';
import Axios from 'axios';
import RateSessionModal from '../pages/modals/Rate';
import RequestPaymentModal from '../pages/modals/RequestPayment';
import SessionResultModal from '../pages/modals/SessionResult';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import ReactStars from "react-rating-stars-component";

const Page = () => {
    const [sessions, setSessions] = useState([]);
    const [allSessions, setAllSessions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sessionStatus, setSessionStatus] = useState('Booked');
    const [showRateSessionModal, setShowRateSessionModal] = useState(false);
    const [activeRateSession, setActiveRateSession] = useState(null);
    const [showRequestPaymentModal, setShowRequestPaymentModal] = useState(false);
    const [activeRequestPaymentSession, setActiveRequestPaymentSession] = useState(null);
    const [showSessionResultModal, setShowSessionResultModal] = useState(false);
    const [activeSRSession, setActiveSRSession] = useState(null);
    const { userInfo, userType } = useSelector(state => state.appInfo);
    const [isAllPastSession, setIsAllPastSession] = useState(false)
    const { t, i18n } = useTranslation();

    const onRefresh = async (status) => {
        if (!userInfo) return;

        setLoading(true);

        var sesslist = [];
        if (status === 'Unpaid') {
            sesslist = await getUnpaidSessions('School Lead', userInfo['Name'], userInfo['Administrator']);
        } else if (status === 'Finish') {
            switch (userType) {
                case 'Teacher':
                    sesslist = await getSessions('Teacher', userInfo['Teacher Name'], "Completed", false, false, true);
                    const alllist = await getAllCompletedSessions('Teacher', userInfo['Teacher Name'], true);
                    if (sesslist.length === 0) {
                        setIsAllPastSession(true);
                    }
                    setAllSessions(alllist);
                    break;
                case 'Team':
                    sesslist = await getCompletedSessions('School Lead', userInfo['Name'], userInfo['Administrator'])
                    break;
                case 'Provider':
                    sesslist = await getSessions('Provider', userInfo['Name'], "Completed")
                    break;
                default:
                    break;
            }
        } else {
            sesslist = await getSessions('School Lead', userInfo['Name'], status, userInfo['Administrator'], true)
        }
        setSessions(sesslist);
        setLoading(false);
    };

    useEffect(() => {
        setSessionStatus((userType === 'Team') ? "Booked" : "Finish")
        onRefresh((userType === 'Team') ? "Booked" : "Finish");
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeStatus = async (status) => {
        if (loading) return;
        setSessionStatus(status)
        onRefresh(status);
    }

    const getPlaceholderString = () => {
        if (userType === 'Team') {
            switch (sessionStatus) {
                case 'Booked':
                    return t("no-booked-sessions");
                case 'Completed':
                    return t("no-completed-sessions");
                case 'Unpaid':
                    return t("no-unpaid-sessions");
                case 'Finish':
                    return t("no-finish-sessions");
                default:
                    return '';
            }
        } else {
            return t("no-sessions-for-non-team");
        }
    }

    const onRateSession = (e, session) => {
        e.preventDefault();
        setShowRateSessionModal(true);
        setActiveRateSession(session);
    }

    const onRequestPayment = (e, session) => {
        e.preventDefault();
        setShowRequestPaymentModal(true);
        setActiveRequestPaymentSession(session);
    }

    const onGenerateInvoice = (e, session) => {
        e.preventDefault();
        Axios.post('https://hooks.zapier.com/hooks/catch/89715/od2te8d/', {
            sessionid: [session.id]
        }, {
            headers: {
                'Content-Type': 'text/plain'
            }
        }).then(res => {
            toast.success(t("successfully-posted"));
        }).catch(error => {
            toast.error(error.toString());
        })
    }

    const onChangeSession = (e, session, status) => {
        e.preventDefault();
        updateSession(session.id, {
            'Status': status
        }).then(res => {
            onRefresh(sessionStatus);
        }).catch(error => {
            toast.error(error.toString());
        })
    }

    const openSessionResult = (e, session) => {
        e.preventDefault();
        setShowSessionResultModal(true);
        setActiveSRSession(session);
    }

    const postRating = () => {
        setShowRateSessionModal(false)
        onRefresh(sessionStatus);
    }

    const postRequestPayment = () => {
        setShowRequestPaymentModal(false)
    }

    const renderRateInfo = (session) => {
        let rating;
        if (userType === 'Teacher') rating = session["Rating"];
        else if (userType === 'Team') rating = session["Team Rating"];

        if (rating) {
            return (
                <div style={{ marginTop: 3 }}>
                    <ReactStars
                        value={rating}
                        count={5}
                        edit={false}
                        size={24}
                        isHalf={false}
                        emptyIcon={<i className="fa fa-star"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#ffd700"
                    />
                </div>
            )
        }

        if (userType === 'Teacher' && session["# Teachers"] > 1) return null;

        return (
            <button
                type="button"
                className='btn btn-secondary'
                onClick={e => onRateSession(e, session)}
            >{t("rate-session")}</button>
        )
    }

    const renderSessions = () => {
        if (loading) {
            return (null)
        }

        return (
            <>
                {
                    !isAllPastSession && sessions.length > 0 ? sessions.map((session, index) => (
                        <div key={index}>
                            <SessionItem session={session} userType={userType} hasFavorite={userType === 'Teacher'}>
                                {
                                    ((userType === 'Teacher' || userType === 'Team') && sessionStatus === 'Finish') ? (
                                        <div>{renderRateInfo(session)}</div>
                                    ) : (
                                        <UncontrolledDropdown>
                                            <DropdownToggle color="secondary">
                                                <i className="fa fa-ellipsis-v" />
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {
                                                    (((userType === 'Teacher' && session["# Teachers"] === 1) || userType === 'Team') && sessionStatus === 'Finish') ? (
                                                        <DropdownItem href="#" onClick={e => onRateSession(e, session)}>{t("rate-session")}</DropdownItem>
                                                    ) : null
                                                }

                                                {
                                                    (userType === 'Team' && sessionStatus === 'Unpaid') ? (
                                                        <>
                                                            <DropdownItem href="#" onClick={e => onRequestPayment(e, session)}>{t("request-payment")}</DropdownItem>
                                                            {
                                                                (session['Payment Type'] === 'Individual Invoices Per Session') && (
                                                                    <DropdownItem href="#" onClick={e => onGenerateInvoice(e, session)}>{t("generate-invoice")}</DropdownItem>
                                                                )
                                                            }
                                                        </>
                                                    ) : null
                                                }

                                                {
                                                    (userType === 'Team' && sessionStatus === 'Booked') ? (
                                                        <>
                                                            <DropdownItem href="#" onClick={e => onChangeSession(e, session, 'Completed')}>
                                                                {t("set-status-completed")}
                                                            </DropdownItem>
                                                            <DropdownItem href="#" onClick={e => onChangeSession(e, session, 'Cancelled without Payment')}>
                                                                {t("set-status-cancelledwithoutpayment")}
                                                            </DropdownItem>
                                                            <DropdownItem href="#" onClick={e => onChangeSession(e, session, 'Cancelled with Payment')}>
                                                                {t("set-status-cancelledpayment")}
                                                            </DropdownItem>
                                                        </>
                                                    ) : null
                                                }

                                                {
                                                    (userType === 'Team' && sessionStatus === 'Finish') ? (
                                                        <DropdownItem href="#" onClick={e => openSessionResult(e, session)}>{t("open-session-result")}</DropdownItem>
                                                    ) : null
                                                }
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    )
                                }
                            </SessionItem>
                        </div>
                    )) : null
                }

                {
                    isAllPastSession && allSessions.length > 0 ? allSessions.map((session, index) => (
                        <div key={index}>
                            <SessionItem session={session} userType={userType} hasFavorite={userType === 'Teacher'}>
                                {
                                    ((userType === 'Teacher' || userType === 'Team') && sessionStatus === 'Finish') ? (
                                        <div><div>{renderRateInfo(session)}</div></div>
                                    ) : (
                                        <UncontrolledDropdown>
                                            <DropdownToggle color="secondary">
                                                <i className="fa fa-ellipsis-v" />
                                            </DropdownToggle>
                                            <DropdownMenu>

                                                {
                                                    (userType === 'Team' && sessionStatus === 'Unpaid') ? (
                                                        <>
                                                            <DropdownItem href="#" onClick={e => onRequestPayment(e, session)}>{t("request-payment")}</DropdownItem>
                                                            {
                                                                (session['Payment Type'] === 'Individual Invoices Per Session') && (
                                                                    <DropdownItem href="#" onClick={e => onGenerateInvoice(e, session)}>{t("generate-invoice")}</DropdownItem>
                                                                )
                                                            }
                                                        </>
                                                    ) : null
                                                }

                                                {
                                                    (userType === 'Team' && sessionStatus === 'Booked') ? (
                                                        <>
                                                            <DropdownItem href="#" onClick={e => onChangeSession(e, session, 'Completed')}>
                                                                {t("set-status-completed")}
                                                            </DropdownItem>
                                                            <DropdownItem href="#" onClick={e => onChangeSession(e, session, 'Cancelled without Payment')}>
                                                                {t("set-status-cancelledwithoutpayment")}
                                                            </DropdownItem>
                                                            <DropdownItem href="#" onClick={e => onChangeSession(e, session, 'Cancelled with Payment')}>
                                                                {t("set-status-cancelledpayment")}
                                                            </DropdownItem>
                                                        </>
                                                    ) : null
                                                }

                                                {
                                                    (userType === 'Team' && sessionStatus === 'Finish') ? (
                                                        <DropdownItem href="#" onClick={e => openSessionResult(e, session)}>{t("open-session-result")}</DropdownItem>
                                                    ) : null
                                                }
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    )
                                }
                            </SessionItem>
                        </div>
                    )) : null
                }

                {
                    (sessions.length === 0 && allSessions.length === 0) && (
                        <div className="nolist-placeholder" align="center">
                            <span>{getPlaceholderString()}</span>
                        </div>
                    )
                }
            </>
        )
    }

    const renderTitle = () => {
        if (loading) return null;
        if (userType === 'Team') {
            return (
                <span className="page-topic">{t("manage-past-sessions")}</span>
            )
        }

        if (sessions.length === 0 && allSessions.length === 0) {
            return (
                <span className="page-topic">{t("rate-past-sessions")}</span>
            )
        }

        if (sessions.length === 0) {
            return (
                <span className="page-topic">{i18n.t("all-n-past-sessions", { len: allSessions.length })}</span>
            )
        }

        const allLen = allSessions.length, len = sessions.length;
        return (
            <span className="page-topic">
                {
                    isAllPastSession ? (
                        <Trans
                            i18nKey="all-rate-title"
                            values={{ allLen, len }}
                        >
                            All {{ allLen }} Past Sessions (View <span
                                style={{ color: 'blue' }}
                                onClick={() => setIsAllPastSession(false)}
                            >Rate {{ len }} Past Sessions</span>)
                        </Trans>
                    ) : (
                        <Trans
                            i18nKey="some-rate-title"
                            values={{ allLen, len }}
                        >
                            Rate {{ len }} Past Sessions (View <span
                                style={{ color: 'blue' }}
                                onClick={() => setIsAllPastSession(true)}
                            >All {{ allLen }} Past Sessions</span>)
                        </Trans>
                    )
                }
            </span>
        )

        /* return (
            isAllPastSession ? (
                <span
                    className="page-topic"
                >{i18n.t("all-n-past-sessions", { len: allSessions.length })} (View <span
                    style={{ color: 'blue' }}
                    onClick={() => setIsAllPastSession(false)}
                >{i18n.t("rate-n-past-sessions", { len: sessions.length })}</span>)</span>
            ) : (
                <span
                    className="page-topic"
                >{i18n.t("rate-n-past-sessions", { len: sessions.length })} (View <span
                    style={{ color: 'blue' }}
                    onClick={() => setIsAllPastSession(true)}
                >{i18n.t("all-n-past-sessions", { len: allSessions.length })}</span>)</span>
            )
        ) */
    }

    return (
        <div className="main-container">
            {renderTitle()}
            <div style={{ marginTop: 10 }}>
                {
                    userType === 'Team' ? (
                        <>
                            <div className="main-tabs">
                                <Button
                                    color={sessionStatus !== 'Booked' ? "secondary" : "primary"}
                                    size="md"
                                    type="button"
                                    onClick={
                                        () => {
                                            if (sessionStatus !== 'Booked') {
                                                onChangeStatus('Booked')
                                            }
                                        }
                                    }
                                >{t("booked")}</Button>

                                <Button
                                    color={sessionStatus !== 'Completed' ? "secondary" : "primary"}
                                    size="md"
                                    type="button"
                                    onClick={
                                        () => {
                                            if (sessionStatus !== 'Completed') {
                                                onChangeStatus('Completed')
                                            }
                                        }
                                    }
                                >{t("unrated")}</Button>

                                <Button
                                    color={sessionStatus !== 'Unpaid' ? "secondary" : "primary"}
                                    size="md"
                                    type="button"
                                    onClick={
                                        () => {
                                            if (sessionStatus !== 'Unpaid') {
                                                onChangeStatus('Unpaid')
                                            }
                                        }
                                    }
                                >{t("unpaid")}</Button>

                                <Button
                                    color={sessionStatus !== 'Finish' ? "secondary" : "primary"}
                                    size="md"
                                    type="button"
                                    onClick={
                                        () => {
                                            if (sessionStatus !== 'Finish') {
                                                onChangeStatus('Finish')
                                            }
                                        }
                                    }
                                >{t("completed")}</Button>
                            </div>
                        </>
                    ) : (null)
                }
                {loading ? <LinearProgress /> : null}
                <div className="general-container">
                    {renderSessions()}
                </div>
            </div>
            {
                (showRateSessionModal && activeRateSession) ? (
                    <RateSessionModal
                        showModal={showRateSessionModal}
                        session={activeRateSession}
                        userType={userType}
                        onDone={() => postRating()}
                        onToggle={() => setShowRateSessionModal(!showRateSessionModal)}
                    />
                ) : null
            }

            {
                (showRequestPaymentModal && activeRequestPaymentSession) ? (
                    <RequestPaymentModal
                        showModal={showRequestPaymentModal}
                        session={activeRequestPaymentSession}
                        userInfo={userInfo}
                        onDone={() => postRequestPayment()}
                        onToggle={() => setShowRequestPaymentModal(!showRequestPaymentModal)}
                    />
                ) : null
            }

            {
                (showSessionResultModal && activeSRSession) ? (
                    <SessionResultModal
                        showModal={showSessionResultModal}
                        session={activeSRSession}
                        userType={userType}
                        onToggle={() => setShowSessionResultModal(!showSessionResultModal)}
                    />
                ) : null
            }
        </div>
    )
}

export default Page;
