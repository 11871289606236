import React, { useEffect, useState } from 'react';
import Loading from '../../components/Loading';
import {
    Button,
    Modal,
    FormGroup,
    InputGroupText,
    InputGroup,
    Input
} from 'reactstrap'
import { toast } from 'react-toastify';
import { updateSession } from '../../airtables/Sessions';
import ReactDatetime from 'react-datetime';
import { getProviderById } from '../../airtables/Providers';
import { getDateTimeString } from '../../utils/time';
import Select from 'react-select';
import Axios from 'axios';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const sessionTypes = [
    'Indigenous Role Model/Content Provider',
    'Virtual Experts & Field Trips',
    'Classroom Exchange',
    'Teacher PD',
    'Co-Planning with Teachers & Experts',
    'Technical Support',
    'Other',
    'Text',
    'Premium Session'
];
const estimatedTimeTypes = ['Easy', 'Medium', 'Difficult', 'Most Difficult'];
const sendMeetingRequestTypes = ['All', 'Content Provider', 'Only Me', 'Teacher', 'Provider and Me'];

const Booking = ({ session, showModal, onDone, onToggle }) => {
    const { totalSubjects } = useSelector(state => state.appInfo);
    const providerId = session['Session Title'][0];
    const sessionId = session.id;
    const [loading, setLoading] = useState(true);
    const [booking, setBooking] = useState(false);
    const [startDate, setStartDate] = useState(new Date(session['Session Start Date/Time']));
    const alternativeDate = new Date(session['Alternative Date/Time']);
    const [bridgeAddr, setBridgeAddr] = useState(session['Bridge Address / SIP URI']);
    const [sessionType, setSessionType] = useState('');
    const [estimatedTime, setEstimatedTime] = useState('');
    const [sendMeetingInvite, setSendMeetingInvite] = useState('');
    const [subjectCurriculum, setSubjectCurriculum] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            var subs = [];

            if (providerId) {
                const provider = await getProviderById(providerId);
                if (provider) {
                    provider['Subject'].forEach(subjectId => {
                        for (var i = 0; i < totalSubjects.length; i++) {
                            if (subjectId === totalSubjects[i].id) {
                                subs.push({ value: subjectId, label: totalSubjects[i].Subject })
                            }
                        }
                    });
                    setSubjectCurriculum(subs);
                    setLoading(false)
                }
            }
        })();
    }, [providerId, totalSubjects]);

    const onBook = () => {
        var param = {
            'Session Start Date/Time': startDate,
            'Bridge Address / SIP URI': bridgeAddr,
            'Type of Session': sessionType,
            'Estimated Time to Book Request': estimatedTime,
            'Send Meeting Invite to:': sendMeetingInvite,
            'Subject/Curriculum': subjectCurriculum.map(item => (item.value)),
            'Status': 'Booked'
        }

        setBooking(true);
        updateSession(sessionId, param).then(async res => {
            await Axios.post(
                "https://hooks.zapier.com/hooks/catch/89715/omzbz3f/",
                { record_id: sessionId },
                { headers: { 'Content-Type': 'text/plain' } }
            )
            setBooking(false);
            toast.success("Booked successfully!")
            onDone();
        }).catch(error => {
            setBooking(false);
            toast.error(error.toString())
        })
    }

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={() => onToggle()}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="modal-title-default">Book & Confirm</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => onToggle()}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                {
                    loading ? (
                        <div style={{ padding: '20px 0' }} align="center">
                            <Loading size={20} />
                        </div>
                    ) : (
                        <div>
                            <FormGroup>
                                <label>Session Date & Time</label>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupText>
                                        <i className="ni ni-calendar-grid-58" />
                                    </InputGroupText>
                                    <ReactDatetime
                                        value={startDate}
                                        inputProps={{
                                            placeholder: "Session Date & Time",
                                            style: {
                                                color: 'black',
                                                fontSize: 14
                                            }
                                        }}
                                        timeFormat={true}
                                        onChange={(e) => setStartDate(e.toDate())}
                                    />
                                </InputGroup>
                                <label>Alternate Time Requested: {getDateTimeString(alternativeDate)}</label>
                            </FormGroup>

                            <FormGroup>
                                <label>Bridge Address / SIP URI</label>
                                <Input
                                    className="form-style"
                                    defaultValue={bridgeAddr}
                                    placeholder="Bridge Address / SIP URI"
                                    onChange={e => setBridgeAddr(e.target.value)}
                                />
                            </FormGroup>

                            <FormGroup>
                                <label>Type of Session</label>
                                <Select
                                    className="form-style"
                                    value={{ value: sessionType, label: sessionType }}
                                    placeholder="Type of Session"
                                    onChange={(selectedOption) => setSessionType(selectedOption.value)}
                                    options={sessionTypes.map(type => ({ value: type, label: type }))}
                                />
                            </FormGroup>

                            <FormGroup>
                                <label>Subject/Curriculum</label>
                                <Select
                                    className="form-style"
                                    isMulti={true}
                                    value={subjectCurriculum}
                                    placeholder="Subject/Curriculum"
                                    onChange={(selectedOptions) => setSubjectCurriculum(selectedOptions)}
                                    options={totalSubjects.map(subject => ({ value: subject.id, label: subject.Subject }))}
                                />
                            </FormGroup>

                            <FormGroup>
                                <label>Estimated Time to Book Request</label>
                                <Select
                                    className="form-style"
                                    placeholder="Estimated Time to Book Request"
                                    value={{ value: estimatedTime, label: estimatedTime }}
                                    onChange={selectedOption => setEstimatedTime(selectedOption.value)}
                                    options={estimatedTimeTypes.map(type => ({ value: type, label: type }))}
                                />
                            </FormGroup>

                            <FormGroup>
                                <label>Send Meeting Invite to:</label>
                                <Select
                                    className="form-style"
                                    placeholder="Estimated Time to Book Request"
                                    value={{ value: sendMeetingInvite, label: sendMeetingInvite }}
                                    onChange={selectedOption => setSendMeetingInvite(selectedOption.value)}
                                    options={sendMeetingRequestTypes.map(type => ({ value: type, label: type }))}
                                />
                            </FormGroup>
                        </div>
                    )
                }
            </div>
            <div className="modal-footer">
                <Button
                    color="primary"
                    type="button"
                    onClick={() => onBook()}
                >
                    {booking ? (<Loading size={14} />) : (null)}Book & Confirm
                </Button>
                <Button
                    className="ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => onToggle()}
                >{t("close")}</Button>
            </div>
        </Modal>
    )
}

export default Booking;