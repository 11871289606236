import { LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getProviderSessionsByPhoto } from '../../../airtables/ProviderSessions';
import PhotoSession from './PhotoSession';
import { GradeLevels } from '../../../utils/constants';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { useSelector } from 'react-redux';

const Screen = () => {
    const [loading, setLoading] = useState(true);
    const [sessions, setSessions] = useState([]);
    const [subject, setSubject] = useState(null);
    const [grade, setGrade] = useState("");
    const { totalSubjects, subjectMap } = useSelector(state => state.appInfo);

    useEffect(() => {
        setLoading(true);
        setSessions([]);
        getProviderSessionsByPhoto(subject ? subjectMap[subject].Subject : null, grade).then(res => {
            setLoading(false);
            setSessions([...res]);
        }).catch(error => {
            console.log('ERROR:', error);
            setLoading(false);
        })
    }, [subject, grade, subjectMap])

    return (
        <div className="main-container">
            <span className="page-topic">Browse Sessions</span>
            <div style={{ marginTop: 20, minHeight: 500 }}>
                <div className="photo-session-filter">
                    {
                        (totalSubjects && totalSubjects.length) ? (
                            <Form inline>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="exampleEmail" className="mr-sm-2">Subject:</Label>
                                    <Input
                                        id="subject"
                                        type="select"
                                        onChange={e => setSubject(e.target.value)}
                                    >
                                        <option value=''></option>
                                        {
                                            totalSubjects.map((sub, i) => (
                                                <option value={sub.id} key={i}>{sub.Subject}</option>
                                            ))
                                        }
                                    </Input>
                                </FormGroup>
                                <div style={{ width: 20 }} />
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="exampleEmail" className="mr-sm-2">Grade Level:</Label>
                                    <Input
                                        id="grade"
                                        type="select"
                                        onChange={(e) => setGrade(e.target.value)}
                                    >
                                        <option value=''></option>
                                        {
                                            GradeLevels.map((type, i) => (
                                                <option key={i}>{type}</option>
                                            ))
                                        }
                                    </Input>
                                </FormGroup>
                            </Form>
                        ) : null
                    }
                </div>

                {loading && (<div><LinearProgress /></div>)}

                <div className="photo-session-container">
                    {
                        sessions.map((session, i) => (
                            <PhotoSession
                                key={i}
                                session={session}
                                onPress={() => window.open(`/cn/session/${session.id}`)}
                            />
                        ))
                    }
                </div>
            </div>
        </div >
    )
}

export default Screen;