import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    Input,
    Container,
    Row,
    Col
} from "reactstrap";
import logoImg from '../../assets/img/logo1.png';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { updateSession, getSessionById } from '../../airtables/Sessions';
import { logEvent } from 'firebase/analytics'
import { useTranslation } from "react-i18next";
import './styles.scss';
import { analytics } from "../../firebase";

const rates = [1, 2, 3, 4, 5];

const GlobalRate = (props) => {
    const [height, setHeight] = useState(0);
    const { match: { params: { teacherId, sessionId, rating } } } = props;
    const [loading, setLoading] = useState(true);
    const [isOwner, setIsOwner] = useState(true);
    const [isRated, setIsRated] = useState(false);
    const [error, setError] = useState("");
    const [changedRating, setChangedRating] = useState(parseInt(rating));
    const [feedback, setFeedback] = useState("")
    const [qualityRating, setQualityRating] = useState(5);

    const [saving, setSaving] = useState(false);
    const [session, setSession] = useState(null);
    const [ratingFinished, setRatingFinished] = useState(false);
    const { t, i18n } = useTranslation();

    const resizeListener = () => {
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        setTimeout(resizeListener)
        initialize();

        window.addEventListener('resize', resizeListener)
        return () => {
            window.removeEventListener("resize", resizeListener);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const initialize = async () => {
        let tId = teacherId;
        if (tId.indexOf(",") >= 0) tId = tId.split(",")[0]

        const sess = await getSessionById(sessionId);
        setSession(sess);

        if (!sess) {
            setError(t("no-such-session"));
            setLoading(false);
        } else if (!sess || sess["Teacher"].indexOf(tId) < 0) {
            setIsOwner(false);
            setLoading(false);
        } else if (sess["Rating"]) {
            setChangedRating(sess["Rating"]);
            setFeedback(sess["Feedback"]);
            setQualityRating(sess["Teacher Call Quality Rating"]);

            // setIsRated(true);
            setLoading(false);
        } else {
            await updateSession(sessionId, {
                Rating: changedRating,
                Feedback: "",
                "Teacher Call Quality Rating": 5
            });

            setLoading(false);
            setIsOwner(true);
            setIsRated(false);
        }
    }

    const rateSession = async () => {
        if (changedRating === 0) {
            toast.warning(t("rate-session-desc"));
            return;
        }

        setSaving(true);

        var params = {
            "Rating": changedRating,
            "Feedback": feedback,
            "Teacher Call Quality Rating": qualityRating
        }

        logEvent(analytics, 'rate_session', {
            userType: "Teacher",
            session: sessionId
        });

        updateSession(sessionId, params).then(res => {
            setSaving(false);
            setRatingFinished(true);
            toast.success(t("session-rate-successful-desc"));
        }).catch(error => {
            setSaving(false);
            toast.error(error.toString());
        })
    }

    const getProviderName = () => {
        if (session['Provider Name'] && session['Provider Name'].length) {
            return session['Provider Name'][0];
        }

        return "";
    }

    const renderContent = () => {
        if (loading) return (
            <div className="rate-content">
                <Loading size={24} />
            </div>
        )

        if (error) {
            return (
                <div className="rate-content">
                    <span className="rate-title" style={{ color: 'red' }}>{error}</span>
                </div>
            )
        }

        if (!isOwner) {
            return (
                <>
                    <div className="rate-content">
                        <span className="rate-title" style={{ color: 'red' }}>{t("not-own-session")}</span>
                    </div>
                    <div className="rate-action">
                        <Button
                            className="ml-auto"
                            color="danger"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => window.location.href = "/cn/rate"}
                        >OK</Button>
                    </div>
                </>
            )
        }

        if (ratingFinished) {
            return (
                <>
                    <div className="rate-content">
                        <span className="rate-title">{i18n.t("how-was-your-session", { providerName: getProviderName() })}</span>
                        <div style={{ height: 10 }} />
                        <span>{t("share-rating-desc")}</span>
                        <div className="rating" style={{ justifyContent: 'center' }}>
                            <span>{t("rate-session-success")}</span>
                        </div>

                    </div>
                    <div className="rate-action" style={{ justifyContent: 'center' }}>
                        <Button
                            color="success"
                            type="button"
                            onClick={() => window.location.href = "/cn/rate"}
                        >{t("sign_in")}</Button>
                    </div>
                </>
            )
        }

        if (!isRated) {
            return (
                <>
                    <div className="rate-content">
                        <span className="rate-title">{i18n.t("how-was-your-session", { providerName: getProviderName() })}</span>
                        <div style={{ height: 10 }} />
                        <span>{t("share-rating-desc")}</span>
                        <div className="rating">
                            {
                                rates.map((item, index) => (
                                    <div style={{ padding: '0 5px', flex: 1 }} key={index}>
                                        <div
                                            onClick={() => setChangedRating(item)}
                                            style={{
                                                height: 32,
                                                background: (changedRating === item) ? "#FF5D5D" : "#EAEAEB",
                                                borderRadius: 10,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                color: (changedRating === item) ? "white" : "#1A1824"
                                            }}
                                        >
                                            <span>{item}</span>&nbsp;<i className="fa fa-star" />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="rating-feedback">
                            <Input
                                rows="3"
                                type="textarea"
                                className="form-style"
                                placeholder={t("share-feedback-placeholder")}
                                value={feedback}
                                onChange={e => setFeedback(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="rate-content">
                        <span className="rate-title">{t("how-was-your-session-tech")}</span>
                        <div style={{ height: 10 }} />
                        <span>{t("share-rating-desc-tech")}</span>
                        <div className="rating">
                            {
                                rates.map((item, index) => (
                                    <div style={{ padding: '0 5px', flex: 1 }} key={index}>
                                        <div
                                            onClick={() => setQualityRating(item)}
                                            style={{
                                                height: 32,
                                                background: (qualityRating === item) ? "#FF5D5D" : "#EAEAEB",
                                                borderRadius: 10,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                color: (qualityRating === item) ? "white" : "#1A1824"
                                            }}
                                        >
                                            <span>{item}</span>&nbsp;<i className="fa fa-star" />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className="rate-action">
                        <Button
                            className="ml-auto"
                            color="danger"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => window.location.href = "/cn/rate"}
                        >{t("cancel")}</Button>
                        <Button
                            color="success"
                            type="button"
                            onClick={() => rateSession()}
                        >{saving ? (<Loading size={14} />) : (null)}{t("submit-rating")}</Button>
                    </div>
                </>
            )
        }
    }

    return (
        <main>
            <section className="section section-shaped section-lg" style={{ height: height, background: 'rgb(85, 197, 242, 0.7)', padding: 0 }}>
                <div className="shape shape-style-1 bg-gradient-default">
                    <span /><span /><span /><span /><span /><span /><span /><span />
                </div>
                <Container className="lg-8">
                    <Row className="justify-content-center align-items-center" style={{ height: '100%', overflowY: 'scroll' }} id="rating-main-container">
                        <Col lg="8">
                            <div className="top-gap" />
                            <Card className="bg-secondary shadow border-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    <div className="rate-logo">
                                        <img src={logoImg} style={{ padding: '0 20px', maxWidth: 300, marginBottom: 10 }} alt="" />
                                        <span>{t("tranforming-lives-through-technology")}</span>
                                    </div>
                                    {renderContent()}
                                </CardBody>
                            </Card>
                            <div className="bottom-gap" />
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    )
}

export default GlobalRate;