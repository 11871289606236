import React from 'react';
import { Button } from "reactstrap";
import { getSession } from '../utils/session';
import { CONNECTEDNORTH_SIGNINFO, CONNECTEDNORTH_SIGNTYPE } from '../config';
import { LinearProgress } from '@mui/material';
import { getAllTeams } from '../airtables/Team';
import { getAllProviders } from '../airtables/Providers';
import { getAllTeachers } from '../airtables/Teachers';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import PastSessions from './modals/PastSessions';

class Page extends React.Component {
    constructor() {
        super()
        this.state = {
            loading: true,
            userInfo: null,
            userType: null,

            teams: [],
            teachers: [],
            providers: [],
            activeTab: 'Teams',
            activeContacts: [],

            showModal: false,
            activeTeacher: null
        }
    }

    componentDidMount = async () => {
        const userInfo = await getSession(CONNECTEDNORTH_SIGNINFO);
        const userType = await getSession(CONNECTEDNORTH_SIGNTYPE);

        this.setState({ userInfo, userType });
        this.onRefresh();
    }

    onRefresh = async () => {
        this.setState({ loading: true });

        // get all contacts such as teams, teachers, providers
        const teams = await getAllTeams(this.state.userInfo['Email']); this.setState({ teams });
        const providers = await getAllProviders(); this.setState({ providers });
        const teachers = await getAllTeachers(this.state.userInfo['Administrator'] ? null : this.state.userInfo['Name'], this.state.userInfo['Name']);

        this.setState({ teams, teachers, providers, loading: false })
        // this.setState({ teachers, loading: false });
        this.onChangeStatus(this.state.activeTab);
    }

    onChangeStatus = async (status) => {
        this.setState({ activeTab: status });
        var activeContacts = null;
        switch (status) {
            case 'Teams':
                activeContacts = this.state.teams;
                break;
            case 'Teachers':
                activeContacts = this.state.teachers;
                break;
            case 'Providers':
                activeContacts = this.state.providers;
                break;
            default:
                activeContacts = []
        }

        this.setState({ activeContacts });
    }

    getPlaceholderString() {
        switch (this.state.activeTab) {
            case 'Teams':
                return "No teams.";
            case 'Teachers':
                return "No teachers.";
            case 'Providers':
                return "No providers.";
            default:
                return '';
        }
    }

    async copyClipboard(txt) {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(txt);
        } else {
            document.execCommand('copy', true, txt);
        }
        toast.info("Copied to Clipboard!")
    }

    renderContacts() {
        const { t } = this.props;

        if (this.state.loading) {
            return (null)
        }

        const ContactIcon = () => {
            switch (this.state.activeTab) {
                case 'Teams':
                    return (<i className="fa fa-users" />)
                case 'Teachers':
                    return (<i className="fa fa-book" />)
                case 'Providers':
                    return (<i className="fa fa-microphone" />)
                default:
                    return null
            }
        }

        return (
            this.state.activeContacts.length > 0 ? this.state.activeContacts.map((contact, index) => (
                contact.id !== this.state.userInfo.id ? (<div className="contact-item" key={index}>
                    <div className="name">
                        <ContactIcon />&nbsp;&nbsp;
                        <span>{this.state.activeTab === 'Teachers' ? contact['Teacher Name'] : contact.Name}</span>
                    </div>

                    <div className="contact-info">
                        {
                            contact.Phone ? (
                                <>
                                    <a href={"tel:" + contact.Phone}>
                                        <div className="phone">
                                            <i className="fa fa-phone" />&nbsp;<span>{contact.Phone}</span>
                                        </div>
                                    </a>
                                </>
                            ) : null
                        }

                        {
                            contact.Email ? (
                                <a href={"mailto:" + contact.Email}>
                                    <div className="email">
                                        <i className="fa fa-envelope" />&nbsp;<span>{contact.Email}</span>
                                    </div>
                                </a>
                            ) : null
                        }

                        {
                            this.state.activeTab === 'Teachers' && contact.Sessions ? (
                                <div onClick={() => this.setState({ showModal: true, activeTeacher: contact })}>
                                    <div className="booked-sessions">
                                        <i className="fa fa-video-camera" />&nbsp;<span>{t("n-booked-sessions", { sessions: contact.Sessions })}</span>
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
                </div>) : null
            )) : (
                <div className="nolist-placeholder" align="center">
                    <span>{this.getPlaceholderString()}</span>
                </div>
            )
        )
    }

    render() {
        const { t } = this.props;
        const { showModal, activeTeacher } = this.state;

        return (
            <div className="main-container">
                <span className="page-topic">{t("contacts")}</span>
                <div style={{ marginTop: 10 }}>
                    {
                        (this.state.userType === 'Team' && this.state.userInfo['Administrator']) ? (
                            <>
                                <div className="main-tabs">
                                    <Button
                                        color={this.state.activeTab !== 'Teams' ? "secondary" : "primary"}
                                        size="md"
                                        type="button"
                                        onClick={
                                            () => {
                                                if (this.state.activeTab !== 'Teams') {
                                                    this.onChangeStatus('Teams')
                                                }
                                            }
                                        }
                                    >{t("teams")}</Button>

                                    <Button
                                        color={this.state.activeTab !== 'Teachers' ? "secondary" : "primary"}
                                        size="md"
                                        type="button"
                                        onClick={
                                            () => {
                                                if (this.state.activeTab !== 'Teachers') {
                                                    this.onChangeStatus('Teachers')
                                                }
                                            }
                                        }
                                    >{t("teachers")}</Button>

                                    <Button
                                        color={this.state.activeTab !== 'Providers' ? "secondary" : "primary"}
                                        size="md"
                                        type="button"
                                        onClick={
                                            () => {
                                                if (this.state.activeTab !== 'Providers') {
                                                    this.onChangeStatus('Providers')
                                                }
                                            }
                                        }
                                    >{t("providers")}</Button>
                                </div>
                            </>
                        ) : (null)
                    }
                    {this.state.loading ? <LinearProgress /> : null}
                    <div className="general-container">
                        {this.renderContacts()}
                    </div>
                </div>

                {
                    (showModal && activeTeacher) ? (
                        <PastSessions
                            onToggle={() => {
                                this.setState({
                                    showModal: false,
                                    activeTeacher: null
                                })
                            }}
                            teacherInfo={activeTeacher}
                        />
                    ) : null
                }
            </div>
        )
    }
}

export default withTranslation()(Page);