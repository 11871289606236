import React, { useEffect, useRef, useState } from 'react';
import {
    Modal,
    Button,
    FormGroup,
    Input
} from 'reactstrap';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { updateSession } from '../../airtables/Sessions';
import CustomImage from '../../components/CustomImage';
import { logEvent } from 'firebase/analytics';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { analytics } from '../../firebase';

const RateSessionModal = ({ session, onToggle, onDone }) => {
    const [saving, setSaving] = useState(false);
    const [rating, setRating] = useState(0);
    const [feedback, setFeedback] = useState('');
    const [students, setStudents] = useState('0');
    const [photos, setPhotos] = useState([]);
    const rates = [1, 2, 3, 4, 5];
    const { t } = useTranslation();
    const { userType } = useSelector(state => state.appInfo);
    const imgInputRef = useRef();

    useEffect(() => {
        if (session['Students']) setStudents(session['Students'].toString())
    }, [session])

    const onRate = async () => {
        if (rating === 0) {
            toast.warning("Please rate this session.");
            return;
        }

        var sts = 0;
        if (!students || parseInt(students) <= 0) {
            sts = 0;
        } else {
            sts = parseInt(students);
        }

        setSaving(true);

        var params = {}
        if (userType === 'Team') {
            params['Team Rating'] = rating;
            params['Team Feedback'] = feedback;
            params['Students'] = sts;
            params['Highlight Photos'] = photos.map(photo => ({ url: photo.uploadUrl }));
        } else if (userType === 'Teacher') {
            params['Rating'] = rating;
            params['Feedback'] = feedback;
            params['Students'] = sts;
        } else if (userType === 'Provider' || userType === 'Presenter') {
            params['Provider Rating'] = rating;
            params['Provider Feedback'] = feedback;
        }

        logEvent(analytics, 'rate_session', {
            userType: userType,
            session: session.id
        })

        updateSession(session['id'], params).then(res => {
            setSaving(true)
            onDone();
            toast.success("Rated successfully!");
        }).catch(error => {
            setSaving(false)
            toast.error(error.toString())
        })
    }

    const onAttachFile = (file) => {
        if (file) {
            var mPhotos = [...photos];
            mPhotos.push({
                file, loading: true
            })
            setPhotos(mPhotos)
            imgInputRef.current.value = '';
        }
    }

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={true}
            toggle={() => onToggle()}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="modal-title-default">{t("rate_past_sessions")}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => onToggle()}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <div>
                    <FormGroup>
                        <label>How do you rate this session?</label>
                        <div style={{ padding: '20px 10px', display: 'flex' }}>
                            {
                                rates.map((item, index) => (
                                    <div style={{ padding: '0 5px', flex: 1 }} key={index}>
                                        <div
                                            onClick={() => setRating(item)}
                                            style={{
                                                height: 32,
                                                background: (rating === item) ? "#FF5D5D" : "#EAEAEB",
                                                borderRadius: 10,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                color: (rating === item) ? "white" : "#1A1824"
                                            }}
                                        >
                                            <span>{item}</span>&nbsp;<i className="fa fa-star" />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </FormGroup>

                    {
                        (userType === 'Teacher' || userType === 'Team') && (
                            <FormGroup>
                                <label>How many students participated?</label>
                                <Input
                                    type="number"
                                    className="form-style"
                                    defaultValue={students}
                                    onChange={e => setStudents(e.target.value)}
                                />
                            </FormGroup>
                        )
                    }

                    {
                        userType === 'Team' ? (
                            <FormGroup>
                                <label>Highlight Photos</label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={imgInputRef}
                                    style={{ display: 'none' }}
                                    onChange={e => onAttachFile(e.target.files[0])}
                                />
                                {
                                    photos.length > 0 ? (
                                        <div className="horizontal-scrollview">
                                            {
                                                photos.map((attachment, index) => (
                                                    <CustomImage attachment={attachment} key={index} onUploadDone={(uploadUrl) => {
                                                        var mPhotos = [...photos];
                                                        mPhotos[index].uploadUrl = uploadUrl;
                                                        mPhotos[index].loading = false
                                                        setPhotos(mPhotos)
                                                    }} />
                                                ))
                                            }
                                        </div>
                                    ) : null
                                }
                                <div>
                                    <Button size="sm" onClick={() => imgInputRef.current.click()} type="button" color="secondary" >Attach Image</Button>
                                </div>
                            </FormGroup>
                        ) : null
                    }

                    <FormGroup>
                        <label>Is there anything else you'd like us to know?</label>
                        <Input
                            rows="3"
                            type="textarea"
                            className="form-style"
                            defaultValue={feedback}
                            onChange={e => setFeedback(e.target.value)}
                        />
                    </FormGroup>
                </div>
            </div>
            <div className="modal-footer">
                <Button
                    className="ml-auto"
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => onToggle()}
                >CANCEL</Button>
                <Button
                    color="success"
                    type="button"
                    onClick={() => onRate()}
                >{saving ? (<Loading size={14} />) : (null)}SUBMIT RATING</Button>
            </div>
        </Modal>
    )
}

export default RateSessionModal