import React, { useEffect, useState } from "react";
import MainNavbar from "../components/Navbars/DemoNavbar.js";
import CardsFooter from "../components/Footers/CardsFooter.js";
import { Redirect, Switch, Route } from "react-router-dom";
import {
    UpcomingStack,
    RateStack,
    PendingStack,
    ContactStack,
    SearchStack,
    MySessionsStack,
    BrowserCurriculumStack,
    BrowserPhotoStack,
    MyInfoStack,
    FavoritesStack,
    BestPracticeStack,
    MystatsStack,
    MySettingsStack,
    SurveyStack,
    EventsStack,
    PDWeekStack
} from './routers'
import { Container } from "reactstrap";
import { logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { loadPresenterSessions } from '../airtables/Sessions';
import { useDispatch, useSelector } from "react-redux";
import SessionDetail from "./SessionDetail";
import ProviderDetail from './ProviderDetail';
import TagCollection from "./Browser/Tag/index";
import { setPresenterLoading, setPresenterUpcoming, setPresenterPast } from '../redux/actions';
import { isCompletedBestPractices } from "../utils/bp-utils";
import { setPresentersForProvider } from "../redux/actions";
import { getPresenterById } from '../airtables/Presenters';
import { analytics } from "../firebase.js";

const Page = (props) => {
    const [loading, setLoading] = useState('loading');
    const { userType, userInfo } = useSelector(state => state.appInfo);
    const { path } = props.match;
    const url = window.location.href;
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            if (userInfo) {
                setLoading("signed");
                try {
                    await Promise.all([
                        setUserId(analytics, userInfo['Email']),
                        setUserProperties(analytics, 'user_type', userType),
                        logEvent(analytics, 'login', {
                            email: userInfo['Email'],
                            userType
                        })
                    ]);

                    if (userType === 'Provider' && userInfo["Presenters"] && userInfo["Presenters"].length) {
                        const ps = [];
                        for (const presenterId of userInfo["Presenters"]) {
                            const presenter = await getPresenterById(presenterId);
                            ps.push(presenter);
                        }

                        dispatch(setPresentersForProvider(ps));
                    }
                } catch (error) {
                    console.log("firebase analytics error", error);
                }
            } else {
                setLoading("login");
            }

            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
        })();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (userType === "Presenter" && userInfo) {
            if (userInfo["Sessions Delivered"] && userInfo["Sessions Delivered"].length) {
                dispatch(setPresenterLoading(true));
                loadPresenterSessions(userInfo["Sessions Delivered"], ({ upcoming, past }) => {
                    dispatch(setPresenterUpcoming([...upcoming]));
                    dispatch(setPresenterPast([...past]));
                }).then(() => {
                    dispatch(setPresenterLoading(false));
                }).catch(() => {
                    dispatch(setPresenterLoading(false));
                })
            } else {
                dispatch(setPresenterLoading(false));
                dispatch(setPresenterUpcoming([]));
                dispatch(setPresenterPast([]));
            }
        }
    }, [userInfo]) // eslint-disable-line react-hooks/exhaustive-deps

    const getDefaultPage = (path) => {
        if (userType !== 'Provider') return `${path}/upcoming`;

        if (userInfo['Delivered 21-22'] === 0) return `${path}/best-practices/introduction`;

        if (!userInfo['Other Surveys']) return `${path}/survey`;
        else if (userInfo['Other Surveys'].length) {
            if (isCompletedBestPractices(userInfo, userType)) {
                // return `${path}/mysessions`
                return `${path}/upcoming`
            }
            return `${path}/best-practices/introduction`
        }
    }

    if (loading === 'loading') {
        return (
            <div>loading</div>
        )
    }

    if (loading === 'login') {
        return (
            url ? <Redirect to={`/login?url=${url}`} /> : <Redirect to="/login" />
        )
    }

    return (
        <>
            <MainNavbar signInfo={userInfo} userType={userType} path={path} />
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-mg section-shaped">
                        <div className="shape shape-style-1 shape-default">
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                        </div>
                    </section>
                </div>
                <section>
                    <Container>
                        <Switch>
                            <Redirect from={`${path}/`} exact to={getDefaultPage(path)} />
                            <Route path={`${path}/`} exact component={userType === 'Provider' ? MySessionsStack : UpcomingStack} />
                            <Route path={`${path}/upcoming`} component={UpcomingStack} />
                            <Route path={`${path}/rate`} component={RateStack} />
                            <Route path={`${path}/pending`} component={PendingStack} />
                            <Route path={`${path}/contact`} component={ContactStack} />
                            <Route path={`${path}/browser/curriculum`} component={BrowserCurriculumStack} />
                            <Route path={`${path}/browser/photo`} component={BrowserPhotoStack} />
                            <Route path={`${path}/collection/:tagId`} component={TagCollection} />
                            <Route path={`${path}/session/:sessionId`} exact component={SessionDetail} />
                            <Route path={`${path}/provider/:providerId`} exact component={ProviderDetail} />
                            {userType === 'Teacher' && (
                                <>
                                    <Route path={`${path}/favorites`} component={FavoritesStack} />
                                    <Route path={`${path}/mystats`} component={MystatsStack} />
                                    <Route path={`${path}/mysettings`} component={MySettingsStack} />
                                    <Route path={`${path}/events`} component={EventsStack} />
                                    {
                                        (userInfo["School PT"] && userInfo["School PT"][0] === "recalRmA0o3OZhdlO") && (
                                            <Route path={`${path}/pd-week`} component={PDWeekStack} />
                                        )
                                    }
                                    {
                                        !(userInfo['Limit Booking to Collection'] && userInfo['Limit Booking to Collection'].length) ? (
                                            <Route path={`${path}/search`} component={SearchStack} />
                                        ) : null
                                    }
                                </>
                            )}

                            {
                                userType === 'Team' && (
                                    <>
                                        <Route path={`${path}/events`} component={EventsStack} />
                                        <Route path={`${path}/search`} component={SearchStack} />
                                    </>
                                )}

                            {
                                userType === 'Provider' && (
                                    <>
                                        {userInfo['Delivered 21-22'] > 0 && <Route path={`${path}/survey`} component={SurveyStack} />}
                                        <Route path={`${path}/mysessions`} component={MySessionsStack} />
                                        <Route path={`${path}/best-practices/:page`} component={BestPracticeStack} />
                                        <Route path={`${path}/myinfo`} component={MyInfoStack} />
                                    </>
                                )
                            }

                            {
                                userType === 'Presenter' && (
                                    <>
                                        <Route path={`${path}/best-practices/:page`} component={BestPracticeStack} />
                                        <Route path={`${path}/myinfo`} component={MyInfoStack} />
                                    </>
                                )
                            }
                        </Switch>
                    </Container>
                </section>
            </main>
            <CardsFooter />
        </>
    )
}

export default Page;
