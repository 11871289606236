import React from 'react';
import introImg from '../../../assets/img/introduction.jpg';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

// const url2 = "https://airtable.com/shrfE8jxBCgN9zJkF";

const NavContent = () => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div style={{ width: '100%', marginTop: 10 }}>
            <div className="main-content">
                <p><strong>{t("best-practices-content.intro.title")}</strong></p>
                <p>{t("best-practices-content.intro.para1")}</p>
                <img src={introImg} style={{ float: 'right', marginLeft: 10, marginBottom: 10, maxWidth: 300 }} alt="Introduction" />
                <p>{t("best-practices-content.intro.para2")}</p>
                <p>{t("best-practices-content.intro.para3")}</p>
                <p>
                    <Trans i18nKey={"best-practices-content.intro.para4"}>This new virtual space will be updated periodically with additional resources, and will help to supplement the live professional learning activities we plan for providers throughout the year.</Trans>
                </p>
                <p>{t("best-practices-content.intro.para5")}</p>
                <p>{t("best-practices-content.intro.para6")}</p>
                <p><strong>
                    <Trans i18nKey={"best-practices-content.intro.para7"}>» Continue to <span style={{ color: '#5e72e4', cursor: 'pointer' }} onClick={() => history.push("meaningful-land-acknowledgement")}>Meaningful Land Acknowledgement</span>!</Trans>
                </strong></p>
            </div>
        </div>
    )
}

export default NavContent
