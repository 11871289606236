import React, { useEffect, useState } from 'react';
import { Button, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { getSessionsForPending, updateSession } from '../airtables/Sessions';
import SessionItem from '../components/SessionItem';
import { LinearProgress } from '@mui/material';
import { DropdownMenu, DropdownItem, } from "reactstrap";
import SessionDetail from '../pages/modals/Session';
import { selectProviderSessionById } from '../airtables/ProviderSessions';
import { toast } from 'react-toastify';
import BookingModal from '../pages/modals/Booking';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import RequestForm from './modals/Request';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import InternalIDModal from './modals/InternalID';
import { useSelector } from 'react-redux';
import AssignPresenter from './modals/AssignPresenter';

const Screen = () => {
    const { t, i18n } = useTranslation();
    const { userInfo, userType, presentersForProvider } = useSelector(state => state.appInfo);

    const [pendings, setPendings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);

    // session detail info
    const [activeSession, setActiveSession] = useState(null);
    const [showSessionDetailModal, setShowSessionDetailModal] = useState(false);

    // book & confirm info
    const [showBookingModal, setShowBookingModal] = useState(false);
    const [activeBookingSession, setActiveBookingSession] = useState(null);

    // edit request info
    const [showEditRequestModal, setShowEditRequestModal] = useState(false);
    const [activeRequestedSession, setActiveRequestedSession] = useState(null);

    // edit internal id
    const [showInternalIDModal, setShowInternalIDModal] = useState(false);
    const [activeSessionForInternalID, setActiveSessionForInternalID] = useState(null);

    const [showAssignPresenterModal, setAssignPresenterModal] = useState(false);
    const [activeSessionForAssignPresenter, setActiveSessionForAssignPresenter] = useState(null);

    useEffect(() => {
        onRefresh();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const onRefresh = async (backgroundRefresh) => {
        setLoading(backgroundRefresh ? false : true);

        var ps = [];
        switch (userType) {
            case 'Teacher':
                ps = await getSessionsForPending('Teacher', userInfo['Teacher Name'])
                break;
            case 'Team':
                ps = await getSessionsForPending('School Lead', userInfo['Name'], isAdmin)
                break;
            case 'Provider':
                ps = await getSessionsForPending('Provider', userInfo['Name'])
                break;
            default:
                break;
        }
        setLoading(false);
        setPendings(ps);
        console.log("=======", ps)
    }

    const onChangeStatus = async (ia) => {
        setIsAdmin(ia)
        onRefresh();
    }

    const openSessionDetail = async (id) => {
        if (!id) return;

        setShowSessionDetailModal(true);

        const session = await selectProviderSessionById(id);

        setActiveSession(session);
    }

    const openInternalIDModal = async (session) => {
        setActiveSessionForInternalID(session);
        setShowInternalIDModal(true)
    }

    const onChangeSession = (e, session, status) => {
        e.preventDefault();
        updateSession(session.id, {
            'Status': status
        }).then(res => {
            onRefresh();
        }).catch(error => {
            toast.error(error.toString());
        })
    }

    const onBookConfirm = (e, session) => {
        e.preventDefault();

        setShowBookingModal(true);
        setActiveBookingSession(session);
    }

    const gotoURL = (e, url) => {
        e.preventDefault();
        window.location.href = url;
    }

    const onBookedSession = () => {
        setShowBookingModal(false)
        onRefresh();
    }

    const onConfirm = (e, date1, date2, sessionId, selectedTimeStr) => {
        confirmAlert({
            title: t("accept-request"),
            message: i18n.t("accept-request-desc", { selectedTimeStr }),
            buttons: [
                {
                    label: t("accept"),
                    onClick: () => {
                        e.preventDefault();
                        updateSession(sessionId, {
                            'Session Start Date/Time': new Date(date1),
                            'Alternative Date/Time': new Date(date2),
                            'Status': 'Confirmed for Booking'
                        }).then(res => {
                            onRefresh();
                        }).catch(error => {
                            toast.error(error.toString());
                        })
                    }
                },
                {
                    label: t("cancel"),
                    onClick: () => { }
                }
            ]
        });
    }

    const editRequest = (session) => {
        setActiveRequestedSession(session);
        setShowEditRequestModal(true)
    }

    const cancelRequest = (session) => {
        confirmAlert({
            title: t("cancel-request"),
            message: t("cancel-request-q"),
            buttons: [
                {
                    label: t("confirm"),
                    onClick: () => {
                        // e.preventDefault();
                        updateSession(session.id, {
                            'Status': 'Request Cancelled by Teacher'
                        }).then(res => {
                            sendNotificationForChange(session.id, 'cancel');
                            onRefresh();
                        }).catch(error => {
                            toast.error(error.toString());
                        })
                    }
                },
                {
                    label: t("cancel"),
                    onClick: () => { }
                }
            ]
        });
    }

    const sendNotificationForChange = (sessId, action) => {
        Axios.get(`https://hooks.zapier.com/hooks/catch/89715/b6i5gt7?RequestID=${sessId}&action=${action}`)
    }

    const renderSessions = () => {
        if (loading) {
            return (null)
        }

        return (
            pendings.length > 0 ? pendings.map((session, index) => (
                <SessionItem
                    key={index}
                    session={session}
                    userType={userType}
                    userInfo={userInfo}
                    viewType="pending"
                    gotoBooking={(e, bookingTime, alternativeTime, selectedTimeStr) => {
                        if (userType === "Provider") {
                            onConfirm(e, bookingTime, alternativeTime, session.id, selectedTimeStr);
                        } else return;
                    }}
                    onRefresh={() => onRefresh(true)}
                >
                    <UncontrolledDropdown>
                        <DropdownToggle color="secondary">
                            <i className="fa fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu>
                            {
                                userType === 'Teacher' ? (
                                    <>
                                        <DropdownItem href="#" onClick={e => editRequest(session)}>{t("edit-request")}</DropdownItem>
                                        <DropdownItem href="#" onClick={e => cancelRequest(session)}>{t("cancel-request")}</DropdownItem>
                                        <DropdownItem href="#" onClick={e => gotoURL(e, `tel:${session['School Lead Phone']}`)}>
                                            {i18n.t("call-sbd", { somebody: session['School Lead Text'] })}
                                        </DropdownItem>
                                        <DropdownItem href="#" onClick={e => gotoURL(e, `mailto:${session['School Lead Email']}`)}>
                                            {i18n.t("email-sbd", { somebody: session['School Lead Text'] })}
                                        </DropdownItem>
                                    </>
                                ) : (null)
                            }

                            {
                                userType === 'Provider' ? (
                                    session["Session Host(s)"] ? (
                                        <>
                                            <DropdownItem href="#" onClick={e => gotoURL(e, `tel:${session['Host Phone']}`)}>
                                                {i18n.t("call-sbd", { somebody: session['Session Host Text'] })}
                                            </DropdownItem>
                                            <DropdownItem href="#" onClick={e => gotoURL(e, `mailto:${session['Session Host Email']}`)}>
                                                {i18n.t("email-sbd", { somebody: session['Session Host Text'] })}
                                            </DropdownItem>
                                        </>
                                    ) : (
                                        <>
                                            <DropdownItem href="#" onClick={e => gotoURL(e, `tel:${session['School Lead Phone']}`)}>
                                                {i18n.t("call-sbd", { somebody: session['School Lead Text'] })}
                                            </DropdownItem>
                                            <DropdownItem href="#" onClick={e => gotoURL(e, `mailto:${session['School Lead Email']}`)}>
                                                {i18n.t("email-sbd", { somebody: session['School Lead Text'] })}
                                            </DropdownItem>
                                        </>
                                    )
                                ) : (null)
                            }

                            {
                                userType === 'Team' ? (
                                    <>
                                        <DropdownItem href="#" onClick={e => gotoURL(e, `tel:${session['Teacher Phone']}`)}>
                                            {i18n.t("call-sbd", { somebody: session['Teacher Name'] })}
                                        </DropdownItem>
                                        <DropdownItem href="#" onClick={e => gotoURL(e, `mailto:${session['Teacher Email']}`)}>
                                            {i18n.t("email-sbd", { somebody: session['Teacher Name'] })}
                                        </DropdownItem>
                                        <DropdownItem href="#" onClick={e => gotoURL(e, `tel:${session['Provider Phone']}`)}>
                                            {i18n.t("call-sbd", { somebody: session['Provider Name'] })}
                                        </DropdownItem>
                                        <DropdownItem href="#" onClick={e => gotoURL(e, `mailto:${session['Provider Email']}`)}>
                                            {i18n.t("email-sbd", { somebody: session['Provider Name'] })}
                                        </DropdownItem>
                                        <DropdownItem href="#" onClick={e => onBookConfirm(e, session)}>
                                            {t("book-confirm")}
                                        </DropdownItem>
                                        <DropdownItem href="#" onClick={e => onChangeSession(e, session, 'Unfulfilled')}>
                                            {t("set-status-unfulfilled")}
                                        </DropdownItem>
                                    </>
                                ) : (null)
                            }
                            <DropdownItem href="#" onClick={e => openSessionDetail(session['Session Title'] ? session['Session Title'][0] : null)}>
                                {t("open-session-detail")}
                            </DropdownItem>

                            {
                                userType === 'Provider' && (
                                    <>
                                        <DropdownItem href="#" onClick={e => openInternalIDModal(session)}>
                                            {session["Provider Internal ID"] ? t("edit-internal-id") : t("add-internal-id")}
                                        </DropdownItem>
                                        {
                                            (presentersForProvider && presentersForProvider.length) ? (
                                                <DropdownItem href="#" onClick={e => {
                                                    setActiveSessionForAssignPresenter(session);
                                                    setAssignPresenterModal(true)
                                                }}>
                                                    {t("assign-presenter")}
                                                </DropdownItem>
                                            ) : null
                                        }
                                    </>
                                )
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </SessionItem>
            )) : (
                <div className="nolist-placeholder" align="center">
                    <span>{t("no-pending-sesssions-desc")}</span>
                </div>
            )
        )
    }

    return (
        <div className="main-container">
            <span className="page-topic">{t("pending_session_requests")}</span>
            <div style={{ marginTop: 10 }}>
                {
                    (userType === 'Team' && userInfo['Administrator']) ? (
                        <>
                            <div className="main-tabs">
                                <Button
                                    color={isAdmin ? "secondary" : "primary"}
                                    size="md"
                                    type="button"
                                    onClick={
                                        () => {
                                            if (isAdmin) {
                                                onChangeStatus(false)
                                            }
                                        }
                                    }
                                >{t("my-pending")}</Button>

                                <Button
                                    color={!isAdmin ? "secondary" : "primary"}
                                    size="md"
                                    type="button"
                                    onClick={
                                        () => {
                                            if (!isAdmin) {
                                                onChangeStatus(true)
                                            }
                                        }
                                    }
                                >{t("all-pending")}</Button>
                            </div>
                        </>
                    ) : (null)
                }
                {loading ? <LinearProgress /> : null}
                <div className="general-container">
                    {renderSessions()}
                </div>
            </div>
            {
                (showSessionDetailModal && activeSession != null) && (
                    <SessionDetail
                        session={activeSession}
                        userType={userType}
                        onToggle={() => setShowSessionDetailModal(!showSessionDetailModal)}
                    />
                )
            }

            {
                (showBookingModal && activeBookingSession) ? (
                    <BookingModal
                        showModal={showBookingModal}
                        session={activeBookingSession}
                        onDone={() => onBookedSession()}
                        onToggle={() => setShowBookingModal(!showBookingModal)}
                    />
                ) : null
            }

            {
                (showEditRequestModal && activeRequestedSession) &&
                <RequestForm
                    edit
                    userType={userType}
                    requestedSession={activeRequestedSession}
                    provider={activeRequestedSession['Provider']}
                    onToggle={() => {
                        setShowEditRequestModal(false);
                        setActiveRequestedSession(null)
                    }}
                    onSubmitted={() => {
                        sendNotificationForChange(activeRequestedSession.id, 'change');
                        setShowEditRequestModal(false);
                        setActiveRequestedSession(null)
                        onRefresh();
                    }}
                />
            }

            {
                (activeSessionForInternalID && showInternalIDModal) ? (
                    <InternalIDModal
                        session={activeSessionForInternalID}
                        onToggle={() => setShowInternalIDModal(!showInternalIDModal)}
                        onSave={async (sessionId, internalId) => {
                            setShowInternalIDModal(false)
                            await updateSession(sessionId, { "Provider Internal ID": internalId })
                            const newPendings = [...pendings]
                            for (const session of newPendings) {
                                if (session.id === sessionId) {
                                    session["Provider Internal ID"] = internalId;
                                }
                            }
                            setPendings(newPendings)
                        }}
                    />
                ) : null
            }

            {
                (showAssignPresenterModal && activeSessionForAssignPresenter) ? (
                    <AssignPresenter
                        session={activeSessionForAssignPresenter}
                        onToggle={() => setAssignPresenterModal(!showAssignPresenterModal)}
                        onSelect={async (sessionId, presenter) => {
                            setAssignPresenterModal(false);
                            const res = await updateSession(sessionId, { 'Presenters': [presenter] })
                            const newSession = { ...res[0].fields, id: res[0].id }

                            const newPendings = [...pendings]
                            for (var i = 0; i < newPendings.length; i++) {
                                if (newPendings[i].id === sessionId) {
                                    newPendings[i] = newSession;
                                }
                            }
                            setPendings(newPendings)
                        }}
                    />
                ) : null
            }
        </div>
    )
}

export default Screen;