import React, { useEffect, useState } from 'react';
import { getSessions, getProviderUpcomingSessions, updateSession } from '../../airtables/Sessions';
import { LinearProgress } from '@mui/material';
import SessionDetail from '../../pages/modals/Session';
import ReassignLeadModal from '../../pages/modals/ReassignSchoolLead';
import RequestSupportModal from '../../pages/modals/RequestSupport';
import { selectProviderSessionById } from '../../airtables/ProviderSessions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import InternalIDModal from '../modals/InternalID';
import AssignPresenter from '../modals/AssignPresenter';
import SessionInfo from '../../components/SessionInfo';
import { isSameDay } from '../../utils/time';

const SessionList = ({ isAdmin }) => {
    const [loading, setLoading] = useState(true);
    const [upcomings, setUpcomings] = useState([]);

    // session detail info
    const [activeSession, setActiveSession] = useState(null);
    const [showSessionDetailModal, setShowSessionDetailModal] = useState(false);

    // reassign lead
    const [reassignSessionId, setReassignSessionId] = useState(null);
    const [showReassignLeadModal, setShowReassignLeadModal] = useState(false);
    const [reassignLeads, setReassignLeads] = useState({});

    // request support info
    const [showRequestSupportModal, setShowRequestSupportModal] = useState(false);
    const [activeRequestSupportSession, setActiveRequestSupportSession] = useState(null);

    const [showInternalIDModal, setShowInternalIDModal] = useState(false);
    const [activeSessionForInternalID, setActiveSessionForInternalID] = useState(null);

    const [showAssignPresenterModal, setAssignPresenterModal] = useState(false);
    const [activeSessionForAssignPresenter, setActiveSessionForAssignPresenter] = useState(null);

    const { t } = useTranslation();
    const { userInfo, userType } = useSelector(state => state.appInfo);

    useEffect(() => {
        onRefresh();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getDisplayInfos = (session) => {
        if (!session) return [];
        let res;

        switch (userType) {
            case "Teacher":
                res = [
                    "Provider Name",
                    ...session["# Schools"] > 1 ? [] : ["Resources Ship Date"],
                    "Provider Internal ID",
                    "Session Host"
                ]
                if (session["# Schools"] === 1) res.push("Resources Ship Date");
                if (session["Session Host(s)"] && isSameDay(new Date(session["Session Start Date/Time"]), new Date())) res.push("Session Host");
                else res.push("School Lead");

                return res;
            case "Team":
                res = [
                    "School Name",
                    "Teacher Name",
                    "Provider Internal ID"
                ]
                if (session["# Schools"] === 1) res.push("Resources Ship Date");

                return res
            case "Provider":
                res = [
                    "School Name",
                    "School Location",
                    "Teacher Name",
                    "Grade(s)",
                    "Presenters"
                ]

                if (session["Session Host(s)"] && isSameDay(new Date(session["Session Start Date/Time"]), new Date())) res.push("Session Host");
                else res.push("School Lead");
                
                return res;
            default:
                return [];
        }
    }

    const getActionMenus = (session) => {
        if (!session) return [];

        let res;
        switch (userType) {
            case "Teacher":
                res = [
                    "copy-link",
                    "join-session",
                    "request-support",
                    "open-detail"
                ]
                if (session["# Schools"] === 1 && session["Resource Tracking URL"] && session["Resource Tracking URL"].length) res.push("track-supplies")
                if (session["Session Host(s)"]) {
                    res = [
                        ...res,
                        "call-session-host",
                        "email-session-host"
                    ]
                } else {
                    res = [
                        ...res,
                        "call-school-lead",
                        "email-school-lead"
                    ]
                }

                return res;
            case "Team":
                res = [
                    "copy-link",
                    "join-session",
                    "call-teacher",
                    "email-teacher",
                    "call-provider",
                    "email-provider",
                    "dial-bridge-address",
                    "reassign-session-lead",
                    "request-support",
                    "open-detail"
                ]
                if (session["# Schools"] === 1 && session["Resource Tracking URL"] && session["Resource Tracking URL"].length) res.push("track-supplies")

                return res;
            case "Provider":
                res = [
                    "copy-link",
                    "join-session",
                    "request-support",
                    "open-detail",
                    "internal-id",
                    "assign-presenter"
                ]
                if (session["# Schools"] === 1 && session["Resource Tracking URL"] && session["Resource Tracking URL"].length) res.push("track-supplies")
                if (session["Session Host(s)"]) {
                    res = [
                        ...res,
                        "call-session-host",
                        "email-session-host"
                    ]
                } else {
                    res = [
                        ...res,
                        "call-school-lead",
                        "email-school-lead"
                    ]
                }
                return res;
            default:
                return [];
        }
    }

    const onRefresh = async () => {
        setLoading(true)

        var tUpcomings = [];
        switch (userType) {
            case 'Teacher':
                tUpcomings = await getSessions('Teacher', userInfo['Teacher Name'], 'Booked')
                break;
            case 'Team':
                tUpcomings = await getSessions('School Lead', userInfo['Name'], 'Booked', isAdmin)
                break;
            case 'Provider':
                tUpcomings = await getProviderUpcomingSessions(userInfo['Name'])
                break;
            default:
                break;
        }

        setUpcomings(tUpcomings);
        setLoading(false);
    }

    const reassignSessionLead = (e, session) => {
        e.preventDefault();
        var leads = {}
        if (session['School Lead'] && session['School Lead'].length > 0) {
            session['School Lead'].forEach((leadId) => {
                leads[leadId] = true
            })
        }

        setReassignSessionId(session.id);
        setShowReassignLeadModal(true);
        setReassignLeads(leads)
    }

    const openSessionDetail = async (e, id) => {
        e.preventDefault();
        if (!id) return;

        setShowSessionDetailModal(true);

        const session = await selectProviderSessionById(id);

        setActiveSession(session);
    }

    const onRequestSupport = (e, session) => {
        e.preventDefault();
        const isToday = (someDate) => {
            const today = new Date()
            return someDate.getDate() === today.getDate() &&
                someDate.getMonth() === today.getMonth() &&
                someDate.getFullYear() === today.getFullYear()
        }
        if (userType === 'Teacher' || userType === 'Provider' || (userType === 'Team' && isToday(new Date(session['Session Start Date/Time'])))) {
            window.location.href = "tel:8679920055"
        } else {
            setShowRequestSupportModal(true);
            setActiveRequestSupportSession(session);
        }
    }

    const openInternalIDModal = (session) => {
        setActiveSessionForInternalID(session);
        setShowInternalIDModal(true);
    }

    const assignPresenter = (session) => {
        setActiveSessionForAssignPresenter(session);
        setAssignPresenterModal(true)
    }

    const renderSessions = () => {
        if (loading) { return (null) }

        return (
            upcomings.length > 0 ? upcomings.map((session, index) => (
                <SessionInfo
                    session={session}
                    key={index}
                    displayInfos={getDisplayInfos(session)}
                    actionMenu={getActionMenus(session)}
                    openInternalIDModal={openInternalIDModal}
                    reassignSessionLead={reassignSessionLead}
                    requestSupport={onRequestSupport}
                    openSessionDetail={openSessionDetail}
                    assignPresenter={assignPresenter}
                />
            )) : (
                <div className="nolist-placeholder" align="center">
                    <span>{t("no-upcoming-sessions")}</span>
                </div>
            )
        )
    }

    return (
        <>
            {loading ? <LinearProgress /> : null}
            <div className="general-container">
                {renderSessions()}
            </div>

            {
                (showSessionDetailModal && activeSession !== null) && (
                    <SessionDetail
                        session={activeSession}
                        userType={userType}
                        onToggle={() => setShowSessionDetailModal(!showSessionDetailModal)}
                    />
                )
            }

            <ReassignLeadModal
                showModal={showReassignLeadModal}
                sessionId={reassignSessionId}
                leads={reassignLeads}
                onDone={
                    () => {
                        setShowReassignLeadModal(false);
                        onRefresh();
                    }
                }
                onToggle={() => setShowReassignLeadModal(!showReassignLeadModal)}
            />
            {
                (showRequestSupportModal && activeRequestSupportSession) ? (
                    <RequestSupportModal
                        session={activeRequestSupportSession}
                        showModal={showRequestSupportModal}
                        onToggle={() => setShowRequestSupportModal(!showRequestSupportModal)}
                    />
                ) : null
            }

            {
                (activeSessionForInternalID && showInternalIDModal) ? (
                    <InternalIDModal
                        session={activeSessionForInternalID}
                        onToggle={() => setShowInternalIDModal(!showInternalIDModal)}
                        onSave={async (sessionId, internalId) => {
                            setShowInternalIDModal(false);
                            await updateSession(sessionId, { "Provider Internal ID": internalId })
                            const newUpcomings = [...upcomings]
                            for (const session of newUpcomings) {
                                if (session.id === sessionId) {
                                    session["Provider Internal ID"] = internalId;
                                }
                            }
                            setUpcomings(newUpcomings);
                        }}
                    />
                ) : null
            }

            {
                (showAssignPresenterModal && activeSessionForAssignPresenter) ? (
                    <AssignPresenter
                        session={activeSessionForAssignPresenter}
                        onToggle={() => setAssignPresenterModal(!showAssignPresenterModal)}
                        onSelect={async (sessionId, presenter) => {
                            setAssignPresenterModal(false);
                            const res = await updateSession(sessionId, { 'Presenters': [presenter] })
                            const newPresenter = { ...res[0].fields, id: res[0].id }

                            const newUpcomings = [...upcomings]
                            for (var i = 0; i < newUpcomings.length; i++) {
                                if (newUpcomings[i].id === sessionId) {
                                    newUpcomings[i] = newPresenter;
                                }
                            }
                            setUpcomings(newUpcomings);
                        }}
                    />
                ) : null
            }
        </>
    )
}

export default SessionList;
