import base from './config';
const tableName = "Events"

export const insertEvent = (param) => {
    return new Promise((resolve, reject) => {
        base(tableName).create([{
            "fields": param
        }]).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error)
        })
    })
}

export const selectEvent = (email, sessionName) => {
    return new Promise((resolve, reject) => {
        base(tableName).select({
            filterByFormula: `AND({E-mail Address} = '${email}', FIND('${sessionName.replaceAll('"', '""')}', {Session}))`,
            maxRecords: 1
        }).eachPage((records) => {
            if (records && records.length) {
                resolve({ id: records[0].id, ...records[0].fields });
            } else {
                resolve(null);
            }
        })
    })
}

export const deleteEvent = (email, sessionName) => {
    return new Promise(async (resolve, reject) => {
        const event = await selectEvent(email, sessionName);
        if (!event) {
            resolve();
        } else {
            base(tableName).destroy([event.id])
                .then(() => resolve())
                .catch(error => reject(error))
        }
    })
}