import { createStore, combineReducers } from 'redux';
import appInfoReducer from './reducers';

const rootReducer = combineReducers({
    appInfo: appInfoReducer,
})

const configureStore = () => {
    return createStore(rootReducer);
}

export default configureStore;