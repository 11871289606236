import { updateProvider } from '../../airtables/Providers'
import { updatePresenter } from '../../airtables/Presenters';

export const updateVideoHistory = async (
    userType,
    userInfo,
    videoOwner,
    type
) => {

    let updateFunc;
    if (userType === 'Provider') updateFunc = updateProvider;
    else if (userType === 'Presenter') updateFunc = updatePresenter;
    else return;

    let prefix = "";
    if (type === 'starting') prefix = "Started";
    else if (type === 'finished') prefix = "Completed";
    else return ;
    const res = await updateFunc(userInfo.id, {
        'PD Activities Completed': [
            ...(userInfo['PD Activities Completed'] ? userInfo['PD Activities Completed'] : []),
            `${prefix} Watching ${videoOwner} Video`
        ]
    });

    return {
        id: res[0].id,
        ...res[0].fields
    }
}