import base, { isContainGrades } from './config';
const tableName = "Provider Sessions"

export const filterProviderSessions = (
    search,
    language,
    grades,
    subjects,
    wellBeingLinks,
    indigenousGroups,
    indigenousCommunity,
    culturalGroup,
    sort
) => {
    return new Promise((resolve, reject) => {
        var psResult = []
        var strSearch = '', strlanguage = '', strSubjects = '', filterByFormula = '', strWellBeingLinks = '', strIndigenousGroups = '';
        if (search && search.trim()) {
            const kWord = search.trim().toUpperCase();

            if (kWord.indexOf(" ") > 0) {
                strSearch = `OR(REGEX_MATCH(UPPER({Title}), "${kWord}"), REGEX_MATCH(UPPER({Description}), "${kWord}"), REGEX_MATCH(UPPER({Tags}), "${kWord}"))`
            } else {
                strSearch = `OR(REGEX_MATCH(UPPER({Title}), "(^|.|,| )${kWord}( |.|,|$)"), REGEX_MATCH(UPPER({Description}), "(^|.|,| )${kWord}( |,|.|$)"), REGEX_MATCH(UPPER({Tags}), "(^|.|,| )${kWord}( |,|.|$)"))`;
            }
            filterByFormula = filterByFormula + (filterByFormula === '' ? strSearch : (', ' + strSearch));
        }

        if (language) {
            strlanguage = `{Language} = "${language}"`
            filterByFormula = filterByFormula + (filterByFormula === '' ? strlanguage : (', ' + strlanguage));
        }

        if (subjects) {
            strSubjects = subjects.length === 1 ? '' : 'AND(';
            subjects.forEach((subject, i) => {
                strSubjects = strSubjects + (i === 0 ? '' : ', ') + `FIND("${subject['Subject']}", {Subject})`;
            })
            strSubjects = strSubjects + (subjects.length === 1 ? '' : ')');
            filterByFormula = filterByFormula + (filterByFormula === '' ? strSubjects : (', ' + strSubjects));
        }

        if (wellBeingLinks) {
            strWellBeingLinks = wellBeingLinks.length === 1 ? '' : 'AND(';
            wellBeingLinks.forEach((linkItem, i) => {
                strWellBeingLinks = strWellBeingLinks + (i === 0 ? '' : ', ') + `FIND("${linkItem.name}", {Well-being Link})`;
            })
            strWellBeingLinks = strWellBeingLinks + (wellBeingLinks.length === 1 ? '' : ')');
            filterByFormula = filterByFormula + (filterByFormula === '' ? strWellBeingLinks : (', ' + strWellBeingLinks));
        }

        if ((indigenousGroups && indigenousGroups.length) || indigenousCommunity || culturalGroup) {
            var conditionFormular = "";

            if (indigenousGroups && indigenousGroups.length) {
                strIndigenousGroups = indigenousGroups.length === 1 ? '' : 'AND(';
                indigenousGroups.forEach((iGroup, i) => {
                    strIndigenousGroups = strIndigenousGroups + (i === 0 ? '' : ', ') + `FIND("${iGroup.name}", {Indigenous Group(s)})`;
                })
                strIndigenousGroups = strIndigenousGroups + (indigenousGroups.length === 1 ? '' : ')');
                conditionFormular += strIndigenousGroups;
            }

            if (indigenousCommunity) {
                if (conditionFormular) conditionFormular += ", ";

                conditionFormular += `FIND("${indigenousCommunity}", {Indigenous Communities})`;
            }

            if (culturalGroup) {
                if (conditionFormular) conditionFormular += ", ";

                conditionFormular += `FIND("${culturalGroup}", {Indigenous Cultural Group(s)})`;
            }

            conditionFormular = `OR(${conditionFormular})`;
            filterByFormula = filterByFormula + (filterByFormula === '' ? conditionFormular : (', ' + conditionFormular));
        }

        const hideFromDirectory = '{Hide from Directory} = FALSE(), {Status} = "Active (Open for booking)"';
        filterByFormula = filterByFormula + (filterByFormula === '' ? hideFromDirectory : (', ' + hideFromDirectory));

        filterByFormula = "AND(" + filterByFormula + ")"

        base(tableName).select({
            filterByFormula: filterByFormula,
            sort: sort
        }).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                if (isContainGrades(record.fields, grades)) {
                    const ps = {
                        id: record.id,
                        ...record.fields
                    }

                    psResult.push(ps);
                }
            })
            fetchNextPage()
        }).then(res => {
            resolve(psResult)
        }).catch(error => {
            console.log(error)
            resolve([])
        })
    })
}

export const getProviderSessions = (providerName, sort, isActive) => {
    return new Promise((resolve, reject) => {
        var psResult = []
        base(tableName).select({
            filterByFormula: isActive ? `AND(FIND("${providerName}", {Provider}), {Hide from Directory} = FALSE(), {Status} = "Active (Open for booking)")` : `FIND("${providerName}", {Provider})`,
            sort: [sort]
        }).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                const ps = {
                    id: record.id,
                    ...record.fields
                }

                psResult.push(ps);
            })
            fetchNextPage()
        }).then(res => {
            resolve(psResult)
        }).catch(error => {
            console.log(error)
            resolve([])
        })
    });
}

export const selectProviderSessionById = (id) => {
    return new Promise((resolve, reject) => {
        base(tableName).find(id).then(record => {
            if (record && record._rawJson) {
                resolve({
                    id: record._rawJson.id,
                    ...record._rawJson.fields
                })
            } else {
                resolve(null)
            }
        }).catch(error => {
            resolve(null);
        })
    })
}

export const createProviderSession = (param) => {
    return new Promise((resolve, reject) => {
        base(tableName).create([{
            "fields": param
        }]).then(res => {
            resolve(res);
        }).catch(error => {
            reject(error)
        })
    })
}

export const updateProviderSession = (id, param) => {
    return new Promise((resolve, reject) => {
        base(tableName).update([{
            id, fields: param
        }]).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export const getProviderSessionsByPhoto = (subject, grade) => {
    return new Promise((resolve, reject) => {
        var psResult = [], filterFormula = "";
        filterFormula = `AND(`;

        filterFormula += `NOT(FIND("heic", {Image/Photo})), `
        filterFormula += `{Provider Delivered} = 0, {Language} = 'English', `

        if (subject) {
            filterFormula += `FIND("${subject}", {Subject}), `;
        }

        if (grade) {
            filterFormula += `FIND(" ${grade},", CONCATENATE(" ", ARRAYJOIN({Grade(s)}), ",")), `;
        }

        filterFormula += "{Image/Photo})"

        base(tableName).select({
            filterByFormula: filterFormula,
            pageSize: 30,
            sort: [
                { field: 'Indigenous Provider?', direction: 'desc' },
                { field: '# Booked', direction: 'desc' }
            ]
        }).firstPage().then((records) => {
            records.forEach(record => {
                const ps = {
                    id: record.id,
                    ...record.fields
                }

                psResult.push(ps);
            });
            resolve(psResult);
        })/*.eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                const ps = {
                    id: record.id,
                    ...record.fields
                }

                psResult.push(ps);
            })
            fetchNextPage()
        }).then(res => {
            resolve(psResult)
        })*/.catch(error => {
            resolve([])
        })
    });
}
