const allowedSpecialChars = ["~", "!", "$", "%", "^", "&", "*", "_", "=", "+", "}", "{", "'", "?", "-", "."];
const lowerAlphaChars = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
const upperAlphaChars = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
const numberChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]

export const getValidUrl = (url) => {
    if (!url) return "";
    if (url.startsWith("http:") || url.startsWith("https:")) return url;
    return "https://" + url;
}

export const getVimeoIDFromUrl = (url) => {
    const params = url.split("/");
    const vimeoInfo = params[params.length - 1];

    const infos = vimeoInfo.split("?");
    return infos[0];
}

export const isContainInvalidChar = (email) => {
    const totalAvailableChars = [...allowedSpecialChars, ...lowerAlphaChars, ...upperAlphaChars, ...numberChars, "@"];
    for (const ch of email) {
        if (totalAvailableChars.indexOf(ch) < 0) return true;
    }

    return false;
}