import React, { useEffect, useState } from 'react';
import { selectProviderSessionById } from '../airtables/ProviderSessions';
import { updateTeacher } from '../airtables/Teachers'
import { getReviewById } from '../airtables/Reviews';
import { getSupplyById } from '../airtables/Supplies';
import { LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import RequestForm from './modals/Request';
import { Button, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Loading from '../components/Loading';
import VideoPlayer from '../components/VideoPlayer';

const SessionDetail = (props) => {
    const sessionId = props.match.params.sessionId;
    const [loading, setLoading] = useState(true);
    const [session, setSession] = useState(null);
    const [grades, setGrades] = useState([]);
    const [requestModal, showRequestModal] = useState(false);
    const [favoriteState, setFavoriteState] = useState('loading');
    const [reviews, setReviews] = useState([]);
    const [supply, setSupply] = useState(null);
    const { userType, userInfo, subjectMap, appLocale, myFavorites } = useSelector(state => state.appInfo);
    const { t } = useTranslation();
    const [mainContainerWidth, setMainConainerWidth] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            resizeListener();
        })

        window.addEventListener('resize', resizeListener)
        return () => {
            window.removeEventListener("resize", resizeListener);
            document.title = "Connected North";
        }
    }, [])

    const resizeListener = () => {
        setMainConainerWidth(document.getElementsByClassName("main-container")[0].getBoundingClientRect().width);
    }

    useEffect(() => {
        if (userType === 'Teacher') {
            setFavoriteState(myFavorites.indexOf(sessionId) >= 0 ? 'favorite' : 'unfavorite');
        }
    }, [myFavorites]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        (async () => {
            const sess = await selectProviderSessionById(sessionId);
            if (sess.Supplies && sess.Supplies.length) {
                const sp = await getSupplyById(sess["Supplies"])
                setSupply(sp);
            } else {
                setSupply(null)
            }
            document.title = `${sess['Session Title']} - ${sess['Provider Name Text']} - Connected North`
            setSession(sess);

            if (sess) {
                if (sess['Grade(s)'] && sess['Grade(s)'].length) {
                    const gs = [...sess['Grade(s)']];
                    gs.sort((a, b) => {
                        const x = a === 'K' ? 0 : parseInt(a);
                        const y = b === 'K' ? 0 : parseInt(b);
                        if (x > y) return 1;
                        return -1;
                    })
                    setGrades(gs)
                }
            }
            setLoading(false);

            if (sess?.Reviews && sess?.Reviews.length) {
                for (let rId of sess.Reviews) {
                    const review = await getReviewById(rId);
                    const newReviews = [...reviews, review];
                    setReviews(newReviews);
                }
            }
        })();
    }, [sessionId]) // eslint-disable-line react-hooks/exhaustive-deps

    /* const recordPlayHistory = async () => {
        if (userType === "Teacher" && (!userInfo["Recordings Watched"] || userInfo["Recordings Watched"].indexOf(session.id) < 0)) {
            let recordings = [];
            if (!userInfo["Recordings Watched"]) recordings = [session.id]
            else recordings = [...userInfo["Recordings Watched"], session.id];

            await updateTeacher(userInfo.id, { "Recordings Watched": recordings });
        }
    } */

    const renderGrade = (grade, i) => {
        if (grade.startsWith('Premium Session')) return null;

        return (
            <div key={i} className="tag-item grade-tag">
                <span>{grade}</span>
            </div>
        )
    }

    const renderSubject = (sub, i) => {
        if (!subjectMap[sub]) return null;

        const subjectName = appLocale === 'fr' ? subjectMap[sub]["Subject (FR)"] : subjectMap[sub]["Subject"];
        if (!subjectName) return null;

        return (
            <div key={i} className="tag-item subject-tag">
                <span>{subjectName}</span>
            </div>
        )
    }

    const getDayRestrictionConfig = () => {
        if (!supply) return 0;

        if (supply["Essential?"] === "Yes" && (supply.Status === "Sioux Lookout Storage" || supply.Status === "Toronto Office Storage") && supply["Quantity Remaining"] === 0) {
            return 60
        }

        return 0;
    }

    const renderContent = () => {
        if (loading) {
            return (
                <div style={{ minHeight: 500 }}>
                    <LinearProgress />
                </div>
            )
        }

        if (!session) {
            return (
                <div style={{ minHeight: 500 }} className="nolist-placeholder" align="center">
                    <span>{t("session-link-invalid")}</span>
                </div>
            )
        }

        return (
            <div style={{ marginTop: 20 }}>
                <div className="session-info">
                    <div className="session-info-row">
                        {
                            (session['Image/Photo'] && session['Image/Photo'].length) && (
                                <div className="session-image">
                                    <img src={session['Image/Photo'][0].url} alt="" />
                                </div>
                            )
                        }

                        <div className="session-info-item">
                            <span className="info-title">{t("content-provider")}:&nbsp;&nbsp;</span>
                            {
                                (session['Provider Name Text'] && session['Provider Name Text'].length > 0) && (
                                    <a href={`/cn/provider/${session.Provider[0]}`} target="_blank" rel="noopener noreferrer" className="info-link">
                                        <span className="info-content">{(session['Language'] === "French" && session['Provider (FR)']) ? session['Provider (FR)'] : session['Provider Name Text']}</span>
                                    </a>
                                )
                            }
                        </div>

                        {
                            session['Cost'] && userType !== 'Teacher' && userType !== 'Provider' ? (
                                <div className="session-info-item">
                                    <span className="info-title">{t("cost")}: </span>
                                    <span className="info-content">${session['Cost']}</span>
                                </div>
                            ) : null
                        }

                        <div className="session-info-item">
                            <span className="info-title">{t("language")}: </span>
                            <span className="info-content">{session['Language']}</span>
                        </div>

                        {
                            session['# Booked'] > 0 && userType !== 'Teacher' && userType !== 'Provider' ? (
                                <div className="session-info-item">
                                    <span className="info-title">{t("session-booked")}: </span>
                                    <span className="info-content">{session['# Booked']}</span>
                                </div>
                            ) : null
                        }

                        <div className="session-info-item">
                            <span className="info-title">{t("subjects")}: </span>
                            <div className="info-content">
                                {
                                    (session.Subject && Array.isArray(session.Subject)) ? session.Subject.map((sub, i) =>
                                        renderSubject(sub, i)
                                    ) : (null)
                                }
                            </div>
                        </div>

                        <div className="session-info-item">
                            <span className="info-title">{t("grades")}: </span>
                            <div className="info-content">
                                {
                                    grades ? grades.map((grade, i) =>
                                        renderGrade(grade, i)
                                    ) : (null)
                                }
                            </div>
                        </div>

                        {
                            session['Length (Minutes)'] ? (
                                <div className="session-info-item">
                                    <span className="info-title">{t("length")}: </span>
                                    <span className="info-content">{session['Length (Minutes)']} min</span>
                                </div>
                            ) : (null)
                        }

                        {
                            (session['Resource Sheets'] && Array.isArray(session['Resource Sheets'])) ? (
                                <div className="session-info-item">
                                    <span className="info-title">{t("resource-sheets")}: </span>
                                    <div className="info-content">
                                        {
                                            session['Resource Sheets'].map((resource, i) => (
                                                <a href={resource.url} target="_blank" key={i} rel="noopener noreferrer">
                                                    <div style={{ backgroundColor: 'lightgray', borderRadius: 5, margin: 10 }}>
                                                        <img src={resource?.thumbnails?.large?.url} style={{ width: 50, height: 50, borderRadius: 5 }} alt="" />
                                                    </div>
                                                </a>
                                            ))
                                        }
                                    </div>
                                </div>
                            ) : (null)
                        }

                        <div className="session-info-item">
                            <span className="info-title">{t("description")}: </span>
                            <div className="info-content">
                                <span className="info-content">{session['Description']}</span>
                            </div>
                        </div>

                        {
                            (reviews && reviews.length) ? (
                                <div className="session-info-item">
                                    <span className="info-title">{t("selected-session-feedback")}: </span>
                                    <div className="info-content">
                                        {
                                            reviews.map((review, i) => (
                                                <div className="review" key={i}>
                                                    <span>{review.Quote}</span><br />
                                                    <span>- {review["Teacher Grade(s)"]} Teacher from {review["Teacher Location"]}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ) : null
                        }

                        {
                            session["Materials Info"] ? (
                                <div className="session-info-item">
                                    <span className="info-title">{t("materials-required")}:</span>
                                    <div className="info-content">
                                        <span className="info-content">{session['Materials Info']}</span>
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>

                    {
                        (session["Video Sample"] && session["Video Sample"].length && session["Video Sample"][0]) ? (
                            <div className="session-info-item">
                                <span className="info-title">{t("provider-preview")}</span>
                                {mainContainerWidth ? (<VideoPlayer
                                    videoData={session['Video Sample'][0]}
                                    width={mainContainerWidth}
                                    // onPlay={() => recordPlayHistory()}
                                    onPlay={() => { }}
                                />) : null}

                                {/* <div dangerouslySetInnerHTML={{ __html: session['Video Sample'] }} /> */}
                            </div>
                        ) : null
                    }
                </div >

                {
                    requestModal && <RequestForm
                        session={session}
                        sessionLen={session["Length (Minutes)"]}
                        provider={session.Provider}
                        dayRestrictionConfig={getDayRestrictionConfig()}
                        onToggle={() => showRequestModal(false)}
                        onSubmitted={() => {
                            showRequestModal(false);
                        }}
                    />
                }
            </div>
        );
    }

    const toggleFavorite = async () => {
        if (favoriteState === 'loading') return;

        if (favoriteState === 'favorite') {
            setFavoriteState('loading');
            const favorites1 = [...myFavorites].filter(v => v !== sessionId);
            await updateTeacher(userInfo['id'], { Favourites: favorites1 });
            setFavoriteState('unfavorite')
        } else if (favoriteState === 'unfavorite') {
            setFavoriteState('loading');
            const favorites2 = [...myFavorites, sessionId]
            await updateTeacher(userInfo['id'], { Favourites: favorites2 });
            setFavoriteState('favorite')
        }
    }

    const renderSessionStatus = () => {
        if (!supply) return null;

        if (supply["Essential?"] === "Yes" && (supply.Status === "Sioux Lookout Storage" || supply.Status === "Toronto Office Storage") && supply["Quantity Remaining"] === 0) {
            return (
                <div className="session-warning">
                    <span>{t("session-warning1")}</span>
                </div>
            )
        }

        return null;
    }

    return (
        <div className="main-container">
            <Row>
                <Col xs="12" sm="9">
                    <span className="page-topic">{!session ? "Session Detail" : session['Session Title']}</span>
                </Col>
                <Col xs="12" sm="3">
                    {
                        (!loading && session && userType === 'Teacher') && (
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }} />
                                {
                                    (session['Status'] === "Active (Open for booking)" || session['Status'] === "Consider Another Option (See notes)") && (
                                        <Button
                                            color="primary"
                                            type="button"
                                            onClick={() => showRequestModal(true)}
                                        >{t("request")}</Button>
                                    )
                                }
                                {
                                    favoriteState === 'loading' ? (
                                        <div className='btn-favorite'>
                                            <Loading size={20} />
                                        </div>
                                    ) : (
                                        <div className='btn-favorite' onClick={() => toggleFavorite()}>
                                            <i className={favoriteState === 'unfavorite' ? 'fa fa-heart-o' : 'fa fa-heart'} />
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </Col>
            </Row>
            {renderSessionStatus()}
            {renderContent()}
        </div >
    )
}

export default SessionDetail;
