import base from './config';
const tableName = "Tags"

export const getAllTags = () => {
    return new Promise((resolve, reject) => {
        var tags = [];
        base(tableName).select({
            sort: [
                { field: 'Tag', direction: 'asc' }
            ]
        }).eachPage((records, fetchNextPage) => {
            records.forEach(record => {
                var tag = {
                    id: record.id,
                    ...record.fields
                }
                tags.push(tag);
            })
            fetchNextPage()
        }).then(res => {
            resolve(tags)
        }).catch(error => {
            resolve([])
        })
    });
}

export const getTagById = (id) => {
    return new Promise((resolve, reject) => {
        base(tableName).find(id).then(record => {
            if (record && record._rawJson){
                resolve(record._rawJson.fields)
            } else {
                resolve(null)
            }
        }).catch(error => {
            reject(error.toString())
        })
    })
}