import base from './config';
const tableName = "Clusters"

export const getClusterById = (id) => {
    return new Promise((resolve, reject) => {
        base(tableName).find(id).then(record => {
            if (record && record._rawJson){
                resolve({
                    ...record._rawJson.fields,
                    id: record._rawJson.id
                })
            } else {
                resolve(null)
            }
        }).catch(error => {
            reject(error.toString())
        })
    })
}

export const selectClusterByDomain = (domain) => {
    const dm = domain.toUpperCase();
    return new Promise((resolve, reject) => {
        base(tableName).select({
            filterByFormula: `'${dm}' = UPPER({Domain})`,
            maxRecords: 1
        }).firstPage().then(res => {
            if (res.length > 0) {
                resolve({
                    id: res[0].id,
                    ...res[0].fields
                })
            } else {
                resolve(null)
            }
        }).catch(error => {
            reject(error.toString());
        })
    });
}
