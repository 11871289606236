import Axios from "axios";

export const sendVerifySms = (phone) => {
    return new Promise((resolve, reject) => {
        const url = "https://us-central1-connected-north.cloudfunctions.net/onSendVerifySms";
        Axios.post(url, {
            phonenumber: '+1 ' + phone
        }).then(res => {
            if (res.data.suceess) {
                resolve(res.data.data)
            } else {
                reject(res.data.error)
            }
        }).catch(error => {
            reject(error)
        })
    })
}

export const verifySmsCode = (phone, code) => {
    return new Promise((resolve, reject) => {
        const url = "https://us-central1-connected-north.cloudfunctions.net/onVerifySmsCode";
        Axios.post(url, {
            phonenumber: '+1 ' + phone,
            code
        }).then(res => {
            if (res.data.suceess) {
                resolve(res.data.data)
            } else {
                reject(res.data.error)
            }
        }).catch(error => {
            reject(error)
        })
    })
}